/* eslint-disable */
import React, { useState, lazy, Suspense, useEffect, useCallback } from "react";
import "./arbitraje.css";
import { FaWindowRestore, FaCalculator } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Ventana from "./Ventana";
import Main from "../../../layout/main";
import { useUserContext } from "../../../Providers/UserProvider";
import { io } from "socket.io-client";
import ValidateService from "../../../api/services/validateService";
import Chat from "../../Chat/index";
//COMPONENTES
const Filtro = lazy(() => import("../../../Components/filtro/Filtro"));
const FiltroEspecial = lazy(() => import("../../../Components/filtro/FiltroEspecial"));
const Calculadora = lazy(() => import("../../../Components/caluladora/Calculadora"));
const Scanner = lazy(() => import("../../../Components/scanner/Scanner"));
const ScannerEspecial = lazy(() => import("../../../Components/scanner/ScannerFiltrado"));
// const accessToken = localStorage.getItem("accessToken");
// const socket = io("https://servicesurebets.com:3000/", {
//     query: {
//         variable: 2,
//         token: accessToken,
//     },
// });
//TOOLTIPS
const tooltip = (
    <Tooltip id="tooltip">
        <strong>Abrir en nueva ventana</strong>
    </Tooltip>
);
const tooltipF = (
    <Tooltip id="tooltipF">
        <strong>Solo ventanas flotantes</strong>
    </Tooltip>
);
const tooltipTC = (
    <Tooltip id="tooltipTC">
        <strong>Ver tipo de cambio</strong>
    </Tooltip>
);
function processSurebets(filtros, setDataSurebet,setDataSurebetPre) {

    return (data) => {

        //FILTRANDO POR DEPORTES
        const datafilterSport = data.filter((elem) =>
            filtros.deportes.find(({ value, checked }) => elem.sportname.toLowerCase() == String(value).toLowerCase() && checked)
        );

        //FILTRANDO POR CASAS
        const datafilter = datafilterSport.filter((elem) =>
            filtros.casas.find(({ value, checked }) => elem.bookmaker === value && checked)
        );
        
        const datafilterPre = data.filter((elem) =>
            filtros.casas.find(({ value, checked }) => elem.bookmaker === value)
        );

        //FILTRANDO POR INPUTS (PORCENTAJES)
        let perMin = filtros.inputs.percentMin != "" ? parseFloat(filtros.inputs.percentMin) : null;
        let perMax = filtros.inputs.percentMax != "" ? parseFloat(filtros.inputs.percentMax) : null;

        const dataFiltradazo = datafilter.filter((casas) => {
            let percent = parseFloat(casas.arbprofit);
            if (filtros.inputs.percentMint != "") {
                if (filtros.inputs.percentMax != "") {
                    if (perMin <= percent && percent <= perMax) {
                        return casas;
                    }
                } else {
                    if (perMin <= percent) {
                        return casas;
                    }
                }
            } else {
                if (filtros.inputs.percentMax != "") {
                    if (percent <= perMax) {
                        return casas;
                    }
                } else {
                    return casas;
                }
            }
        });

        //FILTRANDO POR INPUTS (ODDS)
        let oddMin = filtros.inputs.oddsMin != "" ? parseFloat(filtros.inputs.oddsMin) : null;
        let oddMax = filtros.inputs.oddsMax != "" ? parseFloat(filtros.inputs.oddsMax) : null;
        const dataFiltradazoFinal = dataFiltradazo.filter((casas) => {
            let odd = parseFloat(casas.odd);
            if (filtros.inputs.oddsMin != "") {
                if (filtros.inputs.oddsMax != "") {
                    if (oddMin <= odd && odd <= oddMax) {
                        return casas;
                    }
                } else {
                    if (oddMin <= odd) {
                        return casas;
                    }
                }
            } else {
                if (filtros.inputs.oddsMax != "") {
                    if (odd <= oddMax) {
                        return casas;
                    }
                } else {
                    return casas;
                }
            }
        });

        //FILTROS POR MERCADOS
        const datafilterEspecial = dataFiltradazoFinal.filter((casas) => {
            for (let index = 0; index < filtros.especiales.mercados.length; index++) {
                const element = filtros.especiales.mercados[index];
                if (element.checked) {
                    for (let index = 0; index < element.values.length; index++) {
                        const element1 = element.values[index];
                        if (element.equal) {
                            if (casas.arboutcome == element1) {
                                return element.filtro;
                            }
                        } else {
                            if (element.restriction.length > 0) {
                                if (casas.arboutcome.includes(element1)) {
                                    let val = element.restriction.map((res) => {
                                        if (casas.arboutcome.includes(res)) return true;
                                        else return false;
                                    });
                                    if (!val.includes(true)) {
                                        return element1;
                                    }
                                }
                            }
                            if (element.inclution.length > 0) {
                                for (let index = 0; index < element.inclution.length; index++) {
                                    const element2 = element.inclution[index];
                                    if (casas.arboutcome.includes(element1) && casas.arboutcome.includes(element2)) {
                                        return element.filtro;
                                    }
                                }
                            }
                            if (element.inclution.length == 0 && element.restriction.length == 0) {
                                if (casas.arboutcome.includes(element1)) {
                                    return element.filtro;
                                }
                            }
                        }
                    }
                }
            }
        });

        // FILTROS POR LIGA
        const filterSports = datafilterEspecial.filter(
            (e) =>
                e.sportname != "Soccer" &&
                e.sportname != "Tennis" &&
                e.sportname != "Basketball" &&
                e.sportname != "Baseball" &&
                e.sportname != "Hockey" &&
                e.sportname != "Am. Football" &&
                e.sportname != "E-Sports"
        );

        //SOCCER
        let totSoccer = false;
        let dataFilterLigasSoccer = [];
        for (let index = 0; index < filtros.especiales.ligas.soccer.length; index++) {
            const element = filtros.especiales.ligas.soccer[index];
            if (element.value == 0 && element.checked) {
                totSoccer = true;
            }
        }        
        if (totSoccer) {
            dataFilterLigasSoccer = datafilterEspecial.filter((casas) => {
                if (casas.sportname == "Soccer") {
                    return casas
                }
            })
        } else {
            dataFilterLigasSoccer = datafilterEspecial.filter((elem) =>
                filtros.especiales.ligas.soccer.find(
                    ({ value, checked }) => elem.sportname == "Soccer" && elem.leaguename.toLowerCase().includes(String(value).toLowerCase()) && checked
                )
            );
        }

        //TENIS
        let totTenis = false;
        let dataFilterLigasTenis = [];
        for (let index = 0; index < filtros.especiales.ligas.tenis.length; index++) {
            const element = filtros.especiales.ligas.tenis[index];
            if (element.value == 0 && element.checked) {
                totTenis = true;
            }
        }
        if (totTenis) {
            dataFilterLigasTenis = datafilterEspecial.filter((casas) => {
                if (casas.sportname == "Tennis") {
                    return casas
                }
            })
        } else {
            dataFilterLigasTenis = datafilterEspecial.filter((elem) =>
                filtros.especiales.ligas.tenis.find(
                    ({ value, checked }) => elem.sportname == "Tennis" && elem.leaguename.toLowerCase().includes(String(value).toLowerCase()) && checked
                )
            );
        }

        //BASKET
        let totBasket = false;
        let dataFilterLigasBasket = [];
        for (let index = 0; index < filtros.especiales.ligas.basket.length; index++) {
            const element = filtros.especiales.ligas.basket[index];
            if (element.value == 0 && element.checked) {
                totBasket = true;
            }
        }
        if (totBasket) {
            dataFilterLigasBasket = datafilterEspecial.filter((casas) => {
                if (casas.sportname == "Basketball") {
                    return casas
                }
            })
        } else {
            dataFilterLigasBasket = datafilterEspecial.filter((elem) =>
                filtros.especiales.ligas.basket.find(
                    ({ value, checked }) => elem.sportname == "Basketball" && elem.leaguename.toLowerCase().includes(String(value).toLowerCase()) && checked
                )
            );
        }

        //BASEBALL
        let totBaseball = false;
        let dataFilterLigasBaseball = [];
        for (let index = 0; index < filtros.especiales.ligas.baseball.length; index++) {
            const element = filtros.especiales.ligas.baseball[index];
            if (element.value == 0 && element.checked) {
                totBaseball = true;
            }
        }
        if (totBaseball) {
            dataFilterLigasBaseball = datafilterEspecial.filter((casas) => {
                if (casas.sportname == "Baseball") {
                    return casas
                }
            })
        } else {
            dataFilterLigasBaseball = datafilterEspecial.filter((elem) =>
                filtros.especiales.ligas.baseball.find(
                    ({ value, checked }) => elem.sportname == "Baseball" && elem.leaguename.toLowerCase().includes(String(value).toLowerCase()) && checked
                )
            );
        }

        //HOCKEY
        let totHockey = false;
        let dataFilterLigasHockey = [];
        for (let index = 0; index < filtros.especiales.ligas.hockey.length; index++) {
            const element = filtros.especiales.ligas.hockey[index];
            if (element.value == 0 && element.checked) {
                totHockey = true;
            }
        }
        if (totHockey) {
            dataFilterLigasHockey = datafilterEspecial.filter((casas) => {
                if (casas.sportname == "Hockey") {
                    return casas
                }
            })
        } else {
            dataFilterLigasHockey = datafilterEspecial.filter((elem) =>
                filtros.especiales.ligas.hockey.find(
                    ({ value, checked }) => elem.sportname == "Hockey" && elem.leaguename.toLowerCase().includes(String(value).toLowerCase()) && checked
                )
            );
        }

        //FUTBOL AMERICANO
        let totFA = false;
        let dataFilterLigasFamericano = [];
        for (let index = 0; index < filtros.especiales.ligas.famericano.length; index++) {
            const element = filtros.especiales.ligas.famericano[index];
            if (element.value == 0 && element.checked) {
                totFA = true;
            }
        }
        if (totFA) {
            dataFilterLigasFamericano = datafilterEspecial.filter((casas) => {
                if (casas.sportname == "Am. Football") {
                    return casas
                }
            })
        } else {
            dataFilterLigasFamericano = datafilterEspecial.filter((elem) =>
                filtros.especiales.ligas.famericano.find(
                    ({ value, checked }) => elem.sportname == "Am. Football" && elem.leaguename.toLowerCase().includes(String(value).toLowerCase()) && checked
                )
            );
        }

        //E SPORTS
        let totESports = false;
        let dataFilterLigasEsports = [];
        for (let index = 0; index < filtros.especiales.ligas.esports.length; index++) {
            const element = filtros.especiales.ligas.esports[index];
            if (element.value == 0 && element.checked) {
                totESports = true;
            }
        }
        if (totESports) {
            dataFilterLigasFamericano = datafilterEspecial.filter((casas) => {
                if (casas.sportname == "E-Sports") {
                    return casas
                }
            })
        } else {
            dataFilterLigasEsports = datafilterEspecial.filter((elem) =>
                filtros.especiales.ligas.esports.find(
                    ({ value, checked }) => elem.sportname == "E-Sports" && elem.leaguename.toLowerCase().includes(String(value).toLowerCase()) && checked
                )
            );
        }

        if (dataFilterLigasSoccer.length != 0) {
            for (let index = 0; index < dataFilterLigasSoccer.length; index++) {
                const element = dataFilterLigasSoccer[index];
                filterSports.push(element);
            }
        }
        if (dataFilterLigasTenis.length != 0) {
            for (let index = 0; index < dataFilterLigasTenis.length; index++) {
                const element = dataFilterLigasTenis[index];
                filterSports.push(element);
            }
        }
        if (dataFilterLigasBasket.length != 0) {
            for (let index = 0; index < dataFilterLigasBasket.length; index++) {
                const element = dataFilterLigasBasket[index];
                filterSports.push(element);
            }
        }
        if (dataFilterLigasBaseball.length != 0) {
            for (let index = 0; index < dataFilterLigasBaseball.length; index++) {
                const element = dataFilterLigasBaseball[index];
                filterSports.push(element);
            }
        }
        if (dataFilterLigasHockey.length != 0) {
            for (let index = 0; index < dataFilterLigasHockey.length; index++) {
                const element = dataFilterLigasHockey[index];
                filterSports.push(element);
            }
        }
        if (dataFilterLigasFamericano.length != 0) {
            for (let index = 0; index < dataFilterLigasFamericano.length; index++) {
                const element = dataFilterLigasFamericano[index];
                filterSports.push(element);
            }
        }
        if (dataFilterLigasEsports.length != 0) {
            for (let index = 0; index < dataFilterLigasEsports.length; index++) {
                const element = dataFilterLigasEsports[index];
                filterSports.push(element);
            }
        }

        filterSports.sort((s1, s2) => {
            return parseFloat(s2.arbprofit) - parseFloat(s1.arbprofit);
        });

        //SCANNER ESPECIAL
        let filids = filterSports.map((ids) => ids.arbid);
        filids = Array.from(new Set(filids.filter((res) => filids.indexOf(res) !== filids.lastIndexOf(res))));
        const datos = [];
        setDataSurebet(datos);
        for (const ids of filids) {
            datos.push(filterSports.filter((datos) => datos.arbid === ids));
        }        
        setDataSurebet(datos);

        //SCANNER NORMAL
        // console.log('datafilter', filids)
        // console.log('filterSports',filterSports)
        let filtrando = datafilterPre.filter((elem) => !filterSports.find(({ id }) => elem.id === id));

        // let filtrando = datafilterPre.filter((elem) => !filids.find((x) => (x === elem.arbid)));

        // console.log("filtrand2", filtrand2);

        let filids1 = [];
        if (filtrando.length > 0) {
            filids1 = filtrando.map((ids) => ids.arbid);
            // console.log('filids1',filids1)
            filids1 = Array.from(new Set(filids1.filter((res) => filids1.indexOf(res) !== filids1.lastIndexOf(res))));
            // console.log('filids1',filids1)
        }
        const datos1 = [];
        setDataSurebetPre(datos1);
        if (filids1.length > 0) {
            for (const ids of filids1) {
                datos1.push(filtrando.filter((datos) => datos.arbid === ids));
            }
            setDataSurebetPre(datos1);
        } 
        // let filids1 = datafilter.map((ids) => ids.arbid);
        // filids1 = Array.from(new Set(filids.filter((res) => filids.indexOf(res) !== filids.lastIndexOf(res))));
        // const datos1 = [];
        // setDataSurebetPre(datos1);
        // for (const ids of filids) {
        //     datos1.push(datafilter.filter((datos) => datos.arbid === ids));
        // }        
        // setDataSurebetPre(datos1);
    };
}
const Arbitraje = () => {
    //HOOKS
    const { user, filtrosLive, filtrosPrematch } = useUserContext();
    const [liveSure, setLiveSure] = useState([]);
    const [dataSurebet, setDataSurebet] = useState([])
    const [dataSurebetPre, setDataSurebetPre] = useState([])
    const [dataFilter, setDataFilter] = useState([])
    const [type, setType] = useState(2);
    const [tc, setTc] = useState("normal");
    const [stake, setStake] = useState(user.stake);
    const [userTc, setUserTc] = useState(user.tipocambio);
    const [onlyF, setOnlyF] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [mood, setMood] = useState(false);
    const [disInput, setDisInput] = useState(true);
    const [trigger, setTrigger] = useState(0);
    const [showWindowPortal, setShowWindowPortal] = useState(false);
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    //METODOS Y MOUNTED
    
    // useEffect(() => {        
    //     socket.on("server:message", (data) => {
    //         const reduceSurebets = processSurebets(
    //             type == 2 ? JSON.parse(localStorage.getItem("filtrosLive")) : JSON.parse(localStorage.getItem("filtrosPrematch")),
    //             setDataSurebet,setDataSurebetPre
    //         );
    //         reduceSurebets(data);
    //     });
        
    //     return () => {
    //         socket.close();
    //         socket.disconnect();
    //     };
    // }, [socket]);
    useEffect(() => {
        const socket = io("https://servicesurebets.com:3000/", {
            query: {
                variable: type,
                token: accessToken,
            },
        });
        socket.connect();
        socket.on("server:message", (data) => {
            // console.log('data',data)
            // setDataSurebet(data);
            const reduceSurebets = processSurebets(
                type == 2 ? JSON.parse(localStorage.getItem("filtrosLive")) : JSON.parse(localStorage.getItem("filtrosPrematch")),
                setDataSurebet,setDataSurebetPre
            );
            reduceSurebets(data);
        });
        
        return () => {
            socket.close();
            socket.disconnect();
        };
    }, [type]);
    
    // const changeType = (tipo) => {
    //     setType(tipo)
    //     socket.io.opts.query.variable = tipo
    //     socket.close()
    //     socket.open()
    //     socket.io.on("reconnect_attempt", () => {
    //         socket.io.opts.query.x++;
    //       });
    // }
    const changeType = useCallback((tipo) => {
        setType(tipo);
    }, []);
    const showScanner = useCallback((tipo) => {
        setShowFilter(tipo);
    }, []);
    const onOptionChange = (e) => {
        setTc(e.target.checked);
    };
    const openWindow = () => {
        setTrigger((trigger) => trigger + 1);
        setShowWindowPortal(true);
    };
    const closeWindowPortal = () => {
        setShowWindowPortal(false);
    };
    const onlyFloat = (e) => {
        var checkBox = document.getElementById("chck1");
        if (e.target.checked) {
            setOnlyF(true);
            setDisInput(true);

            if (checkBox.checked == true) {
                checkBox.checked = false;
                checkBox.disabled = true;
            }
        } else {
            setOnlyF(false);
            if (liveSure.length > 0) {
                setDisInput(false);
                if (checkBox.checked == false) {
                    checkBox.checked = true;
                    checkBox.disabled = false;
                }
            }
        }
    };
    const setData = (data) => {
        // console.log('data',data)
        // dataLive = data
        setLiveSure(data);
        if (data.length != 0) {
            if (onlyF) {
                openWindow();
            } else {
                // console.log('aqui')
                setDisInput(false);
                var checkBox = document.getElementById("chck1");
                if (checkBox.checked == false) {
                    checkBox.checked = true;
                }
            }
        }
    };
    const setDatos =(data)=>{
        setDataFilter(data)
    }
    const ToogleMood = useCallback((val) => {
        setMood(val);
    }, []);

    useEffect(() => {
        window.addEventListener("beforeunload", () => {
            closeWindowPortal();
        });
    }, []);
    const validation = async () => {
        const resp = await ValidateService.validateToken({ refreshToken: "Bearer " + refreshToken });
    };
    useEffect(() => {
        validation();
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            await ValidateService.validateToken({ refreshToken: "Bearer " + refreshToken });
        }, 1000 * 30);
        return () => clearInterval(interval);
    }, []);
    return (
        <Main userStake={(datos) => setStake(datos)} userTC={(datos) => setUserTc(datos)}>
            <Suspense
                fallback={
                    <div className="py-6 d-flex justify-content-center align-items-center h-100">
                        <h1>Cargando...</h1>
                    </div>
                }
            >
                <div className="row w-100 mx-auto" style={{ marginTop: "-10px" }}>
                    <div className="col-md-6 col-sm-12 order-2 order-md-1 h-100">
                        <div className="card ">
                            <div className="d-flex flex-column flex-xl-row header_tabs_scanner rounded justify-content-between align-items-center">
                                <div className="d-flex w-100 w-xl-auto justify-content-start align-items-center">
                                    <button
                                        className={`btn btn-evento w-100 btn-sm ${type == 2 ? "tabs_active_sca" : "text-white"}`}
                                        onClick={() => changeType(2)}
                                    >
                                        LIVE
                                    </button>
                                    <button
                                        className={`btn btn-evento w-100 btn-sm ${type == 1 ? "tabs_active_sca" : "text-white"}`}
                                        onClick={() => changeType(1)}
                                    >
                                        PREMATCH
                                    </button>
                                </div>

                                <div className="px-4 d-flex">
                                    <Filtro type={type == 2 ? "live" : "prematch"} />
                                    <button
                                        type="button"
                                        className="btn btn-filtro btn-sm py-2"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal1"
                                    >
                                        ESPECIALES
                                    </button>
                                    <div className="d-flex gap-2 justify-content-center align-items-center form-check form-switch form-check-custom form-check-primary form-check-solid">
                                        <input
                                            type="checkbox"
                                            className="form-check-input border_tc cursor-pointer"
                                            name="tipo_cambio"
                                            id="option-mood"
                                            value={mood}
                                            onChange={() => ToogleMood(!mood)}
                                        />
                                        <label htmlFor="option-mood" style={{ color: "white" }}>
                                            Normal/Resumido
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* {type} */}
                            <div className="">
                                <ScannerEspecial actSurebet={dataSurebet} type={type} setFilterData={(datos) => setDatos(datos)} setLiveSure={(liveSure) => setData(liveSure)} mood={mood} />
                                {/* <Scanner type={type} setLiveSure={(liveSure) => setData(liveSure)} /> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 order-1 order-md-2">
                        <div className="d-flex flex-column position-relative calculadora_content pb-3">
                            <div className="card mb-4 w-100">
                                <div className="w-100 d-flex flex-column">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="tabs">
                                                <div className="tab">
                                                    <input type="checkbox" id="chck1" disabled={disInput} />
                                                    <label className="tab-label p-2" htmlFor="chck1">
                                                        <div className="d-flex box_options ps-5 gap-2 justify-content-between w-100 pe-5">
                                                            <div className="d-flex gap-2">
                                                                <div className="d-flex align-items-center cursor-pointer me-2">
                                                                    <FaCalculator size={15}></FaCalculator>
                                                                </div>
                                                                <OverlayTrigger placement="top" overlay={tooltipTC}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center form-check form-switch form-check-custom form-check-primary form-check-solid">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input border_tc cursor-pointer"
                                                                            name="tipo_cambio"
                                                                            id="option-2"
                                                                            value={"tc"}
                                                                            onChange={onOptionChange}
                                                                        />
                                                                        <label htmlFor="option-2">TC</label>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="d-flex gap-2">
                                                                <div className="form-check form-switch form-check-custom form-check-success form-check-solid me-2">
                                                                    <OverlayTrigger placement="top" overlay={tooltipF}>
                                                                        <input
                                                                            className="form-check-input ms-1 cursor-pointer"
                                                                            type="checkbox"
                                                                            value="1"
                                                                            id="flexSwitchDefault"
                                                                            onChange={onlyFloat}
                                                                        />
                                                                    </OverlayTrigger>
                                                                </div>

                                                                <OverlayTrigger placement="top" overlay={tooltip}>
                                                                    <button
                                                                        className=" btn btn-outline btn-outline-warning p-0 px-1"
                                                                        onClick={openWindow}
                                                                    >
                                                                        <FaWindowRestore></FaWindowRestore>
                                                                    </button>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <div className="tab-content_cal card_contenido">
                                                        <Calculadora
                                                            actSurebet={liveSure}
                                                            tc={tc}
                                                            stake={stake}
                                                            userTc={userTc}
                                                            type={type}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-none d-md-block box_filters mb-4">
                                <div className=" px-6 bg_surebet_primary   fw-bolder fs-14 d-flex justify-content-between rounded-top">
                                    <div className="d-flex align-items-center justify-content-between w-100 py-2">
                                        <h6 className="fs-14 fw-bolder px-1 mb-0 text-white">EVENTOS ENCONTRADOS</h6>
                                        <div className="d-flex gap-2 justify-content-center align-items-center form-check form-switch form-check-custom form-check-primary form-check-solid">
                                            <input
                                                type="checkbox"
                                                className="form-check-input border_tc cursor-pointer"
                                                name="tipo_filters"
                                                id="option-filters"
                                                value={showFilter}
                                                onChange={() => showScanner(!showFilter)}
                                            />
                                            <label htmlFor="option-filters" style={{ color: "white" }}>
                                                Ocultar/Ver
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <Scanner actSurebet={dataSurebetPre} dataFilter={dataFilter} type={type} setLiveSure={(liveSure) => setData(liveSure)} mood={mood} showFilter={showFilter} />
                                {/* <ScannerEspecial type={type} setLiveSure={(liveSure) => setData(liveSure)} /> */}
                            </div>
                            <Chat></Chat>
                        </div>
                    </div>
                    <FiltroEspecial type={type == 2 ? "live" : "prematch"}></FiltroEspecial>
                    {showWindowPortal ? (
                        <Ventana
                            trigger={trigger}
                            closeWindowPortal={closeWindowPortal}
                            datos={liveSure}
                            type={"live"}
                            tc={tc}
                            stake={stake}
                            userTc={userTc}
                        ></Ventana>
                    ) : (
                        <></>
                    )}
                </div>
            </Suspense>
        </Main>
    );
};

export default Arbitraje;
