/* eslint-disable */

import UseService from '../UseServices'

const CajaCuentaService = {
  async getCajasCuentas(data) {
    var resp = await UseService.post('cajacuentas/list', data)
    return resp
  },
  async addCajaCuenta(data) {
    var resp = await UseService.post('cajacuentas/add', data)
    return resp
  },
  async updateCajaCuenta(data) {
    var resp = await UseService.put('cajacuentas/update', data)
    return resp
  },
  async statusCajaCuenta(data) {
    var resp = await UseService.put('cajacuentas/status', data)
    return resp
  },
  // async reportMonto(data) {
  //   var resp = await UseService.put('montoscuentas/report', data)
  //   return resp
  // }
}
export default CajaCuentaService
