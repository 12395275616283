/* eslint-disable */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import "../auth.css";
import AuthService from "../../../api/services/authService";
import { useLocation } from "react-router-dom";
const schema = yup.object({
    contraseña: yup.string().required("Este campo es requerido"),
    termconds: yup.boolean().oneOf([true], "Acepte los términos y condiciones").required("Acepte los términos y condiciones"),
});

const RestorePass = () => {
    let location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const {
        handleSubmit,
        register,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const sendData = async (data) => {
        let ruta = location.search.substring(1, location.search.length);
        setLoading(true);
        const datos = {
            header: {
                Authorization: `Bearer ` + ruta,
            },
            body: {
                contra: data.contraseña,
            },
        };
        const resp = await AuthService.changePassword(datos);
        // console.log("resp", resp);
        if (resp.status) {
            Swal.fire({
                title: "Enviado",
                icon: "success",
                text: "Se ha restablecido su contraseña con éxito.",
            });
            navigate("/");
        } else {
            Swal.fire({
                title: "Error",
                icon: "error",
                text: "Correo no registrado.",
            });
        }
        setLoading(false);
    };
    return (
        <div className="d-flex flex-column flex-root h-100">
            <div className="row h-100 w-100 gx-0">
                {/* <div className="d-none d-lg-block col-lg-6 login_aside h-100"></div> */}
                <div className="col-12 h-100">
                    <div className="h-100 d-flex flex-center w-100 content_login">
                        <div className="login_form w-100 w-lg-600px p-15  flex-center mx-10 mx-lg-4 d-flex flex-column gap-8">
                            <div className="py-2 pb-lg-5">
                                <img alt="Logo" className="logo_login " />
                            </div>
                            <form
                                className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                                id="kt_new_password_form"
                                onSubmit={handleSubmit(sendData)}
                            >
                                <div className="text-center mb-10">
                                    <h1 className="text-dark mb-3">Elige una nueva contraseña</h1>
                                    <div className="text-gray-400 fw-semibold fs-4 ms-1">
                                        ¿Ya haz actualizado tu contraseña?
                                        <Link to="/" className="link-primary fw-bold">
                                            Inicia sesión aquí
                                        </Link>
                                    </div>
                                </div>
                                <div className="mb-10 fv-row fv-plugins-icon-container" data-kt-password-meter="true">
                                    <div className="mb-1">
                                        <label className="form-label fw-bold text-dark fs-6">Nueva contraseña</label>
                                        <div className="position-relative mb-3">
                                            <input
                                                className="form-control form-control-lg form_select_login border-login"
                                                type="password"
                                                placeholder=""
                                                name="password"
                                                autoComplete="off"
                                                {...register("contraseña")}
                                            />
                                            <div className="fv-plugins-message-container invalid-feedback">
                                                {errors.contraseña?.type === "required" && errors.contraseña.message}
                                                {errors.contraseña?.type === "matches" && errors.contraseña.message}
                                            </div>
                                            <span
                                                className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                                                data-kt-password-meter-control="visibility"
                                            >
                                                <i className="bi bi-eye-slash fs-2"></i>
                                                <i className="bi bi-eye fs-2 d-none"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                </div>
                                <div className="fv-row mb-10 fv-plugins-icon-container">
                                    <div className="form-check form-check-custom form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="toc"
                                            value="1"
                                            {...register("termconds")}
                                        />
                                        <label className="form-check-label fw-semibold text-gray-700 fs-6">
                                            Estoy de acuerdo con
                                            <a
                                                href="https://www.bullbet.com.pe/pages/terminos-y-condiciones"
                                                target="_blank"
                                                className="ms-1 link-primary"
                                            >
                                                Terminos y condiciones
                                            </a>
                                            .
                                        </label>
                                        <div className="fv-plugins-message-container invalid-feedback">
                                            {errors.termconds?.type === "oneOf" && errors.termconds.message}
                                        </div>
                                    </div>
                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                </div>
                                <div className="text-center">
                                    <button
                                        disabled={loading}
                                        type="submit"
                                        id="kt_new_password_submit"
                                        className="btn btn-lg btn-primary fw-bold"
                                    >
                                        {loading ? (
                                            <span className="indicator-label">
                                                Enviando...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        ) : (
                                            <span className="indicator-label ">Enviar</span>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RestorePass;
