/* eslint-disable */

import UseService from "../UseServices";

const CombinadasService = {
    async getCombinadas(data) {
        var resp = await UseService.post("combinadas/list", data);
        return resp;
    },
    async addCombinadas(data) {
        var resp = await UseService.post("combinadas/add", data);
        return resp;
    },
    async updateCombinadas(data) {
        var resp = await UseService.put("combinadas/update", data);
        return resp;
    },
    async updateStatusCombinadas(data) {
        var resp = await UseService.put("combinadas/status", data);
        return resp;
    },
};
export default CombinadasService;