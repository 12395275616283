/* eslint-disable */
import React, { useState, lazy } from "react";
import Main from "../../layout/main";
import Breadcrumbs from "../../Components/utils/BreadCrumb";
//COMPONENTES
import Listar from "./tabs/listar";
import Graficos from "./tabs/graficos";

const Eventos = () => {
    const [type, setType] = useState(2);
    const [loading, setLoading] = useState(false);
    //BreadCrumb
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/eventos", name: "Calendario Deportivo", active: false },
    ];
    return (
        <Main>
            <div className="w-100 mw-100 ">
                <div className={`overlay bg-active-dark ${loading ? "overlay-block" : ""}`}>
                    <div className="overlay-wrapper">
                        <div className="row mx-0 w-100">
                            <div className="col-12 col-sm-12 order-2 order-md-1">
                                <div className="card ">
                                    <div className="d-flex header_tabs_scanner rounded justify-content-between align-items-center">
                                        <div className="d-flex justify-content-start align-items-center">
                                            <button
                                                className={`btn btn-sm ${type == 2 ? "tabs_active_sca" : "text-white"}`}
                                                onClick={() => setType(2)}
                                            >
                                                Grafica
                                            </button>
                                            <button
                                                className={`btn btn-sm ${type == 1 ? "tabs_active_sca" : "text-white"}`}
                                                onClick={() => setType(1)}
                                            >
                                                Lista
                                            </button>
                                        </div>
                                    </div>
                                    {/* {type} */}
                                    <div className="">{type == 1 ? <Listar></Listar> : <Graficos></Graficos>}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="overlay-layer card-rounded bg-black bg-opacity-50">
                            <div className="d-flex flex-column align-items-center mt-10">
                                <div className="spinner-border text-white" role="status"></div>
                                <span>Buscando datos...</span>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </Main>
    );
};

export default Eventos;
