/* eslint-disable */
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../Components/utils/BreadCrumb";
import moment from "moment/moment";
import SurebetService from "../../../api/services/surebetService";
import JugadasService from "../../../api/services/jugadasService";
import ReportesService from "../../../api/services/reportesService";
import { useForm } from "react-hook-form";
import { FaRegEdit, FaTrash, FaDonate, FaUndo, FaBan, FaExclamationCircle, FaCheckCircle, FaHistory, FaChartBar } from "react-icons/fa";
import Toast from "react-bootstrap/Toast";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import Main from "../../../layout/main";
import "../jugadas.css";
import { useUserContext } from "../../../Providers/UserProvider";
const today = new Date();
const tooltipEd = (
    <Tooltip id="tooltipEd">
        <strong>Editar</strong>
    </Tooltip>
);
const tooltipE = (
    <Tooltip id="tooltipE">
        <strong>Eliminar</strong>
    </Tooltip>
);
const tooltipG = (
    <Tooltip id="tooltipG">
        <strong>Ganado</strong>
    </Tooltip>
);
const tooltipD = (
    <Tooltip id="tooltipD">
        <strong>Devuelto</strong>
    </Tooltip>
);
const tooltipP = (
    <Tooltip id="tooltipP">
        <strong>Perdido</strong>
    </Tooltip>
);

const tooltipRes = (
    <Tooltip id="tooltipRes">
        <strong>Restablecer</strong>
    </Tooltip>
);
const JugadasApuestas = () => {
    //USE FORM
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm();
    //hooks
    const [fechaInicio, setFechaInicio] = useState(moment(today).format("yyyy-MM-DD"));
    const [items, setItems] = useState([]);
    const [itemsReporte, setItemsReporte] = useState([]);
    const [alertColor, setAlertColor] = useState("");
    const [alertMsg, setAlertMsg] = useState("");
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [bancosList, setBancosList] = useState([]);
    const { user } = useUserContext();
    //BreadCrumb
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { name: "Jugadas", active: false },
        { path: "/jugadas-apuestas", name: "Apuestas", active: false },
    ];
    //DATOS LOCALSTORAGE
    const refreshToken = localStorage.getItem("refreshToken");
    //TRAER DATA
    const getData = async () => {
        setLoading(true);
        const bodyCasas = {
            idagrupador: 2,
            refreshToken: "Bearer " + refreshToken,
        };
        const casasApuestas = await JugadasService.listCasas(bodyCasas);
        // console.log('casasApuestas',casasApuestas)
        if (casasApuestas.status) {
            setBancosList(casasApuestas.data[0]);
        }
        const body = {
            fecha: fechaInicio,
            refreshToken: "Bearer " + refreshToken,
        };
        const resp = await SurebetService.getBets(body);
        if (resp.status) {
            setItems(resp.data[0]);
        }
        setLoading(false);
    };

    //MOUNTED
    useEffect(() => {
        // getData();
        $("#kt_datepicker_2").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            onChange: function (selectedDates, dateStr, instance) {
                //console.log("sipe aq");
                setFechaInicio(moment(dateStr).format("yyyy-MM-DD"));
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
    }, []);

    //METODOS
    const estadoFinal = async (id, estado) => {
        const body = {
            idapuesta: id,
            estado: estado,
            refreshToken: "Bearer " + refreshToken,
        };
        //console.log("body", body);
        const resp = await JugadasService.stateFinal(body);
        //console.log("resp estado", resp);
        if (resp.status) {
            toast.success("Apuesta actualizado", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "B",
            });
            getData();
        } else {
            toast.error("rror al actualizar la apuesta", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "B",
            });
        }
        setShow(true);
    };

    const editApuesta = async (data) => {
        //console.log(data);
        setValue("id", data.id_apuesta);
        setValue("banco", data.casa.toUpperCase());
        setValue("evento", data.evento);
        setValue("cuota", data.cuota);
        setValue("deporte", data.deporte);
        setValue("mercado", data.mercado);
        setValue("stake", data.stake);
        setValue("observacion", data.descripcion);
        $("#kt_modal_add_customer").modal("show");
    };
    const resetForm = () => {
        reset({
            casa: "",
            deporte: "",
            evento: "",
            mercado: "",
            cuota: "",
            stake: "",
            observacion: "",
        });
    };

    //ENVIAR DATA DE MODAL
    const onSubmit = async (data) => {
        //console.log("dataaa", data);
        const body = {
            idapuesta: data.id,
            casa: data.banco,
            observacion: data.observacion != null ? data.observacion : "",
            deporte: data.deporte,
            evento: data.evento,
            mercado: data.mercado,
            cuota: data.cuota,
            stake: data.stake,
            refreshToken: "Bearer " + refreshToken,
        };
        //console.log("body", body);
        const respEdit = await JugadasService.editApuesta(body);
        if (respEdit.status) {
            Swal.fire({
                title: "Actualizado",
                icon: "success",
                text: "Datos actualizados con éxito.",
            });
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al actualizar la apuesta.",
            });
        }
        getData();
        $("#kt_modal_add_customer").modal("hide");
    };

    //ELIMINAR
    const handleMyDelete = (data) => {
        Swal.fire({
            icon: "error",
            title: "¿Seguro que desea eliminar la apuesta seleccionada?",
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                confirmDelete(data);
            }
        });
    };

    //CONFIRMACIÓN DE ELIMINACIÓN
    const confirmDelete = async (data) => {
        //console.log("daaataaa", data);
        const body = {
            idapuesta: data.id_apuesta,
            refreshToken: "Bearer " + refreshToken,
        };
        const resp = await JugadasService.deleteBet(body);
        if (resp.status) {
            Swal.fire({
                title: "Eliminado",
                icon: "success",
                text: "La apuesta ha sido eliminada con éxito.",
            });
            getData();
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Hubo un error al eliminar la apuesta.",
            });
        }
    };

    const getReport = async () => {
        setLoading2(true);
        const body = {
            fecha: fechaInicio,
            tcambio: user.tipocambio,
            refreshToken: "Bearer " + refreshToken,
        };
        const resp = await ReportesService.getGanancias(body);
        if (resp.status) {
            // console.log("resp.data[0]", resp.data[0]);
            setItemsReporte(resp.data[0]);
            $("#kt_modal_reporte").modal("show");
        }
        setLoading2(false);
        // console.log("resp", resp);
    };
    const toggleAcord = (id) => {
        const acord = document.getElementById("kt_accordion_2_item_" + id);

        if (acord.classList.contains("hide-acord")) {
            acord.classList.remove("hide-acord");
            acord.classList.add("show-acord");
        } else {
            acord.classList.add("hide-acord");
            acord.classList.remove("show-acord");
        }
    };

    return (
        user.frqpoint == 0 ?
            <div className="container-xxl mw-100 px-0">
                <div className="toolbar" id="kt_toolbar">
                    <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                    <div className="overlay-wrapper">
                        <div className="card">
                            <div className="card-header border-0 p-6">
                                <div className="card-toolbar w-100">
                                    <div className="d-flex flex-column flex-lg-row  w-100 h-100 justify-content-between">
                                        <div className="row mx-0 w-100 ">
                                            <div className="col-12 col-md-4 col-xxl-2">
                                                <label htmlFor="" className="form-label">
                                                    Fecha
                                                </label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    placeholder="Fecha Inicio"
                                                    id="kt_datepicker_2"
                                                />
                                            </div>
                                            <div className="col-6 col-md-4 col-xxl-5 mt-4 mt-md-0">
                                                <div className="d-flex align-content-end justify-content-md-start justify-content-end h-100  w-100">
                                                    <button type="button" className="btn btn-primary mt-auto" onClick={getData}>
                                                        {loading ? (
                                                            <span className="indicator-label">
                                                                Buscando...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                <i className="bi bi-search" /> Buscar
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                            {/* <div className="col-6 col-md-4 col-xxl-5">
                                            <div className="d-flex align-content-end justify-content-md-end justify-content-start h-100  w-100">
                                                <button type="button" className="btn btn-secondary mt-auto" onClick={() => getReport()}>
                                                    <FaChartBar></FaChartBar> {loading2 ? "Buscando.." : "Reporte"}
                                                </button>
                                            </div>
                                        </div> */}
                                            <div className="d-flex align-content-end justify-content-lg-end justify-content-center h-100  w-100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-flex flex-column flex-md-row ">
                                    <div className="accordion accordion-icon-toggle w-100" id="kt_accordion_2">
                                        {items.map((bet, index) => {
                                            return (
                                                <div className="card_acordion mb-5 w-100 border-1" key={index}>
                                                    <div
                                                        className={`accordion-header content py-3 d-flex px-3 border-2 border border-active active ${bet.estado == 1
                                                            ? "bg-light-success  border-success"
                                                            : bet.estado == 3
                                                                ? "bg-light-warning border-warning"
                                                                : bet.estado == 0
                                                                    ? "bg-light-danger border-danger"
                                                                    : ""
                                                            }`}
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={"#kt_accordion_2_item_" + index}
                                                    // onClick={()=>toggleAcord(index)}
                                                    >
                                                        {/* Header del acordion */}
                                                        <div className="d-flex gap-2">
                                                            <span className="font-bold">{bet.deporte}</span> /
                                                            <span className="">{bet.evento}</span> /
                                                            <span className="">{bet.fecha_evento}</span>
                                                        </div>
                                                    </div>
                                                    {/* Body del acordion */}
                                                    <div
                                                        id={"kt_accordion_2_item_" + index}
                                                        className="fs-6 collapse px-6 py-2"
                                                        data-bs-parent="#kt_accordion_2"
                                                    >
                                                        <div className="d-flex flex-column ">
                                                            <div
                                                                className={`row justify-content-md-center mt-2 text-center ${bet.estado == 1
                                                                    ? "bg-light-success "
                                                                    : bet.estado == 3
                                                                        ? "bg-light-warning"
                                                                        : bet.estado == 0
                                                                            ? "bg-light-danger"
                                                                            : ""
                                                                    }`}
                                                            >
                                                                <div className="col-6 col-xl-4 col-xxl-3 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">Casa</div>
                                                                    <div className="column_content p-4">{bet.casa}</div>
                                                                </div>
                                                                <div className="col-6  col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-primary">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                        Apuesta
                                                                    </div>
                                                                    <div className="column_content p-4">{bet.mercado}</div>
                                                                </div>
                                                                <div className="col-6   col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">Cuotas</div>
                                                                    <div className="column_content p-4">{bet.cuota}</div>
                                                                </div>
                                                                <div className="col-6  col-xl-6 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">Stake</div>
                                                                    <div className="column_content p-4">{bet.stake}</div>
                                                                </div>
                                                                <div className="col-12 col-xl-6 col-xxl-3 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                        Ganancia
                                                                    </div>
                                                                    <div className="column_content p-4">{bet.yield}</div>
                                                                </div>
                                                                <div className="col-12 col-xl-6 col-xxl-6 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                        Observación
                                                                    </div>
                                                                    <div className="column_content p-4">{bet.observacion}</div>
                                                                </div>
                                                                <div className="col-12 col-xl-6 col-xxl-6 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                        Acciones
                                                                    </div>
                                                                    <div className="column_content p-4 gap-5 d-flex flex-column flex-xl-row justify-content-center justify-content-xxl-evenly">
                                                                        {bet.estado != 2 ? (
                                                                            <div className="d-flex justify-content-center">
                                                                                <OverlayTrigger placement="top" overlay={tooltipRes}>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-primary"
                                                                                        data-bs-toggle="tooltip"
                                                                                        data-bs-placement="top"
                                                                                        title="Restablecer"
                                                                                        onClick={() => estadoFinal(bet.id_apuesta, 2)}
                                                                                    >
                                                                                        <FaHistory />
                                                                                    </button>
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                <div className="d-flex gap-2 gap-xxl-10 justify-content-center">
                                                                                    <OverlayTrigger placement="top" overlay={tooltipEd}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-primary btn-sm"
                                                                                            data-bs-toggle="tooltip"
                                                                                            data-bs-placement="top"
                                                                                            title="Editar"
                                                                                            onClick={() => editApuesta(bet)}
                                                                                        >
                                                                                            <FaRegEdit />
                                                                                        </button>
                                                                                    </OverlayTrigger>
                                                                                    <OverlayTrigger placement="top" overlay={tooltipE}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-danger btn-sm"
                                                                                            data-bs-toggle="tooltip"
                                                                                            data-bs-placement="top"
                                                                                            title="Eliminar"
                                                                                            onClick={() => handleMyDelete(bet)}
                                                                                        >
                                                                                            <FaTrash />
                                                                                        </button>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                                <div className="d-flex gap-2 gap-xxl-10 justify-content-center">
                                                                                    <OverlayTrigger placement="top" overlay={tooltipG}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-success btn-sm"
                                                                                            data-bs-toggle="tooltip"
                                                                                            data-bs-placement="top"
                                                                                            title="Ganado"
                                                                                            onClick={() => estadoFinal(bet.id_apuesta, 1)}
                                                                                        >
                                                                                            <FaDonate />
                                                                                        </button>
                                                                                    </OverlayTrigger>
                                                                                    <OverlayTrigger placement="top" overlay={tooltipD}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-warning btn-sm"
                                                                                            data-bs-toggle="tooltip"
                                                                                            data-bs-placement="top"
                                                                                            title="Devuelto"
                                                                                            onClick={() => estadoFinal(bet.id_apuesta, 3)}
                                                                                        >
                                                                                            <FaUndo />
                                                                                        </button>
                                                                                    </OverlayTrigger>
                                                                                    <OverlayTrigger placement="top" overlay={tooltipP}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-danger btn-sm"
                                                                                            data-bs-toggle="tooltip"
                                                                                            data-bs-placement="top"
                                                                                            title="Perdido"
                                                                                            onClick={() => estadoFinal(bet.id_apuesta, 0)}
                                                                                        >
                                                                                            <FaBan />
                                                                                        </button>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* toast */}
                        <ToastContainer
                            theme={localStorage.getItem("userTheme") === "dark" ? "dark" : "light"}
                            className="container_bets "
                            enableMultiContainer
                            containerId={"B"}
                            autoClose={1000}
                            position={toast.POSITION.BOTTOM_RIGHT}
                        />
                        {/* modal */}
                        <div className="modal fade" id="kt_modal_add_customer" tabIndex="-1" aria-modal="true" role="dialog">
                            <div className="modal-dialog modal-dialog-centered mw-650px">
                                <div className="modal-content">
                                    <form
                                        className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                        id="kt_modal_add_customer_form"
                                        onSubmit={handleSubmit(onSubmit)}
                                    >
                                        <div className="modal-header" id="kt_modal_add_customer_header">
                                            <h2 className="fw-bold">Editar Apuesta</h2>
                                            <div
                                                id="kt_modal_add_customer_close"
                                                className="btn btn-icon btn-sm btn-active-icon-primary"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm}
                                            >
                                                <span className="svg-icon svg-icon-1">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <rect
                                                            opacity="0.5"
                                                            x="6"
                                                            y="17.3137"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(-45 6 17.3137)"
                                                            fill="currentColor"
                                                        ></rect>
                                                        <rect
                                                            x="7.41422"
                                                            y="6"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(45 7.41422 6)"
                                                            fill="currentColor"
                                                        ></rect>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-body py-10 px-lg-17">
                                            <div
                                                className="scroll-y me-n7 pe-7"
                                                id="kt_modal_add_customer_scroll"
                                                data-kt-scroll="true"
                                                data-kt-scroll-activate="{default: false, lg: true}"
                                                data-kt-scroll-max-height="auto"
                                                data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                                                data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                                                data-kt-scroll-offset="300px"
                                                style={{ maxHeight: "1035px" }}
                                            >
                                                <div className="fv-row mb-7 fv-plugins-icon-container">
                                                    <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                        <span className="">Casa</span>
                                                    </label>
                                                    <select
                                                        className="select2-selection select2-selection--single form-select form-select-solid"
                                                        name="bancos"
                                                        {...register("banco")}
                                                    >
                                                        {/* <option value="">Seleccionar</option> */}
                                                        {bancosList.map((estado) => (
                                                            <option key={estado.id_constante} value={estado.nombre_constante}>
                                                                {estado.nombre}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                        <span className="">Deporte</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-solid"
                                                        placeholder="Deporte"
                                                        name="deporte"
                                                        {...register("deporte")}
                                                    />
                                                    <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                        <span className="">Evento</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-solid"
                                                        placeholder="Evento"
                                                        name="evento"
                                                        {...register("evento")}
                                                    />
                                                    <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                        <span className="">Mercado</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-solid"
                                                        placeholder="Mercado"
                                                        name="mercado"
                                                        {...register("mercado")}
                                                    />
                                                    <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                        <span className="">Cuota</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-solid"
                                                        placeholder="Cuota"
                                                        name="cuota"
                                                        {...register("cuota")}
                                                    />
                                                    <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                        <span className="">Stake</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-solid"
                                                        placeholder="Stake"
                                                        name="stake"
                                                        {...register("stake")}
                                                    />
                                                    {/* <label className="fs-6 fw-semibold my-2" htmlFor="">
                                        <span className="">Ganancia</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control form-control-solid"
                                        placeholder="Ganancia"
                                        name="ganancia"
                                        {...register("ganancia")}
                                    /> */}
                                                    <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                        <span className="">Observación</span>
                                                    </label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control form-control-solid"
                                                        placeholder="Observación"
                                                        name="observacion"
                                                        data-kt-autosize="true"
                                                        {...register("observacion")}
                                                    />
                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer flex-center">
                                            <button
                                                type="reset"
                                                id="kt_modal_add_customer_cancel"
                                                className="btn btn-danger me-3"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm}
                                            >
                                                Cerrar
                                            </button>
                                            <button type="submit" id="kt_modal_add_customer_submit" className="btn btn-primary">
                                                <span className="indicator-label">Editar</span>
                                                <span className="indicator-progress">
                                                    Cargando....
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="kt_modal_reporte" tabIndex="-1" aria-modal="true" role="dialog">
                            <div className="modal-dialog modal-dialog-centered mw-650px">
                                <div className="modal-content">
                                    <form
                                        className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                        id="kt_modal_reporte_form"
                                        onSubmit={handleSubmit(onSubmit)}
                                    >
                                        <div className="modal-header" id="kt_modal_reporte_header">
                                            <h2 className="fw-bold">Reporte Diario</h2>
                                            <div
                                                id="kt_modal_reporte_close"
                                                className="btn btn-icon btn-sm btn-active-icon-primary"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm}
                                            >
                                                <span className="svg-icon svg-icon-1">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <rect
                                                            opacity="0.5"
                                                            x="6"
                                                            y="17.3137"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(-45 6 17.3137)"
                                                            fill="currentColor"
                                                        ></rect>
                                                        <rect
                                                            x="7.41422"
                                                            y="6"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(45 7.41422 6)"
                                                            fill="currentColor"
                                                        ></rect>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-body p-0">
                                            <div
                                                className="scroll-y me-n7 pe-7"
                                                id="kt_modal_reporte_scroll"
                                                data-kt-scroll="true"
                                                data-kt-scroll-activate="{default: false, lg: true}"
                                                data-kt-scroll-max-height="auto"
                                                data-kt-scroll-dependencies="#kt_modal_reporte_header"
                                                data-kt-scroll-wrappers="#kt_modal_reporte_scroll"
                                                data-kt-scroll-offset="300px"
                                                style={{ maxHeight: "1035px" }}
                                            >
                                                <div className="row mx-0 p-2 border-2 border-bottom">
                                                    <div className="col-3 text-center text-center">
                                                        <span className="font-bold">Ganancia</span>
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        <span className="font-bold">Movido</span>
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        <span className="font-bold">Pendiente</span>
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        <span className="font-bold">Yield</span>
                                                    </div>
                                                </div>
                                                <div className="row d-flex mx-0 p-2">
                                                    <div className="col-3 text-center">
                                                        <span className="">
                                                            {itemsReporte.ganancia != null
                                                                ? parseFloat(itemsReporte.ganancia).toFixed(2)
                                                                : (0).toFixed(2)}
                                                        </span>
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        <span className="">
                                                            {itemsReporte.movido != null
                                                                ? parseFloat(itemsReporte.movido).toFixed(2)
                                                                : (0).toFixed(2)}
                                                        </span>
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        <span className="">
                                                            {itemsReporte.pendiente != null
                                                                ? parseFloat(itemsReporte.pendiente).toFixed(2)
                                                                : (0).toFixed(2)}
                                                        </span>
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        <span className="">
                                                            {itemsReporte.yield != null && itemsReporte.yield != "NaN"
                                                                ? itemsReporte.yield
                                                                : 0}{" "}
                                                            %
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer flex-center">
                                            <button
                                                type="reset"
                                                id="kt_modal_reporte_cancel"
                                                className="btn btn-danger me-3"
                                                data-bs-dismiss="modal"
                                            >
                                                Cerrar
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="overlay-layer card-rounded bg-black bg-opacity-50">
                            <div className="d-flex flex-column align-items-center mt-10">
                                <div className="spinner-border text-white" role="status"></div>
                                <span>Buscando datos...</span>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div> : 
            <Main>
                <div className="container-xxl mw-100">
                    <div className="toolbar" id="kt_toolbar">
                        <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                            <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                        </div>
                    </div>
                    <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                        <div className="overlay-wrapper">
                            <div className="card">
                                <div className="card-header border-0 p-6">
                                    <div className="card-toolbar w-100">
                                        <div className="d-flex flex-column flex-lg-row  w-100 h-100 justify-content-between">
                                            <div className="row mx-0 w-100 ">
                                                <div className="col-12 col-md-4 col-xxl-2">
                                                    <label htmlFor="" className="form-label">
                                                        Fecha
                                                    </label>
                                                    <input
                                                        className="form-control form-control-solid"
                                                        placeholder="Fecha Inicio"
                                                        id="kt_datepicker_2"
                                                    />
                                                </div>
                                                <div className="col-6 col-md-4 col-xxl-5 mt-4 mt-md-0">
                                                    <div className="d-flex align-content-end justify-content-md-start justify-content-end h-100  w-100">
                                                        <button type="button" className="btn btn-primary mt-auto" onClick={getData}>
                                                            {loading ? (
                                                                <span className="indicator-label">
                                                                    Buscando...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    <i className="bi bi-search" /> Buscar
                                                                </span>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                                {/* <div className="col-6 col-md-4 col-xxl-5">
                                                <div className="d-flex align-content-end justify-content-md-end justify-content-start h-100  w-100">
                                                    <button type="button" className="btn btn-secondary mt-auto" onClick={() => getReport()}>
                                                        <FaChartBar></FaChartBar> {loading2 ? "Buscando.." : "Reporte"}
                                                    </button>
                                                </div>
                                            </div> */}
                                                <div className="d-flex align-content-end justify-content-lg-end justify-content-center h-100  w-100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="d-flex flex-column flex-md-row ">
                                        <div className="accordion accordion-icon-toggle w-100" id="kt_accordion_2">
                                            {items.map((bet, index) => {
                                                return (
                                                    <div className="card_acordion mb-5 w-100 border-1" key={index}>
                                                        <div
                                                            className={`accordion-header content py-3 d-flex px-3 border-2 border border-active active ${bet.estado == 1
                                                                ? "bg-light-success  border-success"
                                                                : bet.estado == 3
                                                                    ? "bg-light-warning border-warning"
                                                                    : bet.estado == 0
                                                                        ? "bg-light-danger border-danger"
                                                                        : ""
                                                                }`}
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={"#kt_accordion_2_item_" + index}
                                                        // onClick={()=>toggleAcord(index)}
                                                        >
                                                            {/* Header del acordion */}
                                                            <div className="d-flex gap-2">
                                                                <span className="font-bold">{bet.deporte}</span> /
                                                                <span className="">{bet.evento}</span> /
                                                                <span className="">{bet.fecha_evento}</span>
                                                            </div>
                                                        </div>
                                                        {/* Body del acordion */}
                                                        <div
                                                            id={"kt_accordion_2_item_" + index}
                                                            className="fs-6 collapse px-6 py-2"
                                                            data-bs-parent="#kt_accordion_2"
                                                        >
                                                            <div className="d-flex flex-column ">
                                                                <div
                                                                    className={`row justify-content-md-center mt-2 text-center ${bet.estado == 1
                                                                        ? "bg-light-success "
                                                                        : bet.estado == 3
                                                                            ? "bg-light-warning"
                                                                            : bet.estado == 0
                                                                                ? "bg-light-danger"
                                                                                : ""
                                                                        }`}
                                                                >
                                                                    <div className="col-6 col-xl-4 col-xxl-3 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_head p-2 text-uppercase fw-semibold">Casa</div>
                                                                        <div className="column_content p-4">{bet.casa}</div>
                                                                    </div>
                                                                    <div className="col-6  col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-primary">
                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                            Apuesta
                                                                        </div>
                                                                        <div className="column_content p-4">{bet.mercado}</div>
                                                                    </div>
                                                                    <div className="col-6   col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_head p-2 text-uppercase fw-semibold">Cuotas</div>
                                                                        <div className="column_content p-4">{bet.cuota}</div>
                                                                    </div>
                                                                    <div className="col-6  col-xl-6 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_head p-2 text-uppercase fw-semibold">Stake</div>
                                                                        <div className="column_content p-4">{bet.stake}</div>
                                                                    </div>
                                                                    <div className="col-12 col-xl-6 col-xxl-3 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                            Ganancia
                                                                        </div>
                                                                        <div className="column_content p-4">{bet.yield}</div>
                                                                    </div>
                                                                    <div className="col-12 col-xl-6 col-xxl-6 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                            Observación
                                                                        </div>
                                                                        <div className="column_content p-4">{bet.observacion}</div>
                                                                    </div>
                                                                    <div className="col-12 col-xl-6 col-xxl-6 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                            Acciones
                                                                        </div>
                                                                        <div className="column_content p-4 gap-5 d-flex flex-column flex-xl-row justify-content-center justify-content-xxl-evenly">
                                                                            {bet.estado != 2 ? (
                                                                                <div className="d-flex justify-content-center">
                                                                                    <OverlayTrigger placement="top" overlay={tooltipRes}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-primary"
                                                                                            data-bs-toggle="tooltip"
                                                                                            data-bs-placement="top"
                                                                                            title="Restablecer"
                                                                                            onClick={() => estadoFinal(bet.id_apuesta, 2)}
                                                                                        >
                                                                                            <FaHistory />
                                                                                        </button>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                            ) : (
                                                                                <>
                                                                                    <div className="d-flex gap-2 gap-xxl-10 justify-content-center">
                                                                                        <OverlayTrigger placement="top" overlay={tooltipEd}>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-primary btn-sm"
                                                                                                data-bs-toggle="tooltip"
                                                                                                data-bs-placement="top"
                                                                                                title="Editar"
                                                                                                onClick={() => editApuesta(bet)}
                                                                                            >
                                                                                                <FaRegEdit />
                                                                                            </button>
                                                                                        </OverlayTrigger>
                                                                                        <OverlayTrigger placement="top" overlay={tooltipE}>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-danger btn-sm"
                                                                                                data-bs-toggle="tooltip"
                                                                                                data-bs-placement="top"
                                                                                                title="Eliminar"
                                                                                                onClick={() => handleMyDelete(bet)}
                                                                                            >
                                                                                                <FaTrash />
                                                                                            </button>
                                                                                        </OverlayTrigger>
                                                                                    </div>
                                                                                    <div className="d-flex gap-2 gap-xxl-10 justify-content-center">
                                                                                        <OverlayTrigger placement="top" overlay={tooltipG}>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-success btn-sm"
                                                                                                data-bs-toggle="tooltip"
                                                                                                data-bs-placement="top"
                                                                                                title="Ganado"
                                                                                                onClick={() => estadoFinal(bet.id_apuesta, 1)}
                                                                                            >
                                                                                                <FaDonate />
                                                                                            </button>
                                                                                        </OverlayTrigger>
                                                                                        <OverlayTrigger placement="top" overlay={tooltipD}>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-warning btn-sm"
                                                                                                data-bs-toggle="tooltip"
                                                                                                data-bs-placement="top"
                                                                                                title="Devuelto"
                                                                                                onClick={() => estadoFinal(bet.id_apuesta, 3)}
                                                                                            >
                                                                                                <FaUndo />
                                                                                            </button>
                                                                                        </OverlayTrigger>
                                                                                        <OverlayTrigger placement="top" overlay={tooltipP}>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-danger btn-sm"
                                                                                                data-bs-toggle="tooltip"
                                                                                                data-bs-placement="top"
                                                                                                title="Perdido"
                                                                                                onClick={() => estadoFinal(bet.id_apuesta, 0)}
                                                                                            >
                                                                                                <FaBan />
                                                                                            </button>
                                                                                        </OverlayTrigger>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* toast */}
                            <ToastContainer
                                theme={localStorage.getItem("userTheme") === "dark" ? "dark" : "light"}
                                className="container_bets "
                                enableMultiContainer
                                containerId={"B"}
                                autoClose={1000}
                                position={toast.POSITION.BOTTOM_RIGHT}
                            />
                            {/* modal */}
                            <div className="modal fade" id="kt_modal_add_customer" tabIndex="-1" aria-modal="true" role="dialog">
                                <div className="modal-dialog modal-dialog-centered mw-650px">
                                    <div className="modal-content">
                                        <form
                                            className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                            id="kt_modal_add_customer_form"
                                            onSubmit={handleSubmit(onSubmit)}
                                        >
                                            <div className="modal-header" id="kt_modal_add_customer_header">
                                                <h2 className="fw-bold">Editar Apuesta</h2>
                                                <div
                                                    id="kt_modal_add_customer_close"
                                                    className="btn btn-icon btn-sm btn-active-icon-primary"
                                                    data-bs-dismiss="modal"
                                                    onClick={resetForm}
                                                >
                                                    <span className="svg-icon svg-icon-1">
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <rect
                                                                opacity="0.5"
                                                                x="6"
                                                                y="17.3137"
                                                                width="16"
                                                                height="2"
                                                                rx="1"
                                                                transform="rotate(-45 6 17.3137)"
                                                                fill="currentColor"
                                                            ></rect>
                                                            <rect
                                                                x="7.41422"
                                                                y="6"
                                                                width="16"
                                                                height="2"
                                                                rx="1"
                                                                transform="rotate(45 7.41422 6)"
                                                                fill="currentColor"
                                                            ></rect>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="modal-body py-10 px-lg-17">
                                                <div
                                                    className="scroll-y me-n7 pe-7"
                                                    id="kt_modal_add_customer_scroll"
                                                    data-kt-scroll="true"
                                                    data-kt-scroll-activate="{default: false, lg: true}"
                                                    data-kt-scroll-max-height="auto"
                                                    data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                                                    data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                                                    data-kt-scroll-offset="300px"
                                                    style={{ maxHeight: "1035px" }}
                                                >
                                                    <div className="fv-row mb-7 fv-plugins-icon-container">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Casa</span>
                                                        </label>
                                                        <select
                                                            className="select2-selection select2-selection--single form-select form-select-solid"
                                                            name="bancos"
                                                            {...register("banco")}
                                                        >
                                                            {/* <option value="">Seleccionar</option> */}
                                                            {bancosList.map((estado) => (
                                                                <option key={estado.id_constante} value={estado.nombre_constante}>
                                                                    {estado.nombre}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Deporte</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Deporte"
                                                            name="deporte"
                                                            {...register("deporte")}
                                                        />
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Evento</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Evento"
                                                            name="evento"
                                                            {...register("evento")}
                                                        />
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Mercado</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Mercado"
                                                            name="mercado"
                                                            {...register("mercado")}
                                                        />
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Cuota</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Cuota"
                                                            name="cuota"
                                                            {...register("cuota")}
                                                        />
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Stake</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Stake"
                                                            name="stake"
                                                            {...register("stake")}
                                                        />
                                                        {/* <label className="fs-6 fw-semibold my-2" htmlFor="">
                                            <span className="">Ganancia</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control form-control-solid"
                                            placeholder="Ganancia"
                                            name="ganancia"
                                            {...register("ganancia")}
                                        /> */}
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Observación</span>
                                                        </label>
                                                        <textarea
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Observación"
                                                            name="observacion"
                                                            data-kt-autosize="true"
                                                            {...register("observacion")}
                                                        />
                                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer flex-center">
                                                <button
                                                    type="reset"
                                                    id="kt_modal_add_customer_cancel"
                                                    className="btn btn-danger me-3"
                                                    data-bs-dismiss="modal"
                                                    onClick={resetForm}
                                                >
                                                    Cerrar
                                                </button>
                                                <button type="submit" id="kt_modal_add_customer_submit" className="btn btn-primary">
                                                    <span className="indicator-label">Editar</span>
                                                    <span className="indicator-progress">
                                                        Cargando....
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="kt_modal_reporte" tabIndex="-1" aria-modal="true" role="dialog">
                                <div className="modal-dialog modal-dialog-centered mw-650px">
                                    <div className="modal-content">
                                        <form
                                            className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                            id="kt_modal_reporte_form"
                                            onSubmit={handleSubmit(onSubmit)}
                                        >
                                            <div className="modal-header" id="kt_modal_reporte_header">
                                                <h2 className="fw-bold">Reporte Diario</h2>
                                                <div
                                                    id="kt_modal_reporte_close"
                                                    className="btn btn-icon btn-sm btn-active-icon-primary"
                                                    data-bs-dismiss="modal"
                                                    onClick={resetForm}
                                                >
                                                    <span className="svg-icon svg-icon-1">
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <rect
                                                                opacity="0.5"
                                                                x="6"
                                                                y="17.3137"
                                                                width="16"
                                                                height="2"
                                                                rx="1"
                                                                transform="rotate(-45 6 17.3137)"
                                                                fill="currentColor"
                                                            ></rect>
                                                            <rect
                                                                x="7.41422"
                                                                y="6"
                                                                width="16"
                                                                height="2"
                                                                rx="1"
                                                                transform="rotate(45 7.41422 6)"
                                                                fill="currentColor"
                                                            ></rect>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="modal-body p-0">
                                                <div
                                                    className="scroll-y me-n7 pe-7"
                                                    id="kt_modal_reporte_scroll"
                                                    data-kt-scroll="true"
                                                    data-kt-scroll-activate="{default: false, lg: true}"
                                                    data-kt-scroll-max-height="auto"
                                                    data-kt-scroll-dependencies="#kt_modal_reporte_header"
                                                    data-kt-scroll-wrappers="#kt_modal_reporte_scroll"
                                                    data-kt-scroll-offset="300px"
                                                    style={{ maxHeight: "1035px" }}
                                                >
                                                    <div className="row mx-0 p-2 border-2 border-bottom">
                                                        <div className="col-3 text-center text-center">
                                                            <span className="font-bold">Ganancia</span>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <span className="font-bold">Movido</span>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <span className="font-bold">Pendiente</span>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <span className="font-bold">Yield</span>
                                                        </div>
                                                    </div>
                                                    <div className="row d-flex mx-0 p-2">
                                                        <div className="col-3 text-center">
                                                            <span className="">
                                                                {itemsReporte.ganancia != null
                                                                    ? parseFloat(itemsReporte.ganancia).toFixed(2)
                                                                    : (0).toFixed(2)}
                                                            </span>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <span className="">
                                                                {itemsReporte.movido != null
                                                                    ? parseFloat(itemsReporte.movido).toFixed(2)
                                                                    : (0).toFixed(2)}
                                                            </span>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <span className="">
                                                                {itemsReporte.pendiente != null
                                                                    ? parseFloat(itemsReporte.pendiente).toFixed(2)
                                                                    : (0).toFixed(2)}
                                                            </span>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <span className="">
                                                                {itemsReporte.yield != null && itemsReporte.yield != "NaN"
                                                                    ? itemsReporte.yield
                                                                    : 0}{" "}
                                                                %
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer flex-center">
                                                <button
                                                    type="reset"
                                                    id="kt_modal_reporte_cancel"
                                                    className="btn btn-danger me-3"
                                                    data-bs-dismiss="modal"
                                                >
                                                    Cerrar
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {loading ? (
                            <div className="overlay-layer card-rounded bg-black bg-opacity-50">
                                <div className="d-flex flex-column align-items-center mt-10">
                                    <div className="spinner-border text-white" role="status"></div>
                                    <span>Buscando datos...</span>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </Main>

    );
};

export default JugadasApuestas;
