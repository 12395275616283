/* eslint-disable */
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../Components/utils/BreadCrumb";
import HistorialService from "../../../api/services/historialService";
import MUIDataTable from "mui-datatables";
import moment from "moment/moment";
import Main from "../../../layout/main";
import { useUserContext } from "../../../Providers/UserProvider";
const HistorialConexiones = () => {
    const { user } = useUserContext();
    //HOOKS
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [stake, setStake] = useState(user.stake);
    const [userTc, setUserTc] = useState(user.tipocambio);
    //BreadCrumb
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/historial", name: "Historial", active: false },
        { path: "/historial-conexiones", name: "Conexiones", active: false },
    ];
    //DATOS
    const refreshToken = localStorage.getItem("refreshToken");
    //COLUMNAS
    const columns1 = [
        // {
        //     name: "id_casacuenta",
        //     label: "",
        //     options: {
        //         display: false,
        //     },
        // },
        {
            name: "ipcliente",
            label: "IP",
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "fecha",
            label: "Fecha",
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span>{moment(value).format("yyyy-MM-DD")}</span>;
                },
            },
        },
        {
            name: "svg",
            label: "Imagen",
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <img src={value} alt="value" className=" h-25px" />;
                },
            },
        },
        // {
        //     name: "ACCIONES",
        //     options: {
        //         sort: false,
        //         customBodyRender: (value, tableMeta, updateValue) => {
        //             return (
        //                 // <div className="d-flex gap-3 flex-wrap justify-content-center">
        //                 //     <OverlayTrigger placement="top" overlay={tooltipE}>
        //                 //         <button className="btn btn-success btn-icon" onClick={() => editCuenta(tableMeta)}>
        //                 //             <FaPencilAlt></FaPencilAlt>
        //                 //         </button>
        //                 //     </OverlayTrigger>
        //                 //     <OverlayTrigger placement="top" overlay={tooltipC}>
        //                 //         <button className="btn btn-danger btn-icon" onClick={() => handleMyState(tableMeta)}>
        //                 //             <FaSync></FaSync>
        //                 //         </button>
        //                 //     </OverlayTrigger>
        //                 // </div>
        //                 <></>
        //             );
        //         },
        //         setCellProps: () => ({
        //             align: "center",
        //         }),
        //         setCellHeaderProps: (value) => ({
        //             className: "centeredHeaderCell",
        //         }),
        //     },
        // },
    ];
    //OPTIONS DEL DATATABLE
    const options = {
        print: false,
        viewColumns: false,
        filter: false,
        filterType: "dropdown",
        selectableRows: "none",
        responsive: "standard",
        fixedSelectColumn: false,
        download: false,
        textLabels: {
            body: {
                noMatch: "No se encontraron datos",
                toolTip: "Sort",
                columnHeaderTooltip: (column) => `Ordenador por ${column.label}`,
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Elementos por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscador",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtrar Tabla",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Resetear",
            },
            viewColumns: {
                title: "Mostrar Columnas",
                titleAria: "Mostrar/Ocultar Columnas",
            },
            selectedRows: {
                text: "fila(s) seleccionada(s)",
                delete: "Eliminar",
                deleteAria: "Eliminar fila seleccionada",
            },
            centeredTableHead: {
                "& > span": {
                    justifyContent: "center",
                },
            },
        },
    };

    //GET DATA
    const showData = async () => {
        const body = {
            refreshToken: "Bearer " + refreshToken,
        };
        setLoading(true);
        const resp = await HistorialService.getConexiones(body);
        //console.log("resp conexiones", resp);
        if (resp.status) {
            setItems(resp.data[0]);
        } else {
            setItems([]);
        }
        setLoading(false);
    };

    //MOUNTED
    useEffect(() => {
        showData();
    }, []);
    return (
        <Main userStake={(datos) => setStake(datos)} userTC={(datos) => setUserTc(datos)}>
            <div className="container-xxl mw-100 ">
                <div className="toolbar" id="kt_toolbar">
                    <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                    <div className="overlay-wrapper">
                        <div className="card p-0">
                            <div className="card-body p-0">
                                <MUIDataTable
                                    title={<span className="fw-bolder fs-14">Historial de Conexiones</span>}
                                    data={items}
                                    columns={columns1}
                                    options={options}
                                />
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="overlay-layer card-rounded bg-black over_loading">
                            <div className="spinner-border text-primary" role="status"></div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </Main>
    );
};
export default HistorialConexiones;
