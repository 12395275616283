/* eslint-disable */
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../Components/utils/BreadCrumb";
import moment from "moment/moment";
import SurebetService from "../../../api/services/surebetService";
import JugadasService from "../../../api/services/jugadasService";
import ReportesService from "../../../api/services/reportesService";
import Select from "react-select";
import { useForm } from "react-hook-form";
import {
    FaRegEdit,
    FaTrash,
    FaDonate,
    FaUndo,
    FaBan,
    FaExclamationCircle,
    FaCheckCircle,
    FaHistory,
    FaRegThumbsUp,
    FaRegThumbsDown,
    FaChartBar
} from "react-icons/fa";
// import Toast from "react-bootstrap/Toast";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Main from "../../../layout/main";
import { useUserContext } from "../../../Providers/UserProvider";
import "../jugadas.css";
const today = new Date();
const tipos = [
    {
        text: "Todos",
        value: "2",
    },
    {
        text: "Pendientes",
        value: "1",
    },
    {
        text: "Cerradas",
        value: "0",
    },
];
const tooltipMid = (
    <Tooltip id="tooltipMid">
        <strong>Middle</strong>
    </Tooltip>
);
const tooltipAMid = (
    <Tooltip id="tooltipAMid">
        <strong>AntiMiddle</strong>
    </Tooltip>
);
const tooltipEd = (
    <Tooltip id="tooltipEd">
        <strong>Editar</strong>
    </Tooltip>
);
const tooltipE = (
    <Tooltip id="tooltipE">
        <strong>Eliminar</strong>
    </Tooltip>
);
const tooltipG = (
    <Tooltip id="tooltipG">
        <strong>Ganado</strong>
    </Tooltip>
);
const tooltipD = (
    <Tooltip id="tooltipD">
        <strong>Devuelto</strong>
    </Tooltip>
);
const tooltipP = (
    <Tooltip id="tooltipP">
        <strong>Perdido</strong>
    </Tooltip>
);

const tooltipRes = (
    <Tooltip id="tooltipRes">
        <strong>Restablecer</strong>
    </Tooltip>
);
const JugadasSureBets = () => {
    //USE FORM
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm();
    //hooks
    const [filtros, setFiltros] = useState({
        f_inicio: moment(today).format("yyyy-MM-DD"),
        tipo: "2",
    });
    const [items, setItems] = useState([]);
    const [itemsReporte, setItemsReporte] = useState([]);
    const [alertColor, setAlertColor] = useState("");
    const [alertMsg, setAlertMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [show, setShow] = useState(false);
    const [bancosList, setBancosList] = useState([]);
    const { user } = useUserContext();
    //ESTILOS
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: localStorage.getItem("userTheme") === "dark" ? "#1b1b29" : "#f9f9f9",
            border: "0px",
            fontWeight: "500",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? localStorage.getItem("userTheme") === "dark"
                        ? "#2B2B3D"
                        : "#D0D2D7"
                    : isFocused
                        ? localStorage.getItem("userTheme") === "dark"
                            ? "#2B2B3D"
                            : "#F4F6FA"
                        : localStorage.getItem("userTheme") === "dark"
                            ? "#1b1b29"
                            : "#f9f9f9",
                color: isSelected
                    ? localStorage.getItem("userTheme") === "dark"
                        ? "white"
                        : "black"
                    : localStorage.getItem("userTheme") === "dark"
                        ? "white"
                        : "black",
                fontWeight: "500",
                cursor: "pointer",
            };
        },
        placeholder: (styles) => ({ ...styles }),
        singleValue: (styles) => ({
            ...styles,
            color: localStorage.getItem("userTheme") === "dark" ? "white" : "black",
            fontWeight: "500",
        }),
        valueContainer: (styles) => ({ ...styles, padding: "0.85rem" }),
        menu: (styles) => ({
            ...styles,
            backgroundColor: localStorage.getItem("userTheme") === "dark" ? "#1b1b29" : "#f9f9f9",
        }),
    };
    //BreadCrumb
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { name: "Jugadas", active: false },
        { path: "/jugadas-surebets", name: "SureBets", active: false },
    ];
    //DATOS LOCALSTORAGE
    const refreshToken = localStorage.getItem("refreshToken");
    //TRAER DATA
    const getData = async () => {
        setLoading(true)

        const bodyCasas = {
            idagrupador: 2,
            refreshToken: "Bearer " + refreshToken,
        };
        const casasApuestas = await JugadasService.listCasas(bodyCasas);
        //console.log("casasApuestas", casasApuestas);
        if (casasApuestas.status) {
            setBancosList(casasApuestas.data[0]);
        }
        const body = {
            fecha: filtros.f_inicio,
            // tipo: filtros.tipo,
            refreshToken: "Bearer " + refreshToken,
        };
        const resp = await SurebetService.getSureBets(body);
        // console.log("resp surebets", resp);
        if (resp.status) {
            let cont = 0
            for (let index = 0; index < resp.data[0].length; index++) {
                const element = resp.data[0][index];
                element.reactive;
                if (element.estado == 0) {
                    cont = cont + 1
                }
            }
            if (cont >= 3) {
                Swal.fire({
                    title: "Alerta",
                    icon: "info",
                    text: "Ten cuidado al momento de realizar tus operaciones.",
                });
            }
            setItems(resp.data[0]);
        }
        setLoading(false)
    };

    //MOUNTED
    useEffect(() => {
        // getData();
        $("#kt_datepicker_1").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            onChange: function (selectedDates, dateStr, instance) {
                //console.log("sipe aq");
                setFiltros({ ...filtros, f_inicio: moment(dateStr).format("yyyy-MM-DD") });
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
    }, []);

    //METODOS
    const estadoCasa = async (id, estado) => {
        const body = {
            idsurebet: id,
            winner: estado,
            refreshToken: "Bearer " + refreshToken,
        };
        const resp = await JugadasService.changeWinner(body);
        if (resp.status) {
            getData();
            toast.success("Apuesta Actualizada !", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "A",
            });
        } else {
            toast.error("Error al actualizar la apuesta", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "A",
            });
        }
    };
    const estadoFinal = async (id, estado) => {
        let resp = {};
        const body = {
            idsurebet: id,
            estado: estado,
            refreshToken: "Bearer " + refreshToken,
        };
        //console.log("body", body);
        resp = await JugadasService.stateHomeSureBet(body);
        if (resp.status) {
            toast.success("SureBet Actualizada !", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "A",
            });
        } else {
            toast.error("Error al actualizar la surebet", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "A",
            });
        }
        getData();
    };
    //estados middle y antimiddle
    const estadoMiddle = async (id, estado, radio) => {
        let resp = {};
        const body = {
            idsurebet: id,
            winner: estado,
            refreshToken: "Bearer " + refreshToken,
        };
        if (estado == 0 || estado == 3) {
            //console.log("body", body);
            resp = await JugadasService.changeWinner(body);
            //console.log("respp de middle", resp);
            if (resp.status) {
                for (let index = 0; index < items.length; index++) {
                    if (radio == 1) {
                        document.querySelectorAll("[name=radioEstado" + index + "]").forEach((x) => (x.checked = false));
                    } else {
                        document.querySelectorAll("[name=radioEstadoR" + index + "]").forEach((x) => (x.checked = false));
                    }
                }
                toast.success("Apuesta Actualizada !", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "A",
                });
            } else {
                toast.error("Error al actualizar la apuesta", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "A",
                });
            }
        }

        getData();
        //console.log("respp", resp);
    };
    //ELIMINAR
    const handleMyDelete = (data) => {
        Swal.fire({
            icon: "error",
            title: "¿Seguro que desea eliminar la surebet seleccionada?",
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                confirmDelete(data);
            }
        });
    };

    //CONFIRMACIÓN DE ELIMINACIÓN
    const confirmDelete = async (data) => {
        //console.log("daaataaa", data);
        const body = {
            idsurebet: data.id_surebet,
            refreshToken: "Bearer " + refreshToken,
        };
        //console.log("body", body);
        const resp = await JugadasService.deleteSureBet(body);
        //console.log("resp", resp);
        if (resp.status) {
            Swal.fire({
                title: "Eliminado",
                icon: "success",
                text: "La surebet ha sido eliminada con éxito.",
            });
            getData();
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Hubo un error al eliminar la surebet.",
            });
        }
    };
    //EDITAR SUREBET
    const editApuesta = async (data) => {
        //console.log(data);
        setValue("id", data.id_surebet);
        setValue("casa1", data.casa1.toUpperCase());
        setValue("casa2", data.casa2.toUpperCase());
        setValue("mercado1", data.mercado1);
        setValue("mercado2", data.mercado2);
        setValue("cuota1", data.cuota1);
        setValue("cuota2", data.cuota2);
        setValue("stake1", data.stake1);
        setValue("stake2", data.stake2);
        setValue("yield1", data.yield1);
        setValue("yield2", data.yield2);
        setValue("deporte", data.deporte);
        setValue("evento", data.evento);
        setValue("observacion", data.observacion);
        $("#kt_modal_edit_surebet").modal("show");
    };
    const resetForm = () => {
        reset({
            id: null,
            casa1: "",
            casa2: "",
            mercado1: "",
            mercado2: "",
            cuota1: "",
            cuota2: "",
            stake1: "",
            stake2: "",
            yield1: "",
            yield2: "",
            deporte: "",
            observacion: "",
            evento: "",
        });
    };

    //ENVIAR DATA DE MODAL
    const onSubmit = async (data) => {
        //console.log("dataaa", data);
        setLoading3(true)
        const body = {
            casa1: data.casa1,
            casa2: data.casa2,
            mercado1: data.mercado1,
            mercado2: data.mercado2,
            cuota1: data.cuota1,
            cuota2: data.cuota2,
            stake1: data.stake1,
            stake2: data.stake2,
            yield1: data.yield1,
            yield2: data.yield2,
            idsurebet: data.id,
            deporte: data.deporte,
            evento: data.evento,
            observacion: data.observacion != null ? data.observacion : "",
            refreshToken: "Bearer " + refreshToken,
        };
        //console.log("body", body);
        const respEdit = await JugadasService.editSurebet(body);
        //console.log("respEdit", respEdit);
        if (respEdit.status) {
            Swal.fire({
                title: "Actualizado",
                icon: "success",
                text: "Datos actualizados con éxito.",
            });

        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al actualizar la surebet.",
            });
        }
        setLoading3(false)
        getData();
        $("#kt_modal_edit_surebet").modal("hide");
    };
    const getReport = async () => {
        setLoading2(true)
        const body = {
            fecha: filtros.f_inicio,
            tcambio: user.tipocambio,
            refreshToken: "Bearer " + refreshToken,
        };
        const resp = await ReportesService.getGanancias(body)
        if (resp.status) {
            // console.log('resp.data[0]', resp.data[0])
            setItemsReporte(resp.data[0])
            $("#kt_modal_reporte").modal("show");
        }
        setLoading2(false)
        // console.log('resp', resp)
    }
    const toggleAcord = (id) => {
        const acord = document.getElementById('kt_accordion_0_item_' + id)

        if (acord.classList.contains("hide-acord")) {
            acord.classList.remove("hide-acord")
            acord.classList.add("show-acord")
        } else {
            acord.classList.add("hide-acord")
            acord.classList.remove("show-acord")
        }

    }

    return (
        user.frqpoint == 0 ?
            <div className="container-xxl mw-100 px-0">
                <div className="toolbar" id="kt_toolbar">
                    <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                    <div className="overlay-wrapper">
                        <div className="card">
                            <div className="card-header border-0 p-6">
                                <div className="card-toolbar w-100">
                                    <div className="row w-100 ">
                                        <div className="col-12 col-sm-3">
                                            <div className="w-100">
                                                <label htmlFor="" className="form-label">
                                                    Fecha
                                                </label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    placeholder="Fecha Inicio"
                                                    id="kt_datepicker_1"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-3">
                                            <div className=" w-100">
                                                <label htmlFor="casas-estado" className="form-label">
                                                    Tipo
                                                </label>
                                                <Select
                                                    options={tipos.map((cu) => ({
                                                        label: cu.text,
                                                        value: cu.value,
                                                    }))}
                                                    defaultValue={{ label: "Todos", value: "2" }}
                                                    styles={colourStyles}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    onChange={(e) => setFiltros({ ...filtros, tipo: e.value })}
                                                    placeholder={"Seleccionar"}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-3 pt-4 pt-sm-0">
                                            <div className="d-flex align-content-end justify-content-sm-start justify-content-center h-100  w-100 ">
                                                <button type="button" className="btn btn-primary mt-auto" onClick={getData}>
                                                    <i className="bi bi-search" /> Buscar
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-3 pt-4 pt-sm-0">
                                            <div className="d-flex align-content-end justify-content-lg-end justify-content-center h-100  w-100">
                                                <button type="button" className="btn btn-secondary mt-auto" onClick={() => getReport()}>
                                                    <FaChartBar></FaChartBar> {loading2 ? 'Buscando..' : 'Reporte'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-flex flex-column flex-md-row ">
                                    <div className="accordion accordion-icon-toggle w-100" id="kt_accordion_0">
                                        {items.map((bet, index) => {
                                            return (
                                                <div className="card_acordion mb-5 w-100 border-1" key={index}>
                                                    {/* Header del acordion */}
                                                    <div
                                                        className={`accordion-header content py-3 d-flex px-3 border-2 border border-active active ${bet.estado == 1
                                                            ? "bg-light-success  border-success"
                                                            : bet.estado == 3
                                                                ? "bg-light-warning border-warning"
                                                                : bet.estado == 0
                                                                    ? "bg-light-danger border-danger"
                                                                    : ""
                                                            }`}
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={"#kt_accordion_0_item_" + index}
                                                    // onClick={()=>toggleAcord(index)}
                                                    >
                                                        <div className="d-flex gap-2 w-100 flex-wrap">
                                                            <span className="font-bold py-0 text-wrap">{bet.profit}</span> /
                                                            <span className="text-wrap">{bet.deporte}</span> /
                                                            <span className=" text-wrap">{bet.evento}</span> /
                                                            <span className="stext-wrap">
                                                                {moment(bet.fechaevento).format("yyyy-MM-DD")}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        id={"kt_accordion_0_item_" + index}
                                                        className="fs-6 collapse px-1 px-xxl-4 py-2"
                                                        data-bs-parent="#kt_accordion_0"
                                                    >
                                                        <div
                                                            className={`d-flex flex-column w-100 text-center ${bet.estado == 1
                                                                ? "bg-light-success "
                                                                : bet.estado == 3
                                                                    ? "bg-light-warning"
                                                                    : bet.estado == 0
                                                                        ? "bg-light-danger"
                                                                        : ""
                                                                }`}
                                                        >
                                                            {/* DATOS RESPONSIVE - Acordiones internos*/}
                                                            <div className="d-block d-lg-none accordion" id={"kt_accordion_" + (index + 1)}>
                                                                <div className="accordion-item">
                                                                    <h2
                                                                        className="accordion-header"
                                                                        id={"kt_accordion_" + (index + 1) + "_header_" + (index + 1)}
                                                                    >
                                                                        <button
                                                                            className="accordion-button fs-4 fw-semibold collapsed"
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={"#kt_accordion_" + (index + 1) + "_body_" + (index + 1)}
                                                                            aria-expanded="false"
                                                                            aria-controls={"kt_accordion_" + (index + 1) + "_body_" + (index + 1)}
                                                                        >
                                                                            Datos Primera Apuesta
                                                                        </button>
                                                                    </h2>
                                                                    <div
                                                                        id={"kt_accordion_" + (index + 1) + "_body_" + (index + 1)}
                                                                        className="accordion-collapse collapse"
                                                                        aria-labelledby={"kt_accordion_" + (index + 1) + "_header_" + (index + 1)}
                                                                        data-bs-parent={"#kt_accordion_" + (index + 1)}
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <div
                                                                                className={`row justify-content-md-center ${bet.ganador == 1 || bet.ganador == 3
                                                                                    ? "bg-light-success"
                                                                                    : bet.ganador == 0
                                                                                        ? "bg-light-danger"
                                                                                        : ""
                                                                                    }`}
                                                                            >
                                                                                <div className="col-6 col-xl-4 col-xxl-2 p-0 d-flex flex-column border">
                                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                        Casa
                                                                                    </div>
                                                                                    <div className="column_content p-4">{bet.casa1}</div>
                                                                                </div>
                                                                                <div className="col-6  col-xl-4 col-xxl-3 p-0 d-flex flex-column border">
                                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                        Estado
                                                                                    </div>
                                                                                    <div className="column_content p-4 d-flex justify-content-evenly">
                                                                                        <div className="form-check form-check-custom form-check-success form-check-solid">
                                                                                            {bet.estado == 2 ? (
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="radio"
                                                                                                    value={1}
                                                                                                    defaultChecked={bet.ganador == 1}
                                                                                                    onClick={() => estadoCasa(bet.id_surebet, 1)}
                                                                                                    id="flexCheckboxLgER"
                                                                                                    name={"radioEstadoR" + index}
                                                                                                />
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                            {bet.estado != 2 ? (
                                                                                                bet.ganador == 1 || bet.ganador == 3 ? (
                                                                                                    <label
                                                                                                        className="form-check-label"
                                                                                                        htmlFor={"flexCheckboxLgER"}
                                                                                                    >
                                                                                                        Ganado
                                                                                                    </label>
                                                                                                ) : (
                                                                                                    <label
                                                                                                        className="form-check-label"
                                                                                                        htmlFor={"flexCheckboxLgER"}
                                                                                                    >
                                                                                                        Perdido
                                                                                                    </label>
                                                                                                )
                                                                                            ) : bet.ganador == 0 ? (
                                                                                                <label
                                                                                                    className="form-check-label"
                                                                                                    htmlFor={"flexCheckboxLgER"}
                                                                                                >
                                                                                                    Perdedor
                                                                                                </label>
                                                                                            ) : (
                                                                                                <label
                                                                                                    className="form-check-label"
                                                                                                    htmlFor={"flexCheckboxLgER"}
                                                                                                >
                                                                                                    Ganador
                                                                                                </label>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6  col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-primary ">
                                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                        Apuesta
                                                                                    </div>
                                                                                    <div className="column_content p-4">{bet.mercado1}</div>
                                                                                </div>
                                                                                <div className="col-6   col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                        Cuotas
                                                                                    </div>
                                                                                    <div className="column_content p-4">{bet.cuota1}</div>
                                                                                </div>
                                                                                <div className="col-6  col-xl-4 col-xxl-1 p-0 d-flex flex-column border border-active-info">
                                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                        Stake
                                                                                    </div>
                                                                                    <div className="column_content p-4">{bet.stake1}</div>
                                                                                </div>
                                                                                <div className="col-6 col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                        Ganancia
                                                                                    </div>
                                                                                    <div className="column_content p-4">{bet.yield1}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="accordion-item">
                                                                    <h2
                                                                        className="accordion-header"
                                                                        id={"kt_accordion_" + (index + 1) + "_header_" + (index + 2)}
                                                                    >
                                                                        <button
                                                                            className="accordion-button fs-4 fw-semibold collapsed"
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={"#kt_accordion_" + (index + 1) + "_body_" + (index + 2)}
                                                                            aria-expanded="false"
                                                                            aria-controls={"kt_accordion_" + (index + 1) + "_body_" + (index + 2)}
                                                                        >
                                                                            Datos Segunda Apuesta
                                                                        </button>
                                                                    </h2>
                                                                    <div
                                                                        id={"kt_accordion_" + (index + 1) + "_body_" + (index + 2)}
                                                                        className="accordion-collapse collapse"
                                                                        aria-labelledby={"kt_accordion_" + (index + 1) + "_header_" + (index + 2)}
                                                                        data-bs-parent={"#kt_accordion_" + (index + 1)}
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <div
                                                                                className={`row justify-content-md-center ${bet.ganador == 2 || bet.ganador == 3
                                                                                    ? "bg-light-success"
                                                                                    : bet.ganador == 0
                                                                                        ? "bg-light-danger"
                                                                                        : ""
                                                                                    }`}
                                                                            >
                                                                                <div className="col-6 col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                        Casa
                                                                                    </div>
                                                                                    <div className="column_content p-4">{bet.casa2}</div>
                                                                                </div>
                                                                                <div className="col-6  col-xl-4 col-xxl-3 p-0 d-flex flex-column border border-active-info">
                                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                        Estado
                                                                                    </div>
                                                                                    <div className="column_content p-4 d-flex justify-content-evenly">
                                                                                        <div className="form-check form-check-custom form-check-success form-check-solid">
                                                                                            {bet.estado == 2 ? (
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="radio"
                                                                                                    value={1}
                                                                                                    defaultChecked={bet.ganador == 2}
                                                                                                    onClick={() => estadoCasa(bet.id_surebet, 2)}
                                                                                                    id="flexCheckboxLgR"
                                                                                                    name={"radioEstadoR" + index}
                                                                                                />
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                            {bet.estado != 2 ? (
                                                                                                bet.ganador == 2 || bet.ganador == 3 ? (
                                                                                                    <label
                                                                                                        className="form-check-label"
                                                                                                        htmlFor={"flexCheckboxLg"}
                                                                                                    >
                                                                                                        Ganado
                                                                                                    </label>
                                                                                                ) : (
                                                                                                    <label
                                                                                                        className="form-check-label"
                                                                                                        htmlFor={"flexCheckboxLg"}
                                                                                                    >
                                                                                                        Perdido
                                                                                                    </label>
                                                                                                )
                                                                                            ) : bet.ganador == 0 ? (
                                                                                                <label
                                                                                                    className="form-check-label"
                                                                                                    htmlFor={"flexCheckboxLg"}
                                                                                                >
                                                                                                    Perdedor
                                                                                                </label>
                                                                                            ) : (
                                                                                                <label
                                                                                                    className="form-check-label"
                                                                                                    htmlFor={"flexCheckboxLg"}
                                                                                                >
                                                                                                    Ganador
                                                                                                </label>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6  col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-light">
                                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                        Apuesta
                                                                                    </div>
                                                                                    <div className="column_content p-4">{bet.mercado2}</div>
                                                                                </div>
                                                                                <div className="col-6   col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                        Cuotas
                                                                                    </div>
                                                                                    <div className="column_content p-4">{bet.cuota2}</div>
                                                                                </div>
                                                                                <div className="col-6  col-xl-4 col-xxl-1 p-0 d-flex flex-column border border-active-info">
                                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                        Stake
                                                                                    </div>
                                                                                    <div className="column_content p-4">{bet.stake2}</div>
                                                                                </div>
                                                                                <div className="col-6 col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                        Ganancia
                                                                                    </div>
                                                                                    <div className="column_content p-4">{bet.yield2}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="accordion-item">
                                                                    <h2
                                                                        className="accordion-header"
                                                                        id={"kt_accordion_" + (index + 1) + "_header_" + (index + 3)}
                                                                    >
                                                                        <button
                                                                            className="accordion-button fs-4 fw-semibold collapsed"
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={"#kt_accordion_" + (index + 1) + "_body_" + (index + 3)}
                                                                            aria-expanded="false"
                                                                            aria-controls={"kt_accordion_" + (index + 1) + "_body_" + (index + 3)}
                                                                        >
                                                                            Acciones
                                                                        </button>
                                                                    </h2>
                                                                    <div
                                                                        id={"kt_accordion_" + (index + 1) + "_body_" + (index + 3)}
                                                                        className="accordion-collapse collapse"
                                                                        aria-labelledby={"kt_accordion_" + (index + 1) + "_header_" + (index + 3)}
                                                                        data-bs-parent={"#kt_accordion_" + (index + 1)}
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <div className="row justify-content-md-center">
                                                                                <div className="col-6 col-xl-3 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                        Acciones
                                                                                    </div>
                                                                                    <div className="column_content p-4 d-flex gap-2 justify-content-center align-items-center h-100">
                                                                                        <OverlayTrigger placement="top" overlay={tooltipEd}>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-secondary btn-sm"
                                                                                                data-bs-toggle="tooltip"
                                                                                                data-bs-placement="top"
                                                                                                title="Editar"
                                                                                                onClick={() => editApuesta(bet)}
                                                                                            >
                                                                                                <FaRegEdit />
                                                                                            </button>
                                                                                        </OverlayTrigger>
                                                                                        <OverlayTrigger placement="top" overlay={tooltipE}>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-secondary btn-sm"
                                                                                                data-bs-toggle="tooltip"
                                                                                                data-bs-placement="top"
                                                                                                title="Eliminar"
                                                                                                onClick={() => handleMyDelete(bet)}
                                                                                            >
                                                                                                <FaTrash />
                                                                                            </button>
                                                                                        </OverlayTrigger>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6 col-xl-3 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                        Win/Lose
                                                                                    </div>
                                                                                    <div className="column_content p-4 d-flex gap-2 justify-content-center align-items-center h-100">
                                                                                        <OverlayTrigger placement="top" overlay={tooltipMid}>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-primary btn-sm"
                                                                                                data-bs-toggle="tooltip"
                                                                                                data-bs-placement="top"
                                                                                                title="Middle"
                                                                                                onClick={() => estadoMiddle(bet.id_surebet, 3, 2)}
                                                                                            >
                                                                                                <FaRegThumbsUp />
                                                                                            </button>
                                                                                        </OverlayTrigger>
                                                                                        <OverlayTrigger placement="top" overlay={tooltipAMid}>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-danger btn-sm"
                                                                                                data-bs-toggle="tooltip"
                                                                                                data-bs-placement="top"
                                                                                                title="Antimiddle"
                                                                                                onClick={() => estadoMiddle(bet.id_surebet, 0, 2)}
                                                                                            >
                                                                                                <FaRegThumbsDown />
                                                                                            </button>
                                                                                        </OverlayTrigger>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6 col-xl-3 col-xxl-4 p-0 d-flex flex-column border border-active-info">
                                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                        Total
                                                                                    </div>
                                                                                    <div className="column_content p-4 d-flex justify-content-center align-items-center">
                                                                                        {bet.total}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6 col-xl-3 col-xxl-4 p-0 d-flex flex-column border border-active-info">
                                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                        Estados
                                                                                    </div>
                                                                                    <div className="column_content p-4 gap-5 d-flex flex-row justify-content-center justify-content-xxl-evenly">
                                                                                        {bet.estado != 2 ? (
                                                                                            <div className="d-flex justify-content-center">
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={tooltipRes}
                                                                                                >
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-primary btn-sm"
                                                                                                        data-bs-toggle="tooltip"
                                                                                                        data-bs-placement="top"
                                                                                                        title="Restablecer"
                                                                                                        onClick={() =>
                                                                                                            estadoFinal(bet.id_surebet, 2)
                                                                                                        }
                                                                                                    >
                                                                                                        <FaHistory />
                                                                                                    </button>
                                                                                                </OverlayTrigger>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <>
                                                                                                <div className="d-flex gap-2 flex-wrap justify-content-center">
                                                                                                    <OverlayTrigger
                                                                                                        placement="top"
                                                                                                        overlay={tooltipG}
                                                                                                    >
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn btn-success btn-sm"
                                                                                                            data-bs-toggle="tooltip"
                                                                                                            data-bs-placement="top"
                                                                                                            title="Ganado"
                                                                                                            onClick={() =>
                                                                                                                estadoFinal(bet.id_surebet, 1)
                                                                                                            }
                                                                                                        >
                                                                                                            <FaDonate />
                                                                                                        </button>
                                                                                                    </OverlayTrigger>
                                                                                                    <OverlayTrigger
                                                                                                        placement="top"
                                                                                                        overlay={tooltipD}
                                                                                                    >
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn btn-warning btn-sm"
                                                                                                            data-bs-toggle="tooltip"
                                                                                                            data-bs-placement="top"
                                                                                                            title="Devuelto"
                                                                                                            onClick={() =>
                                                                                                                estadoFinal(bet.id_surebet, 3)
                                                                                                            }
                                                                                                        >
                                                                                                            <FaUndo />
                                                                                                        </button>
                                                                                                    </OverlayTrigger>
                                                                                                    <OverlayTrigger
                                                                                                        placement="top"
                                                                                                        overlay={tooltipP}
                                                                                                    >
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn btn-danger btn-sm"
                                                                                                            data-bs-toggle="tooltip"
                                                                                                            data-bs-placement="top"
                                                                                                            title="Perdido"
                                                                                                            onClick={() =>
                                                                                                                estadoFinal(bet.id_surebet, 0)
                                                                                                            }
                                                                                                        >
                                                                                                            <FaBan />
                                                                                                        </button>
                                                                                                    </OverlayTrigger>
                                                                                                </div>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 p-0 d-flex flex-column border border-active-info">
                                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                        Observación
                                                                                    </div>
                                                                                    <div className="column_content p-4">{bet.observacion}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* DATOS RESPONSIVE EN XXL */}
                                                            {/* datos primera apuesta */}
                                                            <div
                                                                className={`d-none d-lg-flex row justify-content-md-center mx-0 ${bet.ganador == 1 || bet.ganador == 3
                                                                    ? "bg-light-success"
                                                                    : bet.ganador == 0
                                                                        ? "bg-light-danger"
                                                                        : ""
                                                                    }`}
                                                            >
                                                                <div className="col-6 col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">Casa</div>
                                                                    <div className="column_content p-4">{bet.casa1}</div>
                                                                </div>
                                                                <div className="col-6  col-md-4 col-lg-3 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">Estado</div>
                                                                    <div className="column_content p-4 d-flex justify-content-evenly">
                                                                        <div className="form-check form-check-custom form-check-success form-check-solid">
                                                                            {bet.estado == 2 ? (
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    value={1}
                                                                                    defaultChecked={bet.ganador == 1}
                                                                                    onClick={() => estadoCasa(bet.id_surebet, 1)}
                                                                                    id={"flexCheckboxLg" + index}
                                                                                    name={"radioEstado" + index}
                                                                                />
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                            {bet.estado != 2 ? (
                                                                                bet.ganador == 1 || bet.ganador == 3 ? (
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor={"flexCheckboxLg" + index}
                                                                                    >
                                                                                        Ganado
                                                                                    </label>
                                                                                ) : (
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor={"flexCheckboxLg" + index}
                                                                                    >
                                                                                        Perdido
                                                                                    </label>
                                                                                )
                                                                            ) : bet.ganador == 0 ? (
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor={"flexCheckboxLg" + index}
                                                                                >
                                                                                    Perdedor
                                                                                </label>
                                                                            ) : (
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor={"flexCheckboxLg" + index}
                                                                                >
                                                                                    Ganador
                                                                                </label>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6  col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">Apuesta</div>
                                                                    <div className="column_content p-4">{bet.mercado1}</div>
                                                                </div>
                                                                <div className="col-6   col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">Cuotas</div>
                                                                    <div className="column_content p-4">{bet.cuota1}</div>
                                                                </div>
                                                                <div className="col-6  col-md-4 col-lg-1 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">Stake</div>
                                                                    <div className="column_content p-4">{bet.stake1}</div>
                                                                </div>
                                                                <div className="col-6 col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">Ganancia</div>
                                                                    <div className="column_content p-4">{bet.yield1}</div>
                                                                </div>
                                                            </div>
                                                            {/* datos primera apuesta */}

                                                            {/* datos segunda apuesta */}
                                                            <div
                                                                className={`d-none d-lg-flex row justify-content-md-center mx-0  ${bet.ganador == 2 || bet.ganador == 3
                                                                    ? "bg-light-success"
                                                                    : bet.ganador == 0
                                                                        ? "bg-light-danger"
                                                                        : ""
                                                                    }`}
                                                            >
                                                                <div className="col-6 col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_content p-4">{bet.casa2}</div>
                                                                </div>
                                                                <div className="col-6  col-md-4 col-lg-3 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_content p-4 d-flex justify-content-evenly">
                                                                        <div className="form-check form-check-custom form-check-success form-check-solid">
                                                                            {bet.estado == 2 ? (
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    value={2}
                                                                                    defaultChecked={bet.ganador == 2}
                                                                                    onClick={() => estadoCasa(bet.id_surebet, 2)}
                                                                                    id={"flexCheckboxLg" + (index + 1)}
                                                                                    name={"radioEstado" + index}
                                                                                />
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                            {bet.estado != 2 ? (
                                                                                bet.ganador == 2 || bet.ganador == 3 ? (
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor={"flexCheckboxLg" + index}
                                                                                    >
                                                                                        Ganado
                                                                                    </label>
                                                                                ) : (
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor={"flexCheckboxLg" + index}
                                                                                    >
                                                                                        Perdido
                                                                                    </label>
                                                                                )
                                                                            ) : bet.ganador == 0 ? (
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor={"flexCheckboxLg" + index}
                                                                                >
                                                                                    Perdedor
                                                                                </label>
                                                                            ) : (
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor={"flexCheckboxLg" + index}
                                                                                >
                                                                                    Ganador
                                                                                </label>
                                                                            )}
                                                                            {/* {reactivar && (bet.ganador !== 2 || bet.ganador !== 3) ? (
                                                                    <></>
                                                                ) : (
                                                                    <label className="form-check-label" htmlFor={"flexCheckboxLg" + (index + 1)}>
                                                                        Ganador
                                                                    </label>
                                                                )} */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6  col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_content p-4">{bet.mercado2}</div>
                                                                </div>
                                                                <div className="col-6   col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_content p-4">{bet.cuota2}</div>
                                                                </div>
                                                                <div className="col-6  col-md-4 col-lg-1 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_content p-4">{bet.stake2}</div>
                                                                </div>
                                                                <div className=" col-6 col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_content p-4">{bet.yield2}</div>
                                                                </div>
                                                            </div>
                                                            {/* datos segunda apuesta */}

                                                            {/* acciones */}
                                                            <div className={`d-none d-lg-flex mx-0 row justify-content-md-center`}>
                                                                <div className="col-5 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">Observación</div>
                                                                    <div className="column_content p-4">{bet.observacion}</div>
                                                                </div>
                                                                <div className="col-6 col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">Acciones</div>
                                                                    <div className="column_content p-4 d-flex gap-2 justify-content-center align-items-center h-100">
                                                                        <OverlayTrigger placement="top" overlay={tooltipEd}>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-secondary btn-sm"
                                                                                data-bs-toggle="tooltip"
                                                                                data-bs-placement="top"
                                                                                title="Editar"
                                                                                onClick={() => editApuesta(bet)}
                                                                            >
                                                                                <FaRegEdit />
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                        <OverlayTrigger placement="top" overlay={tooltipE}>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-secondary btn-sm"
                                                                                data-bs-toggle="tooltip"
                                                                                data-bs-placement="top"
                                                                                title="Eliminar"
                                                                                onClick={() => handleMyDelete(bet)}
                                                                            >
                                                                                <FaTrash />
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">Win/Lose</div>
                                                                    <div className="column_content p-4 d-flex gap-2 justify-content-center align-items-center h-100">
                                                                        <OverlayTrigger placement="top" overlay={tooltipMid}>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-secondary btn-sm"
                                                                                data-bs-toggle="tooltip"
                                                                                data-bs-placement="top"
                                                                                title="Middle"
                                                                                onClick={() => estadoMiddle(bet.id_surebet, 3, 1)}
                                                                            >
                                                                                <FaRegThumbsUp />
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                        <OverlayTrigger placement="top" overlay={tooltipAMid}>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-secondary btn-sm"
                                                                                data-bs-toggle="tooltip"
                                                                                data-bs-placement="top"
                                                                                title="Antimiddle"
                                                                                onClick={() => estadoMiddle(bet.id_surebet, 0, 1)}
                                                                            >
                                                                                <FaRegThumbsDown />
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 col-md-4 col-lg-1 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">Total</div>
                                                                    <div className="column_content p-4 d-flex justify-content-center align-items-center">
                                                                        {bet.total}
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">Estados</div>
                                                                    <div className="column_content p-4 gap-5 d-flex flex-column flex-xl-row justify-content-center justify-content-xxl-evenly">
                                                                        {bet.estado != 2 ? (
                                                                            <div className="d-flex justify-content-center">
                                                                                <OverlayTrigger placement="top" overlay={tooltipRes}>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-primary btn-sm"
                                                                                        data-bs-toggle="tooltip"
                                                                                        data-bs-placement="top"
                                                                                        title="Restablecer"
                                                                                        onClick={() => estadoFinal(bet.id_surebet, 2)}
                                                                                    >
                                                                                        <FaHistory />
                                                                                    </button>
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                <div className="d-flex gap-2 flex-wrap justify-content-center">
                                                                                    <OverlayTrigger placement="top" overlay={tooltipG}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-primary btn-sm"
                                                                                            data-bs-toggle="tooltip"
                                                                                            data-bs-placement="top"
                                                                                            title="Ganado"
                                                                                            onClick={() => estadoFinal(bet.id_surebet, 1)}
                                                                                        >
                                                                                            <FaDonate />
                                                                                        </button>
                                                                                    </OverlayTrigger>
                                                                                    <OverlayTrigger placement="top" overlay={tooltipD}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-warning btn-sm"
                                                                                            data-bs-toggle="tooltip"
                                                                                            data-bs-placement="top"
                                                                                            title="Devuelto"
                                                                                            onClick={() => estadoFinal(bet.id_surebet, 3)}
                                                                                        >
                                                                                            <FaUndo />
                                                                                        </button>
                                                                                    </OverlayTrigger>
                                                                                    <OverlayTrigger placement="top" overlay={tooltipP}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-danger btn-sm"
                                                                                            data-bs-toggle="tooltip"
                                                                                            data-bs-placement="top"
                                                                                            title="Perdido"
                                                                                            onClick={() => estadoFinal(bet.id_surebet, 0)}
                                                                                        >
                                                                                            <FaBan />
                                                                                        </button>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* toast */}
                        {/* <div id="kt_docs_toast_stack_container" className="toast-container position-fixed bottom-0 end-0 py-3 px-6 z-index-3">
                    <Toast position="top-start" onClose={() => setShow(false)} show={show} delay={4500} autohide className={"toast border-0 " + "bg-" + alertColor}>
                        <Toast.Body className="text-white toast_text">
                            {alertColor == "danger" ? <FaExclamationCircle></FaExclamationCircle> : <FaCheckCircle></FaCheckCircle>}
                            <span className="ms-2">{alertMsg}</span>
                        </Toast.Body>
                    </Toast>
                </div> */}
                        <ToastContainer
                            theme={localStorage.getItem("userTheme") === "dark" ? "dark" : "light"}
                            className="container_surebets "
                            enableMultiContainer
                            containerId={"A"}
                            position={toast.POSITION.BOTTOM_RIGHT}
                        />

                        {/* modal */}
                        <div className="modal fade" id="kt_modal_edit_surebet" tabIndex="-1" aria-modal="true" role="dialog">
                            <div className="modal-dialog modal-dialog-centered mw-650px">
                                <div className="modal-content">
                                    <form
                                        className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                        id="kt_modal_add_customer_form"
                                        onSubmit={handleSubmit(onSubmit)}
                                    >
                                        <div className="modal-header" id="kt_modal_add_customer_header">
                                            <h2 className="fw-bold">Editar Surebet</h2>
                                            <div
                                                id="kt_modal_add_customer_close"
                                                className="btn btn-icon btn-sm btn-active-icon-primary"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm}
                                            >
                                                <span className="svg-icon svg-icon-1">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect
                                                            opacity="0.5"
                                                            x="6"
                                                            y="17.3137"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(-45 6 17.3137)"
                                                            fill="currentColor"
                                                        ></rect>
                                                        <rect
                                                            x="7.41422"
                                                            y="6"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(45 7.41422 6)"
                                                            fill="currentColor"
                                                        ></rect>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-body py-10 px-lg-17">
                                            <div
                                                className="scroll-y me-n7 pe-7"
                                                id="kt_modal_add_customer_scroll"
                                                data-kt-scroll="true"
                                                data-kt-scroll-activate="{default: false, lg: true}"
                                                data-kt-scroll-max-height="auto"
                                                data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                                                data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                                                data-kt-scroll-offset="300px"
                                                style={{ maxHeight: "1035px" }}
                                            >
                                                <div className="row mx-0">
                                                    <div className="col-12 col-lg-6">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Casa 1</span>
                                                        </label>
                                                        <select
                                                            className="select2-selection select2-selection--single form-select form-select-solid"
                                                            name="casa1"
                                                            {...register("casa1")}
                                                        >
                                                            {/* <option value="">Seleccionar</option> */}
                                                            {bancosList.map((estado) => (
                                                                <option key={estado.id_constante} value={estado.nombre_constante}>
                                                                    {estado.nombre}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Casa 2</span>
                                                        </label>
                                                        <select
                                                            className="select2-selection select2-selection--single form-select form-select-solid"
                                                            name="casa2"
                                                            {...register("casa2")}
                                                        >
                                                            {/* <option value="">Seleccionar</option> */}
                                                            {bancosList.map((estado) => (
                                                                <option key={estado.id_constante} value={estado.nombre_constante}>
                                                                    {estado.nombre}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Mercado 1</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Mercado 1"
                                                            name="mercado1"
                                                            {...register("mercado1")}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Mercado 2</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Mercado 2"
                                                            name="mercado2"
                                                            {...register("mercado2")}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Cuota 1</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Cuota 1"
                                                            name="cuota1"
                                                            {...register("cuota1")}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Cuota 2</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Cuota 2"
                                                            name="cuota2"
                                                            {...register("cuota2")}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Stake 1</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Stake 1"
                                                            name="stake1"
                                                            {...register("stake1")}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Stake 2</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Stake 2"
                                                            name="stake2"
                                                            {...register("stake2")}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Yield 1</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Yield 1"
                                                            name="yield1"
                                                            {...register("yield1")}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Yield 2</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Yield 2"
                                                            name="yield2"
                                                            {...register("yield2")}
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Deporte</span>
                                                        </label>
                                                        <textarea
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Deporte"
                                                            name="deporte"
                                                            data-kt-autosize="true"
                                                            {...register("deporte")}
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Evento</span>
                                                        </label>
                                                        <textarea
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Evento"
                                                            name="evento"
                                                            data-kt-autosize="true"
                                                            {...register("evento")}
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Observación</span>
                                                        </label>
                                                        <textarea
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Observación"
                                                            name="observacion"
                                                            data-kt-autosize="true"
                                                            {...register("observacion")}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="modal-footer flex-center">
                                            <button
                                                type="reset"
                                                id="kt_modal_add_customer_cancel"
                                                className="btn btn-danger me-3"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm}
                                            >
                                                Cerrar
                                            </button>
                                            <button type="submit" id="kt_modal_add_customer_submit" className="btn btn-primary">
                                                {loading3 ? (
                                                    <span className="indicator-label">
                                                        Actualizando....
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                ) : (
                                                    <span className="indicator-label">Editar</span>
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="kt_modal_reporte" tabIndex="-1" aria-modal="true" role="dialog">
                            <div className="modal-dialog modal-dialog-centered mw-650px">
                                <div className="modal-content">
                                    <form className="form fv-plugins-bootstrap5 fv-plugins-framework" id="kt_modal_reporte_form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="modal-header" id="kt_modal_reporte_header">
                                            <h2 className="fw-bold">Reporte Diario</h2>
                                            <div
                                                id="kt_modal_reporte_close"
                                                className="btn btn-icon btn-sm btn-active-icon-primary"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm}
                                            >
                                                <span className="svg-icon svg-icon-1">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect
                                                            opacity="0.5"
                                                            x="6"
                                                            y="17.3137"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(-45 6 17.3137)"
                                                            fill="currentColor"
                                                        ></rect>
                                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-body p-0">
                                            <div
                                                className="scroll-y me-n7 pe-7"
                                                id="kt_modal_reporte_scroll"
                                                data-kt-scroll="true"
                                                data-kt-scroll-activate="{default: false, lg: true}"
                                                data-kt-scroll-max-height="auto"
                                                data-kt-scroll-dependencies="#kt_modal_reporte_header"
                                                data-kt-scroll-wrappers="#kt_modal_reporte_scroll"
                                                data-kt-scroll-offset="300px"
                                                style={{ maxHeight: "1035px" }}
                                            >
                                                <div className="row mx-0 p-2 border-2 border-bottom">
                                                    <div className="col-3 text-center text-center">
                                                        <span className="font-bold">Producción</span>
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        <span className="font-bold">Movido</span>
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        <span className="font-bold">Pendiente</span>
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        <span className="font-bold">Rentabilidad</span>
                                                    </div>
                                                </div>
                                                <div className="row d-flex mx-0 p-2">
                                                    <div className="col-3 text-center">
                                                        <span className="">{itemsReporte.ganancia != null ? parseFloat(itemsReporte.ganancia).toFixed(2) : (0).toFixed(2)}</span>
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        <span className="">{itemsReporte.movido != null ? parseFloat(itemsReporte.movido).toFixed(2) : (0).toFixed(2)}</span>
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        <span className="">{itemsReporte.pendiente != null ? parseFloat(itemsReporte.pendiente).toFixed(2) : (0).toFixed(2)}</span>
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        <span className="">{itemsReporte.yield != null && itemsReporte.yield != "NaN" ? itemsReporte.yield : 0} %</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer flex-center">
                                            <button
                                                type="reset"
                                                id="kt_modal_reporte_cancel"
                                                className="btn btn-danger me-3"
                                                data-bs-dismiss="modal"
                                            >
                                                Cerrar
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="overlay-layer card-rounded bg-black bg-opacity-50">
                            <div className="d-flex flex-column align-items-center mt-10">
                                <div className="spinner-border text-white" role="status"></div>
                                <span>Buscando datos...</span>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            :
            <Main>
                <div className="container-xxl mw-100">
                    <div className="toolbar" id="kt_toolbar">
                        <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                            <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                        </div>
                    </div>
                    <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                        <div className="overlay-wrapper">
                            <div className="card">
                                <div className="card-header border-0 p-6">
                                    <div className="card-toolbar w-100">
                                        <div className="row w-100 ">
                                            <div className="col-12 col-sm-3">
                                                <div className="w-100">
                                                    <label htmlFor="" className="form-label">
                                                        Fecha
                                                    </label>
                                                    <input
                                                        className="form-control form-control-solid"
                                                        placeholder="Fecha Inicio"
                                                        id="kt_datepicker_1"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-3">
                                                <div className=" w-100">
                                                    <label htmlFor="casas-estado" className="form-label">
                                                        Tipo
                                                    </label>
                                                    <Select
                                                        options={tipos.map((cu) => ({
                                                            label: cu.text,
                                                            value: cu.value,
                                                        }))}
                                                        defaultValue={{ label: "Todos", value: "2" }}
                                                        styles={colourStyles}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        onChange={(e) => setFiltros({ ...filtros, tipo: e.value })}
                                                        placeholder={"Seleccionar"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-3 pt-4 pt-sm-0">
                                                <div className="d-flex align-content-end justify-content-sm-start justify-content-center h-100  w-100 ">
                                                    <button type="button" className="btn btn-primary mt-auto" onClick={getData}>
                                                        <i className="bi bi-search" /> Buscar
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-3 pt-4 pt-sm-0">
                                                <div className="d-flex align-content-end justify-content-lg-end justify-content-center h-100  w-100">
                                                    <button type="button" className="btn btn-secondary mt-auto" onClick={() => getReport()}>
                                                        <FaChartBar></FaChartBar> {loading2 ? 'Buscando..' : 'Reporte'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="d-flex flex-column flex-md-row ">
                                        <div className="accordion accordion-icon-toggle w-100" id="kt_accordion_0">
                                            {items.map((bet, index) => {
                                                return (
                                                    <div className="card_acordion mb-5 w-100 border-1" key={index}>
                                                        {/* Header del acordion */}
                                                        <div
                                                            className={`accordion-header content py-3 d-flex px-3 border-2 border border-active active ${bet.estado == 1
                                                                ? "bg-light-success  border-success"
                                                                : bet.estado == 3
                                                                    ? "bg-light-warning border-warning"
                                                                    : bet.estado == 0
                                                                        ? "bg-light-danger border-danger"
                                                                        : ""
                                                                }`}
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={"#kt_accordion_0_item_" + index}
                                                        // onClick={()=>toggleAcord(index)}
                                                        >
                                                            <div className="d-flex gap-2 w-100 flex-wrap">
                                                                <span className="font-bold py-0 text-wrap">{bet.profit}</span> /
                                                                <span className="text-wrap">{bet.deporte}</span> /
                                                                <span className=" text-wrap">{bet.evento}</span> /
                                                                <span className="stext-wrap">
                                                                    {moment(bet.fechaevento).format("yyyy-MM-DD")}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            id={"kt_accordion_0_item_" + index}
                                                            className="fs-6 collapse px-1 px-xxl-4 py-2"
                                                            data-bs-parent="#kt_accordion_0"
                                                        >
                                                            <div
                                                                className={`d-flex flex-column w-100 text-center ${bet.estado == 1
                                                                    ? "bg-light-success "
                                                                    : bet.estado == 3
                                                                        ? "bg-light-warning"
                                                                        : bet.estado == 0
                                                                            ? "bg-light-danger"
                                                                            : ""
                                                                    }`}
                                                            >
                                                                {/* DATOS RESPONSIVE - Acordiones internos*/}
                                                                <div className="d-block d-lg-none accordion" id={"kt_accordion_" + (index + 1)}>
                                                                    <div className="accordion-item">
                                                                        <h2
                                                                            className="accordion-header"
                                                                            id={"kt_accordion_" + (index + 1) + "_header_" + (index + 1)}
                                                                        >
                                                                            <button
                                                                                className="accordion-button fs-4 fw-semibold collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={"#kt_accordion_" + (index + 1) + "_body_" + (index + 1)}
                                                                                aria-expanded="false"
                                                                                aria-controls={"kt_accordion_" + (index + 1) + "_body_" + (index + 1)}
                                                                            >
                                                                                Datos Primera Apuesta
                                                                            </button>
                                                                        </h2>
                                                                        <div
                                                                            id={"kt_accordion_" + (index + 1) + "_body_" + (index + 1)}
                                                                            className="accordion-collapse collapse"
                                                                            aria-labelledby={"kt_accordion_" + (index + 1) + "_header_" + (index + 1)}
                                                                            data-bs-parent={"#kt_accordion_" + (index + 1)}
                                                                        >
                                                                            <div className="accordion-body">
                                                                                <div
                                                                                    className={`row justify-content-md-center ${bet.ganador == 1 || bet.ganador == 3
                                                                                        ? "bg-light-success"
                                                                                        : bet.ganador == 0
                                                                                            ? "bg-light-danger"
                                                                                            : ""
                                                                                        }`}
                                                                                >
                                                                                    <div className="col-6 col-xl-4 col-xxl-2 p-0 d-flex flex-column border">
                                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                            Casa
                                                                                        </div>
                                                                                        <div className="column_content p-4">{bet.casa1}</div>
                                                                                    </div>
                                                                                    <div className="col-6  col-xl-4 col-xxl-3 p-0 d-flex flex-column border">
                                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                            Estado
                                                                                        </div>
                                                                                        <div className="column_content p-4 d-flex justify-content-evenly">
                                                                                            <div className="form-check form-check-custom form-check-success form-check-solid">
                                                                                                {bet.estado == 2 ? (
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        type="radio"
                                                                                                        value={1}
                                                                                                        defaultChecked={bet.ganador == 1}
                                                                                                        onClick={() => estadoCasa(bet.id_surebet, 1)}
                                                                                                        id="flexCheckboxLgER"
                                                                                                        name={"radioEstadoR" + index}
                                                                                                    />
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                                {bet.estado != 2 ? (
                                                                                                    bet.ganador == 1 || bet.ganador == 3 ? (
                                                                                                        <label
                                                                                                            className="form-check-label"
                                                                                                            htmlFor={"flexCheckboxLgER"}
                                                                                                        >
                                                                                                            Ganado
                                                                                                        </label>
                                                                                                    ) : (
                                                                                                        <label
                                                                                                            className="form-check-label"
                                                                                                            htmlFor={"flexCheckboxLgER"}
                                                                                                        >
                                                                                                            Perdido
                                                                                                        </label>
                                                                                                    )
                                                                                                ) : bet.ganador == 0 ? (
                                                                                                    <label
                                                                                                        className="form-check-label"
                                                                                                        htmlFor={"flexCheckboxLgER"}
                                                                                                    >
                                                                                                        Perdedor
                                                                                                    </label>
                                                                                                ) : (
                                                                                                    <label
                                                                                                        className="form-check-label"
                                                                                                        htmlFor={"flexCheckboxLgER"}
                                                                                                    >
                                                                                                        Ganador
                                                                                                    </label>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-6  col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-primary ">
                                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                            Apuesta
                                                                                        </div>
                                                                                        <div className="column_content p-4">{bet.mercado1}</div>
                                                                                    </div>
                                                                                    <div className="col-6   col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                            Cuotas
                                                                                        </div>
                                                                                        <div className="column_content p-4">{bet.cuota1}</div>
                                                                                    </div>
                                                                                    <div className="col-6  col-xl-4 col-xxl-1 p-0 d-flex flex-column border border-active-info">
                                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                            Stake
                                                                                        </div>
                                                                                        <div className="column_content p-4">{bet.stake1}</div>
                                                                                    </div>
                                                                                    <div className="col-6 col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                            Ganancia
                                                                                        </div>
                                                                                        <div className="column_content p-4">{bet.yield1}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="accordion-item">
                                                                        <h2
                                                                            className="accordion-header"
                                                                            id={"kt_accordion_" + (index + 1) + "_header_" + (index + 2)}
                                                                        >
                                                                            <button
                                                                                className="accordion-button fs-4 fw-semibold collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={"#kt_accordion_" + (index + 1) + "_body_" + (index + 2)}
                                                                                aria-expanded="false"
                                                                                aria-controls={"kt_accordion_" + (index + 1) + "_body_" + (index + 2)}
                                                                            >
                                                                                Datos Segunda Apuesta
                                                                            </button>
                                                                        </h2>
                                                                        <div
                                                                            id={"kt_accordion_" + (index + 1) + "_body_" + (index + 2)}
                                                                            className="accordion-collapse collapse"
                                                                            aria-labelledby={"kt_accordion_" + (index + 1) + "_header_" + (index + 2)}
                                                                            data-bs-parent={"#kt_accordion_" + (index + 1)}
                                                                        >
                                                                            <div className="accordion-body">
                                                                                <div
                                                                                    className={`row justify-content-md-center ${bet.ganador == 2 || bet.ganador == 3
                                                                                        ? "bg-light-success"
                                                                                        : bet.ganador == 0
                                                                                            ? "bg-light-danger"
                                                                                            : ""
                                                                                        }`}
                                                                                >
                                                                                    <div className="col-6 col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                            Casa
                                                                                        </div>
                                                                                        <div className="column_content p-4">{bet.casa2}</div>
                                                                                    </div>
                                                                                    <div className="col-6  col-xl-4 col-xxl-3 p-0 d-flex flex-column border border-active-info">
                                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                            Estado
                                                                                        </div>
                                                                                        <div className="column_content p-4 d-flex justify-content-evenly">
                                                                                            <div className="form-check form-check-custom form-check-success form-check-solid">
                                                                                                {bet.estado == 2 ? (
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        type="radio"
                                                                                                        value={1}
                                                                                                        defaultChecked={bet.ganador == 2}
                                                                                                        onClick={() => estadoCasa(bet.id_surebet, 2)}
                                                                                                        id="flexCheckboxLgR"
                                                                                                        name={"radioEstadoR" + index}
                                                                                                    />
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                                {bet.estado != 2 ? (
                                                                                                    bet.ganador == 2 || bet.ganador == 3 ? (
                                                                                                        <label
                                                                                                            className="form-check-label"
                                                                                                            htmlFor={"flexCheckboxLg"}
                                                                                                        >
                                                                                                            Ganado
                                                                                                        </label>
                                                                                                    ) : (
                                                                                                        <label
                                                                                                            className="form-check-label"
                                                                                                            htmlFor={"flexCheckboxLg"}
                                                                                                        >
                                                                                                            Perdido
                                                                                                        </label>
                                                                                                    )
                                                                                                ) : bet.ganador == 0 ? (
                                                                                                    <label
                                                                                                        className="form-check-label"
                                                                                                        htmlFor={"flexCheckboxLg"}
                                                                                                    >
                                                                                                        Perdedor
                                                                                                    </label>
                                                                                                ) : (
                                                                                                    <label
                                                                                                        className="form-check-label"
                                                                                                        htmlFor={"flexCheckboxLg"}
                                                                                                    >
                                                                                                        Ganador
                                                                                                    </label>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-6  col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-light">
                                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                            Apuesta
                                                                                        </div>
                                                                                        <div className="column_content p-4">{bet.mercado2}</div>
                                                                                    </div>
                                                                                    <div className="col-6   col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                            Cuotas
                                                                                        </div>
                                                                                        <div className="column_content p-4">{bet.cuota2}</div>
                                                                                    </div>
                                                                                    <div className="col-6  col-xl-4 col-xxl-1 p-0 d-flex flex-column border border-active-info">
                                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                            Stake
                                                                                        </div>
                                                                                        <div className="column_content p-4">{bet.stake2}</div>
                                                                                    </div>
                                                                                    <div className="col-6 col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                            Ganancia
                                                                                        </div>
                                                                                        <div className="column_content p-4">{bet.yield2}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="accordion-item">
                                                                        <h2
                                                                            className="accordion-header"
                                                                            id={"kt_accordion_" + (index + 1) + "_header_" + (index + 3)}
                                                                        >
                                                                            <button
                                                                                className="accordion-button fs-4 fw-semibold collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={"#kt_accordion_" + (index + 1) + "_body_" + (index + 3)}
                                                                                aria-expanded="false"
                                                                                aria-controls={"kt_accordion_" + (index + 1) + "_body_" + (index + 3)}
                                                                            >
                                                                                Acciones
                                                                            </button>
                                                                        </h2>
                                                                        <div
                                                                            id={"kt_accordion_" + (index + 1) + "_body_" + (index + 3)}
                                                                            className="accordion-collapse collapse"
                                                                            aria-labelledby={"kt_accordion_" + (index + 1) + "_header_" + (index + 3)}
                                                                            data-bs-parent={"#kt_accordion_" + (index + 1)}
                                                                        >
                                                                            <div className="accordion-body">
                                                                                <div className="row justify-content-md-center">
                                                                                    <div className="col-6 col-xl-3 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                            Acciones
                                                                                        </div>
                                                                                        <div className="column_content p-4 d-flex gap-2 justify-content-center align-items-center h-100">
                                                                                            <OverlayTrigger placement="top" overlay={tooltipEd}>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-secondary btn-sm"
                                                                                                    data-bs-toggle="tooltip"
                                                                                                    data-bs-placement="top"
                                                                                                    title="Editar"
                                                                                                    onClick={() => editApuesta(bet)}
                                                                                                >
                                                                                                    <FaRegEdit />
                                                                                                </button>
                                                                                            </OverlayTrigger>
                                                                                            <OverlayTrigger placement="top" overlay={tooltipE}>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-secondary btn-sm"
                                                                                                    data-bs-toggle="tooltip"
                                                                                                    data-bs-placement="top"
                                                                                                    title="Eliminar"
                                                                                                    onClick={() => handleMyDelete(bet)}
                                                                                                >
                                                                                                    <FaTrash />
                                                                                                </button>
                                                                                            </OverlayTrigger>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-6 col-xl-3 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                            Win/Lose
                                                                                        </div>
                                                                                        <div className="column_content p-4 d-flex gap-2 justify-content-center align-items-center h-100">
                                                                                            <OverlayTrigger placement="top" overlay={tooltipMid}>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-primary btn-sm"
                                                                                                    data-bs-toggle="tooltip"
                                                                                                    data-bs-placement="top"
                                                                                                    title="Middle"
                                                                                                    onClick={() => estadoMiddle(bet.id_surebet, 3, 2)}
                                                                                                >
                                                                                                    <FaRegThumbsUp />
                                                                                                </button>
                                                                                            </OverlayTrigger>
                                                                                            <OverlayTrigger placement="top" overlay={tooltipAMid}>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-danger btn-sm"
                                                                                                    data-bs-toggle="tooltip"
                                                                                                    data-bs-placement="top"
                                                                                                    title="Antimiddle"
                                                                                                    onClick={() => estadoMiddle(bet.id_surebet, 0, 2)}
                                                                                                >
                                                                                                    <FaRegThumbsDown />
                                                                                                </button>
                                                                                            </OverlayTrigger>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-6 col-xl-3 col-xxl-4 p-0 d-flex flex-column border border-active-info">
                                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                            Total
                                                                                        </div>
                                                                                        <div className="column_content p-4 d-flex justify-content-center align-items-center">
                                                                                            {bet.total}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-6 col-xl-3 col-xxl-4 p-0 d-flex flex-column border border-active-info">
                                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                            Estados
                                                                                        </div>
                                                                                        <div className="column_content p-4 gap-5 d-flex flex-row justify-content-center justify-content-xxl-evenly">
                                                                                            {bet.estado != 2 ? (
                                                                                                <div className="d-flex justify-content-center">
                                                                                                    <OverlayTrigger
                                                                                                        placement="top"
                                                                                                        overlay={tooltipRes}
                                                                                                    >
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn btn-primary btn-sm"
                                                                                                            data-bs-toggle="tooltip"
                                                                                                            data-bs-placement="top"
                                                                                                            title="Restablecer"
                                                                                                            onClick={() =>
                                                                                                                estadoFinal(bet.id_surebet, 2)
                                                                                                            }
                                                                                                        >
                                                                                                            <FaHistory />
                                                                                                        </button>
                                                                                                    </OverlayTrigger>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <div className="d-flex gap-2 flex-wrap justify-content-center">
                                                                                                        <OverlayTrigger
                                                                                                            placement="top"
                                                                                                            overlay={tooltipG}
                                                                                                        >
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-success btn-sm"
                                                                                                                data-bs-toggle="tooltip"
                                                                                                                data-bs-placement="top"
                                                                                                                title="Ganado"
                                                                                                                onClick={() =>
                                                                                                                    estadoFinal(bet.id_surebet, 1)
                                                                                                                }
                                                                                                            >
                                                                                                                <FaDonate />
                                                                                                            </button>
                                                                                                        </OverlayTrigger>
                                                                                                        <OverlayTrigger
                                                                                                            placement="top"
                                                                                                            overlay={tooltipD}
                                                                                                        >
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-warning btn-sm"
                                                                                                                data-bs-toggle="tooltip"
                                                                                                                data-bs-placement="top"
                                                                                                                title="Devuelto"
                                                                                                                onClick={() =>
                                                                                                                    estadoFinal(bet.id_surebet, 3)
                                                                                                                }
                                                                                                            >
                                                                                                                <FaUndo />
                                                                                                            </button>
                                                                                                        </OverlayTrigger>
                                                                                                        <OverlayTrigger
                                                                                                            placement="top"
                                                                                                            overlay={tooltipP}
                                                                                                        >
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-danger btn-sm"
                                                                                                                data-bs-toggle="tooltip"
                                                                                                                data-bs-placement="top"
                                                                                                                title="Perdido"
                                                                                                                onClick={() =>
                                                                                                                    estadoFinal(bet.id_surebet, 0)
                                                                                                                }
                                                                                                            >
                                                                                                                <FaBan />
                                                                                                            </button>
                                                                                                        </OverlayTrigger>
                                                                                                    </div>
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 p-0 d-flex flex-column border border-active-info">
                                                                                        <div className="column_head p-2 text-uppercase fw-semibold">
                                                                                            Observación
                                                                                        </div>
                                                                                        <div className="column_content p-4">{bet.observacion}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* DATOS RESPONSIVE EN XXL */}
                                                                {/* datos primera apuesta */}
                                                                <div
                                                                    className={`d-none d-lg-flex row justify-content-md-center mx-0 ${bet.ganador == 1 || bet.ganador == 3
                                                                        ? "bg-light-success"
                                                                        : bet.ganador == 0
                                                                            ? "bg-light-danger"
                                                                            : ""
                                                                        }`}
                                                                >
                                                                    <div className="col-6 col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_head p-2 text-uppercase fw-semibold">Casa</div>
                                                                        <div className="column_content p-4">{bet.casa1}</div>
                                                                    </div>
                                                                    <div className="col-6  col-md-4 col-lg-3 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_head p-2 text-uppercase fw-semibold">Estado</div>
                                                                        <div className="column_content p-4 d-flex justify-content-evenly">
                                                                            <div className="form-check form-check-custom form-check-success form-check-solid">
                                                                                {bet.estado == 2 ? (
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        value={1}
                                                                                        defaultChecked={bet.ganador == 1}
                                                                                        onClick={() => estadoCasa(bet.id_surebet, 1)}
                                                                                        id={"flexCheckboxLg" + index}
                                                                                        name={"radioEstado" + index}
                                                                                    />
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                                {bet.estado != 2 ? (
                                                                                    bet.ganador == 1 || bet.ganador == 3 ? (
                                                                                        <label
                                                                                            className="form-check-label"
                                                                                            htmlFor={"flexCheckboxLg" + index}
                                                                                        >
                                                                                            Ganado
                                                                                        </label>
                                                                                    ) : (
                                                                                        <label
                                                                                            className="form-check-label"
                                                                                            htmlFor={"flexCheckboxLg" + index}
                                                                                        >
                                                                                            Perdido
                                                                                        </label>
                                                                                    )
                                                                                ) : bet.ganador == 0 ? (
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor={"flexCheckboxLg" + index}
                                                                                    >
                                                                                        Perdedor
                                                                                    </label>
                                                                                ) : (
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor={"flexCheckboxLg" + index}
                                                                                    >
                                                                                        Ganador
                                                                                    </label>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6  col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_head p-2 text-uppercase fw-semibold">Apuesta</div>
                                                                        <div className="column_content p-4">{bet.mercado1}</div>
                                                                    </div>
                                                                    <div className="col-6   col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_head p-2 text-uppercase fw-semibold">Cuotas</div>
                                                                        <div className="column_content p-4">{bet.cuota1}</div>
                                                                    </div>
                                                                    <div className="col-6  col-md-4 col-lg-1 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_head p-2 text-uppercase fw-semibold">Stake</div>
                                                                        <div className="column_content p-4">{bet.stake1}</div>
                                                                    </div>
                                                                    <div className="col-6 col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_head p-2 text-uppercase fw-semibold">Ganancia</div>
                                                                        <div className="column_content p-4">{bet.yield1}</div>
                                                                    </div>
                                                                </div>
                                                                {/* datos primera apuesta */}

                                                                {/* datos segunda apuesta */}
                                                                <div
                                                                    className={`d-none d-lg-flex row justify-content-md-center mx-0  ${bet.ganador == 2 || bet.ganador == 3
                                                                        ? "bg-light-success"
                                                                        : bet.ganador == 0
                                                                            ? "bg-light-danger"
                                                                            : ""
                                                                        }`}
                                                                >
                                                                    <div className="col-6 col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_content p-4">{bet.casa2}</div>
                                                                    </div>
                                                                    <div className="col-6  col-md-4 col-lg-3 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_content p-4 d-flex justify-content-evenly">
                                                                            <div className="form-check form-check-custom form-check-success form-check-solid">
                                                                                {bet.estado == 2 ? (
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        value={2}
                                                                                        defaultChecked={bet.ganador == 2}
                                                                                        onClick={() => estadoCasa(bet.id_surebet, 2)}
                                                                                        id={"flexCheckboxLg" + (index + 1)}
                                                                                        name={"radioEstado" + index}
                                                                                    />
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                                {bet.estado != 2 ? (
                                                                                    bet.ganador == 2 || bet.ganador == 3 ? (
                                                                                        <label
                                                                                            className="form-check-label"
                                                                                            htmlFor={"flexCheckboxLg" + index}
                                                                                        >
                                                                                            Ganado
                                                                                        </label>
                                                                                    ) : (
                                                                                        <label
                                                                                            className="form-check-label"
                                                                                            htmlFor={"flexCheckboxLg" + index}
                                                                                        >
                                                                                            Perdido
                                                                                        </label>
                                                                                    )
                                                                                ) : bet.ganador == 0 ? (
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor={"flexCheckboxLg" + index}
                                                                                    >
                                                                                        Perdedor
                                                                                    </label>
                                                                                ) : (
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor={"flexCheckboxLg" + index}
                                                                                    >
                                                                                        Ganador
                                                                                    </label>
                                                                                )}
                                                                                {/* {reactivar && (bet.ganador !== 2 || bet.ganador !== 3) ? (
                                                                    <></>
                                                                ) : (
                                                                    <label className="form-check-label" htmlFor={"flexCheckboxLg" + (index + 1)}>
                                                                        Ganador
                                                                    </label>
                                                                )} */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6  col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_content p-4">{bet.mercado2}</div>
                                                                    </div>
                                                                    <div className="col-6   col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_content p-4">{bet.cuota2}</div>
                                                                    </div>
                                                                    <div className="col-6  col-md-4 col-lg-1 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_content p-4">{bet.stake2}</div>
                                                                    </div>
                                                                    <div className=" col-6 col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_content p-4">{bet.yield2}</div>
                                                                    </div>
                                                                </div>
                                                                {/* datos segunda apuesta */}

                                                                {/* acciones */}
                                                                <div className={`d-none d-lg-flex mx-0 row justify-content-md-center`}>
                                                                    <div className="col-5 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_head p-2 text-uppercase fw-semibold">Observación</div>
                                                                        <div className="column_content p-4">{bet.observacion}</div>
                                                                    </div>
                                                                    <div className="col-6 col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_head p-2 text-uppercase fw-semibold">Acciones</div>
                                                                        <div className="column_content p-4 d-flex gap-2 justify-content-center align-items-center h-100">
                                                                            <OverlayTrigger placement="top" overlay={tooltipEd}>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-secondary btn-sm"
                                                                                    data-bs-toggle="tooltip"
                                                                                    data-bs-placement="top"
                                                                                    title="Editar"
                                                                                    onClick={() => editApuesta(bet)}
                                                                                >
                                                                                    <FaRegEdit />
                                                                                </button>
                                                                            </OverlayTrigger>
                                                                            <OverlayTrigger placement="top" overlay={tooltipE}>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-secondary btn-sm"
                                                                                    data-bs-toggle="tooltip"
                                                                                    data-bs-placement="top"
                                                                                    title="Eliminar"
                                                                                    onClick={() => handleMyDelete(bet)}
                                                                                >
                                                                                    <FaTrash />
                                                                                </button>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_head p-2 text-uppercase fw-semibold">Win/Lose</div>
                                                                        <div className="column_content p-4 d-flex gap-2 justify-content-center align-items-center h-100">
                                                                            <OverlayTrigger placement="top" overlay={tooltipMid}>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-secondary btn-sm"
                                                                                    data-bs-toggle="tooltip"
                                                                                    data-bs-placement="top"
                                                                                    title="Middle"
                                                                                    onClick={() => estadoMiddle(bet.id_surebet, 3, 1)}
                                                                                >
                                                                                    <FaRegThumbsUp />
                                                                                </button>
                                                                            </OverlayTrigger>
                                                                            <OverlayTrigger placement="top" overlay={tooltipAMid}>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-secondary btn-sm"
                                                                                    data-bs-toggle="tooltip"
                                                                                    data-bs-placement="top"
                                                                                    title="Antimiddle"
                                                                                    onClick={() => estadoMiddle(bet.id_surebet, 0, 1)}
                                                                                >
                                                                                    <FaRegThumbsDown />
                                                                                </button>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 col-md-4 col-lg-1 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_head p-2 text-uppercase fw-semibold">Total</div>
                                                                        <div className="column_content p-4 d-flex justify-content-center align-items-center">
                                                                            {bet.total}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-4 col-lg-2 p-0 d-flex flex-column border border-active-info">
                                                                        <div className="column_head p-2 text-uppercase fw-semibold">Estados</div>
                                                                        <div className="column_content p-4 gap-5 d-flex flex-column flex-xl-row justify-content-center justify-content-xxl-evenly">
                                                                            {bet.estado != 2 ? (
                                                                                <div className="d-flex justify-content-center">
                                                                                    <OverlayTrigger placement="top" overlay={tooltipRes}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-primary btn-sm"
                                                                                            data-bs-toggle="tooltip"
                                                                                            data-bs-placement="top"
                                                                                            title="Restablecer"
                                                                                            onClick={() => estadoFinal(bet.id_surebet, 2)}
                                                                                        >
                                                                                            <FaHistory />
                                                                                        </button>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                            ) : (
                                                                                <>
                                                                                    <div className="d-flex gap-2 flex-wrap justify-content-center">
                                                                                        <OverlayTrigger placement="top" overlay={tooltipG}>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-primary btn-sm"
                                                                                                data-bs-toggle="tooltip"
                                                                                                data-bs-placement="top"
                                                                                                title="Ganado"
                                                                                                onClick={() => estadoFinal(bet.id_surebet, 1)}
                                                                                            >
                                                                                                <FaDonate />
                                                                                            </button>
                                                                                        </OverlayTrigger>
                                                                                        <OverlayTrigger placement="top" overlay={tooltipD}>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-warning btn-sm"
                                                                                                data-bs-toggle="tooltip"
                                                                                                data-bs-placement="top"
                                                                                                title="Devuelto"
                                                                                                onClick={() => estadoFinal(bet.id_surebet, 3)}
                                                                                            >
                                                                                                <FaUndo />
                                                                                            </button>
                                                                                        </OverlayTrigger>
                                                                                        <OverlayTrigger placement="top" overlay={tooltipP}>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-danger btn-sm"
                                                                                                data-bs-toggle="tooltip"
                                                                                                data-bs-placement="top"
                                                                                                title="Perdido"
                                                                                                onClick={() => estadoFinal(bet.id_surebet, 0)}
                                                                                            >
                                                                                                <FaBan />
                                                                                            </button>
                                                                                        </OverlayTrigger>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* toast */}
                            {/* <div id="kt_docs_toast_stack_container" className="toast-container position-fixed bottom-0 end-0 py-3 px-6 z-index-3">
                    <Toast position="top-start" onClose={() => setShow(false)} show={show} delay={4500} autohide className={"toast border-0 " + "bg-" + alertColor}>
                        <Toast.Body className="text-white toast_text">
                            {alertColor == "danger" ? <FaExclamationCircle></FaExclamationCircle> : <FaCheckCircle></FaCheckCircle>}
                            <span className="ms-2">{alertMsg}</span>
                        </Toast.Body>
                    </Toast>
                </div> */}
                            <ToastContainer
                                theme={localStorage.getItem("userTheme") === "dark" ? "dark" : "light"}
                                className="container_surebets "
                                enableMultiContainer
                                containerId={"A"}
                                position={toast.POSITION.BOTTOM_RIGHT}
                            />

                            {/* modal */}
                            <div className="modal fade" id="kt_modal_edit_surebet" tabIndex="-1" aria-modal="true" role="dialog">
                                <div className="modal-dialog modal-dialog-centered mw-650px">
                                    <div className="modal-content">
                                        <form
                                            className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                            id="kt_modal_add_customer_form"
                                            onSubmit={handleSubmit(onSubmit)}
                                        >
                                            <div className="modal-header" id="kt_modal_add_customer_header">
                                                <h2 className="fw-bold">Editar Surebet</h2>
                                                <div
                                                    id="kt_modal_add_customer_close"
                                                    className="btn btn-icon btn-sm btn-active-icon-primary"
                                                    data-bs-dismiss="modal"
                                                    onClick={resetForm}
                                                >
                                                    <span className="svg-icon svg-icon-1">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect
                                                                opacity="0.5"
                                                                x="6"
                                                                y="17.3137"
                                                                width="16"
                                                                height="2"
                                                                rx="1"
                                                                transform="rotate(-45 6 17.3137)"
                                                                fill="currentColor"
                                                            ></rect>
                                                            <rect
                                                                x="7.41422"
                                                                y="6"
                                                                width="16"
                                                                height="2"
                                                                rx="1"
                                                                transform="rotate(45 7.41422 6)"
                                                                fill="currentColor"
                                                            ></rect>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="modal-body py-10 px-lg-17">
                                                <div
                                                    className="scroll-y me-n7 pe-7"
                                                    id="kt_modal_add_customer_scroll"
                                                    data-kt-scroll="true"
                                                    data-kt-scroll-activate="{default: false, lg: true}"
                                                    data-kt-scroll-max-height="auto"
                                                    data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                                                    data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                                                    data-kt-scroll-offset="300px"
                                                    style={{ maxHeight: "1035px" }}
                                                >
                                                    <div className="row mx-0">
                                                        <div className="col-12 col-lg-6">
                                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                                <span className="">Casa 1</span>
                                                            </label>
                                                            <select
                                                                className="select2-selection select2-selection--single form-select form-select-solid"
                                                                name="casa1"
                                                                {...register("casa1")}
                                                            >
                                                                {/* <option value="">Seleccionar</option> */}
                                                                {bancosList.map((estado) => (
                                                                    <option key={estado.id_constante} value={estado.nombre_constante}>
                                                                        {estado.nombre}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                                <span className="">Casa 2</span>
                                                            </label>
                                                            <select
                                                                className="select2-selection select2-selection--single form-select form-select-solid"
                                                                name="casa2"
                                                                {...register("casa2")}
                                                            >
                                                                {/* <option value="">Seleccionar</option> */}
                                                                {bancosList.map((estado) => (
                                                                    <option key={estado.id_constante} value={estado.nombre_constante}>
                                                                        {estado.nombre}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                                <span className="">Mercado 1</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-solid"
                                                                placeholder="Mercado 1"
                                                                name="mercado1"
                                                                {...register("mercado1")}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                                <span className="">Mercado 2</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-solid"
                                                                placeholder="Mercado 2"
                                                                name="mercado2"
                                                                {...register("mercado2")}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                                <span className="">Cuota 1</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-solid"
                                                                placeholder="Cuota 1"
                                                                name="cuota1"
                                                                {...register("cuota1")}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                                <span className="">Cuota 2</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-solid"
                                                                placeholder="Cuota 2"
                                                                name="cuota2"
                                                                {...register("cuota2")}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                                <span className="">Stake 1</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-solid"
                                                                placeholder="Stake 1"
                                                                name="stake1"
                                                                {...register("stake1")}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                                <span className="">Stake 2</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-solid"
                                                                placeholder="Stake 2"
                                                                name="stake2"
                                                                {...register("stake2")}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                                <span className="">Yield 1</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-solid"
                                                                placeholder="Yield 1"
                                                                name="yield1"
                                                                {...register("yield1")}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                                <span className="">Yield 2</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-solid"
                                                                placeholder="Yield 2"
                                                                name="yield2"
                                                                {...register("yield2")}
                                                            />
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                                <span className="">Deporte</span>
                                                            </label>
                                                            <textarea
                                                                type="text"
                                                                className="form-control form-control-solid"
                                                                placeholder="Deporte"
                                                                name="deporte"
                                                                data-kt-autosize="true"
                                                                {...register("deporte")}
                                                            />
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                                <span className="">Evento</span>
                                                            </label>
                                                            <textarea
                                                                type="text"
                                                                className="form-control form-control-solid"
                                                                placeholder="Evento"
                                                                name="evento"
                                                                data-kt-autosize="true"
                                                                {...register("evento")}
                                                            />
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                                <span className="">Observación</span>
                                                            </label>
                                                            <textarea
                                                                type="text"
                                                                className="form-control form-control-solid"
                                                                placeholder="Observación"
                                                                name="observacion"
                                                                data-kt-autosize="true"
                                                                {...register("observacion")}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="modal-footer flex-center">
                                                <button
                                                    type="reset"
                                                    id="kt_modal_add_customer_cancel"
                                                    className="btn btn-danger me-3"
                                                    data-bs-dismiss="modal"
                                                    onClick={resetForm}
                                                >
                                                    Cerrar
                                                </button>
                                                <button type="submit" id="kt_modal_add_customer_submit" className="btn btn-primary">
                                                    {loading3 ? (
                                                        <span className="indicator-label">
                                                            Actualizando....
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    ) : (
                                                        <span className="indicator-label">Editar</span>
                                                    )}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="kt_modal_reporte" tabIndex="-1" aria-modal="true" role="dialog">
                                <div className="modal-dialog modal-dialog-centered mw-650px">
                                    <div className="modal-content">
                                        <form className="form fv-plugins-bootstrap5 fv-plugins-framework" id="kt_modal_reporte_form" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="modal-header" id="kt_modal_reporte_header">
                                                <h2 className="fw-bold">Reporte Diario</h2>
                                                <div
                                                    id="kt_modal_reporte_close"
                                                    className="btn btn-icon btn-sm btn-active-icon-primary"
                                                    data-bs-dismiss="modal"
                                                    onClick={resetForm}
                                                >
                                                    <span className="svg-icon svg-icon-1">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect
                                                                opacity="0.5"
                                                                x="6"
                                                                y="17.3137"
                                                                width="16"
                                                                height="2"
                                                                rx="1"
                                                                transform="rotate(-45 6 17.3137)"
                                                                fill="currentColor"
                                                            ></rect>
                                                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="modal-body p-0">
                                                <div
                                                    className="scroll-y me-n7 pe-7"
                                                    id="kt_modal_reporte_scroll"
                                                    data-kt-scroll="true"
                                                    data-kt-scroll-activate="{default: false, lg: true}"
                                                    data-kt-scroll-max-height="auto"
                                                    data-kt-scroll-dependencies="#kt_modal_reporte_header"
                                                    data-kt-scroll-wrappers="#kt_modal_reporte_scroll"
                                                    data-kt-scroll-offset="300px"
                                                    style={{ maxHeight: "1035px" }}
                                                >
                                                    <div className="row mx-0 p-2 border-2 border-bottom">
                                                        <div className="col-3 text-center text-center">
                                                            <span className="font-bold">Producción</span>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <span className="font-bold">Movido</span>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <span className="font-bold">Pendiente</span>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <span className="font-bold">Rentabilidad</span>
                                                        </div>
                                                    </div>
                                                    <div className="row d-flex mx-0 p-2">
                                                        <div className="col-3 text-center">
                                                            <span className="">{itemsReporte.ganancia != null ? parseFloat(itemsReporte.ganancia).toFixed(2) : (0).toFixed(2)}</span>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <span className="">{itemsReporte.movido != null ? parseFloat(itemsReporte.movido).toFixed(2) : (0).toFixed(2)}</span>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <span className="">{itemsReporte.pendiente != null ? parseFloat(itemsReporte.pendiente).toFixed(2) : (0).toFixed(2)}</span>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <span className="">{itemsReporte.yield != null && itemsReporte.yield != "NaN" ? itemsReporte.yield : 0} %</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer flex-center">
                                                <button
                                                    type="reset"
                                                    id="kt_modal_reporte_cancel"
                                                    className="btn btn-danger me-3"
                                                    data-bs-dismiss="modal"
                                                >
                                                    Cerrar
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {loading ? (
                            <div className="overlay-layer card-rounded bg-black bg-opacity-50">
                                <div className="d-flex flex-column align-items-center mt-10">
                                    <div className="spinner-border text-white" role="status"></div>
                                    <span>Buscando datos...</span>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </Main>
    );
};

export default JugadasSureBets;
