/* eslint-disable */

import UseService from "../UseServices";

const CuentasService = {
    //AGRUPADORES
    //BANCOS y CASA DE APUESTAS
    async getAgrupador(data) {
        var resp = await UseService.post("constantes/listar/", data);
        return resp;
    },
    //listar cuentas bancarias segun banco
    async getCuentasBancarias(data) {
        var resp = await UseService.post("constantes/agrupadores/", data);
        return resp;
    },
    //CASAS CUENTAS
    async getCasasCuentas(data) {
        var resp = await UseService.post("casascuentas/", data);
        return resp;
    },
    // async getCasasCuentasxCB(data) {
    //     var resp = await UseService.post("casascuentas/select", data);
    //     return resp;
    // },
    async addCuentaCasa(data) {
        var resp = await UseService.post("casascuentas/registrar", data);
        return resp;
    },
    async updateCuentaCasa(data) {
        var resp = await UseService.put("casascuentas/actualizar", data);
        return resp;
    },
    async updateStateCuentaCasa(data) {
        var resp = await UseService.put("casascuentas/estado", data);
        return resp;
    },

    //CUENTAS BANCARIAS
    async getCuentas(data) {
        var resp = await UseService.post("cuentasbancarias/", data);
        return resp;
    },
    async getSelectCuentas(data) {
        var resp = await UseService.post("cuentasbancarias/select", data);
        return resp;
    },
    async addCuentaBancaria(data) {
        var resp = await UseService.post("cuentasbancarias/registrar", data);
        return resp;
    },
    async updateCuentaBancaria(data) {
        var resp = await UseService.put("cuentasbancarias/actualizar", data);
        return resp;
    },
    async updateStateCuentaBancaria(data) {
        var resp = await UseService.put("cuentasbancarias/estado", data);
        return resp;
    },
    // async saveCompany(data,store){
    //     var resp = await VueStoreService.post("api/company",data,store)
    //     return resp;
    // },
    // async getCompany(company_id,store){
    //     var resp = await VueStoreService.get( "api/company/"+company_id,store)
    //     return resp;
    // },
    // async updateCompany(company_id,data,store){
    //     var resp = await VueStoreService.put("api/company/"+company_id,data,store)
    //     return resp;
    // },

    // async deleteCompany(company_id,store){
    //     var resp = await VueStoreService.delete("api/company/"+company_id,store)
    //     return resp;
    // },
};

export default CuentasService;