/* eslint-disable */
import React, { useEffect, useState, useLayoutEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Navbar, Sidebar } from "../Components";
import moment from "moment/moment";
import "moment/locale/es";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Bancarias,
    Casas,
    Movimientos,
    BancaDiario,
    Clones,
    ForgotPass,
    Live,
    Arbitraje,
    Calculator,
    Login,
    Nomenclaturas,
    Perfil,
    Planes,
    DetallePlanes,
    Prematch,
    Registrarse,
    RestorePass,
    ReporteSureBets,
    ReporteApuestas,
    JugadasApuestas,
    JugadasSureBets,
    HistorialPlanes,
    HistorialConexiones,
    Promociones,
    TerminosYCondiciones,
    Eventos,
    Montos,
    ReporteMontos,
    CajaCuenta,
    Confirmados,
    Consolidado,
    GraficosE,
    Combinadas,
    PanelAdmin,
    Utilidades
} from "../Pages";
import { useUserContext } from "../Providers/UserProvider";
const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
};
const ControllerSesion = () => {
    const { user, setUser } = useUserContext();
    let timeTotal = moment.unix(user.iat).startOf("hour").fromNow();
    // const [stake, setStake] = useState(user.stake)
    // const [userTc, setUserTc] = useState(user.tipocambio)
    const { setDataPlan } = useUserContext();
    useEffect(() => {
        window.KTComponents.init();
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            toast.warning("Se encuentra operando " + timeTotal + ".Tome descansos periodicamente.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "A",
            });
        }, 1000 * 60 * 60 * 4);
        return () => clearInterval(interval);
    }, []);
    if (user === {} || !localStorage.userCca) {
        return (
            <Routes>
                <Route path="/" element={<Login userUpLog={(datos) => setUser(datos)} />} />
                <Route path="/registrarse" element={<Registrarse />} />
                <Route path="/restaurar" element={<ForgotPass />} />
                <Route path="/restablecer" element={<RestorePass />} />
                {/* <Route path="/terminos-y-condiciones" element={<TerminosYCondiciones />} /> */}
                <Route path="*" element={<Login userUpLog={(datos) => setUser(datos)} />} />
            </Routes>
        );
    }

    return (
        <Wrapper>
            <div className="d-flex flex-column flex-root position-relative h-100">
                {/* <div className="">
            <Navbar userStake={(datos)=> setStake(datos)} userTc={(datos)=>setUserTc(datos)} />
            </div>
            <div className="page d-flex flex-row flex-column-fluid">
                <Sidebar />
                <div id="kt_wrapper" className="wrapper d-flex flex-column flex-row-fluid">                   

                    <div id="kt_content" className="content fs-6 d-flex flex-column flex-column-fluid pb-20">
                        <div id="kt_post" className="post fs-6 d-flex flex-column-fluid ">
                            
                        </div>
                    </div>
                </div>
            </div> */}
                <Routes>
                    <Route path="/" element={<Navigate to="/perfil" />} />
                    <Route path="/live" element={<Live />} />
                    <Route path="/arbitraje" element={<Arbitraje />} />
                    <Route path="/calculator" element={<Calculator />} />
                    {user.frqpoint == 0 && <Route path="/administrativo" element={<PanelAdmin />} />}
                    <Route path="/utilidades" element={<Utilidades />} />
                    <Route path="/prematch" element={<Prematch />} />
                    {/* <Route path="/montos" element={<Montos />}></Route>
                    <Route path="/confirmados" element={<Confirmados />}></Route>
                    <Route path="/caja-cuenta" element={<CajaCuenta />}></Route>
                    <Route path="/consolidado" element={<Consolidado />}></Route>
                    <Route path="/reporte-montos" element={<ReporteMontos />}></Route> */}
                    {user.frqpoint == 0 && <Route path="/bancarias" element={<Bancarias />}></Route>}
                    {user.frqpoint == 0 && <Route path="/casas" element={<Casas />}></Route>}
                    <Route path="/perfil" element={<Perfil />} />
                    {user.frqpoint != 0 && <Route path="/movimientos" element={<Movimientos />}>
                    </Route>}
                    {<Route path="/bankdiario" element={<BancaDiario />}>
                    </Route>}
                    <Route path="/eventos" element={<Eventos />}></Route>
                    {/* <Route path="/graficos-eventos" element={<GraficosE />} /> */}
                    <Route path="/clones" element={<Clones />} />
                    {/* <Route path="/gastos" element={<Gastos />} /> */}
                    <Route path="/planes" element={<Planes dataPlanes={(datos) => setDataPlan(datos)} />} />
                    <Route path="/planes/:plan" element={<DetallePlanes />} />
                    <Route path="/nomenclaturas" element={<Nomenclaturas />} />
                    <Route path="/historial-planes" element={<HistorialPlanes />} />
                    <Route path="/promociones" element={<Promociones />} />
                    <Route path="/historial-conexiones" element={<HistorialConexiones />} />
                    {user.frqpoint != 0 && <Route path="/reporte-surebets" element={<ReporteSureBets />}>
                    </Route>}
                    {user.frqpoint != 0 && <Route path="/reporte-apuestas" element={<ReporteApuestas />}>
                    </Route>}
                    {user.frqpoint != 0 && <Route path="/jugadas-apuestas" element={<JugadasApuestas />}>
                    </Route>}
                    {user.frqpoint != 0 && <Route path="/jugadas-surebets" element={<JugadasSureBets />}>
                    </Route>}
                    {user.frqpoint != 0 && <Route path="/combinadas" element={<Combinadas />}>
                    </Route>}
                    <Route path="*" element={<Perfil />} />
                </Routes>
                <ToastContainer
                    theme={localStorage.getItem("userTheme") === "dark" ? "dark" : "light"}
                    className="container_surebets "
                    enableMultiContainer
                    containerId={"A"}
                    position={toast.POSITION.BOTTOM_RIGHT}
                />
            </div>
        </Wrapper>
    );
};

export default ControllerSesion;
