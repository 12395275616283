/* eslint-disable */
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../Components/utils/BreadCrumb";
import { Link } from "react-router-dom";
import IconCheck from "../../../Components/utils/icons/IconCheck";
import IconClose from "../../../Components/utils/icons/IconClose";
import { useUserContext } from "../../../Providers/UserProvider";
import Main from "../../../layout/main";
const Planes = ({ dataPlanes }) => {
    const { user } = useUserContext();
    //Hooks
    const [selectTime, setSelectTime] = useState(2);
    const [pricePrematch, setPricePrematch] = useState(99);
    const [priceLive, setPriceLive] = useState(149);
    const [priceLyP, setPriceLyP] = useState(199);
    const [pricePlus, setPricePlus] = useState(250);
    const [stake, setStake] = useState(user.stake);
    const [userTc, setUserTc] = useState(user.tipocambio);
    //BreadCrumb
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/planes", name: "Planes", active: false },
    ];
    const changeTime = (time) => {
        if (time == 1) {
            setSelectTime(1);
            setPricePrematch(64);
            setPriceLive(89);
            setPriceLyP(114);
        }
        if (time == 2) {
            setSelectTime(2);
            setPricePrematch(99);
            setPriceLive(149);
            setPriceLyP(199);
            setPricePlus(250);
        }
        if (time == 3) {
            setSelectTime(3);
            setPricePrematch(30);
            setPriceLive(35);
            setPriceLyP(55);
        }
        if (time == 4) {
            setSelectTime(4);
            setPricePrematch(60);
            setPriceLive(65);
            setPriceLyP(100);
        }
    };
    const selectPlan = (id, tiempo, precio) => {
        let datosPlan = {
            id: id,
            tiempo: tiempo,
            precio: precio,
        };
        dataPlanes(datosPlan);
        window.localStorage.filterData = JSON.stringify(datosPlan);
    };
    return (
        <Main userStake={(datos) => setStake(datos)} userTC={(datos) => setUserTc(datos)}>
            <div className="container-xxl mw-100 ">
                <div className="toolbar" id="kt_toolbar">
                    <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className="container-plan w-100 " style={{padding: "0px 30px"}}>
                    <div className="card" id="kt_pricing">
                        <div className="card-body p-lg-17">
                            <div className="d-flex flex-column">
                                <div className="mb-13 text-center">
                                    <h1 className="fs-2hx fw-bold mb-5">Nuestros Planes</h1>
                                </div>
                                {/* <div className="nav-group nav-group-outline mx-auto mb-15" data-kt-buttons="true" data-kt-initialized="1">
                                    <button
                                        onClick={() => changeTime(1)}
                                        className={`btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ${selectTime == 1 ? "active" : ""}`}
                                    >
                                        1 Mes
                                    </button>
                                    <button
                                        onClick={() => changeTime(2)}
                                        className={`btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ${selectTime == 2 ? "active" : ""}`}
                                    >
                                        3 Meses
                                    </button>
                                    <button
                                        onClick={() => changeTime(3)}
                                        className={`btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ${selectTime == 3 ? "active" : ""}`}
                                    >
                                        6 Meses
                                    </button>
                                    <button
                                        onClick={() => changeTime(4)}
                                        className={`btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 ${selectTime == 4 ? "active" : ""}`}
                                    >
                                        1 Año
                                    </button>
                                </div> */}
                                {/*<div className="nav-group nav-group-outline mx-auto mb-15" data-kt-buttons="true" data-kt-initialized="1">
                                     <button
                                        onClick={() => changeTime(1)}
                                        className={`btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ${selectTime == 1 ? "active" : ""}`}
                                    >
                                        15 Dias
                                    </button> 
                                    <button
                                        onClick={() => changeTime(2)}
                                        className={`btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ${selectTime == 2 ? "active" : ""}`}
                                    >
                                        30 Dias
                                    </button>
                                </div>*/}
                                <div className="row g-10">
                                    <div className="col-xl-3">
                                        <div className="d-flex h-100 align-items-center">
                                            <div className="w-100 h-100 d-flex flex-column flex-center bg-light-primary rounded border-primary border-3 border-dashed py-15 px-10">
                                                <div className="mb-7 text-center">
                                                    <h1 className="text-dark mb-5 fw-bolder">Pre-Match</h1>

                                                    {/*<div className="text-gray-400 fw-semibold mb-5">Tiempo y precio dinámicos</div>*/}
                                                    <div className="text-center">
                                                        <span className="mb-2 ">$</span>

                                                        <span className="fs-3x fw-bold ">{pricePrematch}</span>
                                                    </div>
                                                </div>
                                                <div className="w-100 mb-10">
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Opciones de Middles </span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-primary">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Calculadora inteligente</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-primary">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Surebets ilimitadas</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-primary">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Apuestas en Prematch</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-primary">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Acceso a filtros</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-primary">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-400 flex-grow-1">Apuestas en Live</span>
                                                        <span className="svg-icon svg-icon-1">
                                                            <IconClose></IconClose>
                                                        </span>
                                                    </div>
                                                </div>
                                                <Link
                                                    onClick={() => selectPlan(selectTime ==1? 1 : 2, selectTime, pricePrematch)}
                                                    className="btn btn-sm btn-success"
                                                    to={"/planes/prematch"}
                                                >
                                                    Seleccionar plan
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3">
                                        <div className="d-flex h-100 align-items-center">
                                            <div className="w-100 h-100 d-flex flex-column flex-center bg-light-warning rounded border-warning border-3 border-dashed  py-15 px-10">
                                                <div className="mb-7 text-center">
                                                    <h1 className="text-dark mb-5 fw-bolder">Live & Pre-Match</h1>
                                                    {/*<div className="text-gray-400 fw-semibold mb-5">Tiempo y precio dinámicos</div>*/}
                                                    <div className="text-center">
                                                        <span className="mb-2">$</span>

                                                        <span className="fs-3x fw-bold" data-kt-plan-price-month="999" data-kt-plan-price-annual="9999">
                                                            {priceLyP}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="w-100 mb-10">
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Opciones de Middles </span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-warning">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Calculadora inteligente</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-warning">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Surebets ilimitadas</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-warning">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Apuestas en Live</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-warning">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Acceso a filtros</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-warning">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Apuestas en Prematch</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-warning">
                                                        <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                </div>
                                                <Link
                                                    onClick={() => selectPlan(selectTime ==1? 5 : 6, selectTime, priceLyP)}
                                                    className="btn btn-sm btn-warning"
                                                    to={"/planes/live&prematch"}
                                                >
                                                    Seleccionar plan
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3">
                                        <div className="d-flex h-100 align-items-center">
                                            <div className="w-100 h-100 d-flex flex-column flex-center bg-light-success rounded border-success border-3 border-dashed  py-15 px-10">
                                                <div className="mb-7 text-center">
                                                    <h1 className="text-dark mb-5 fw-bolder">Live</h1>
                                                    {/*<div className="text-gray-400 fw-semibold mb-5">Tiempo y precio dinámicos</div>*/}
                                                    <div className="text-center">
                                                        <span className="mb-2">$</span>

                                                        <span className="fs-3x fw-bold" data-kt-plan-price-month="339" data-kt-plan-price-annual="3399">
                                                            {priceLive}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="w-100 mb-10">
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Opciones de Middles </span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Calculadora inteligente</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Surebets ilimitadas</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Apuestas en Live</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Acceso a filtros</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-400 flex-grow-1">Apuestas en Prematch</span>
                                                        <span className="svg-icon svg-icon-1">
                                                            <IconClose></IconClose>
                                                        </span>
                                                    </div>
                                                </div>
                                                <Link onClick={() => selectPlan(selectTime ==1? 3 : 4, selectTime, priceLive)} className="btn btn-sm btn-primary" to={"/planes/live"}>
                                                    Seleccionar plan
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3">
                                        <div className="d-flex h-100 align-items-center">
                                            <div className="w-100 d-flex flex-column flex-center bg-light-danger rounded border-danger border-3 border-dashed  py-15 px-10">
                                                <div className="mb-7 text-center">
                                                    <h1 className="text-dark mb-5 fw-bolder">PLUS</h1>
                                                    {/*<div className="text-gray-400 fw-semibold mb-5">Tiempo y precio dinámicos</div>*/}
                                                    <div className="text-center">
                                                        <span className="mb-2">$</span>

                                                        <span className="fs-3x fw-bold" data-kt-plan-price-month="339" data-kt-plan-price-annual="3399">
                                                            {pricePlus}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="w-100 mb-10">
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Opciones de Middles </span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-danger">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Calculadora inteligente</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-danger">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Surebets ilimitadas</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-danger">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Apuestas en Live</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-danger">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Acceso a filtros</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-danger">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Apuestas en Prematch</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-danger">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Calendario Deportivo</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-danger">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-5">
                                                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Panel Administrativo</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-danger">
                                                            <IconCheck></IconCheck>
                                                        </span>
                                                    </div>
                                                </div>
                                                <Link onClick={() => selectPlan(selectTime ==1? 3 : 12, selectTime, pricePlus)} className="btn btn-sm btn-danger" to={"/planes/plus"}>
                                                    Seleccionar plan
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    );
};

export default Planes;
