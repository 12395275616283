/* eslint-disable */

import UseService from "../UseServices";

const EstadisticaService = {
    async getResumen(data) {
        var resp = await UseService.post("resumen/", data);
        return resp;
    },
    async getProduccion(data) {
        var resp = await UseService.post("resumen/produccion", data);
        return resp;
    },
    async getJugadas(data) {
        var resp = await UseService.post("resumen/jugadas", data);
        return resp;
    },
    async getRentabilidad(data) {
        var resp = await UseService.post("resumen/rentabilidad", data);
        return resp;
    },
    async getCapital(data) {
        var resp = await UseService.post("resumen/capital", data);
        return resp;
    },
    async getProduccionVsGastos(data) {
        var resp = await UseService.post("resumen/vsgastosproduccion", data);
        return resp;
    },
};
export default EstadisticaService;