/* eslint-disable */
import React, { useState, useEffect } from "react";
import Main from "../../layout/main";
import Breadcrumbs from "../../Components/utils/BreadCrumb";
import MUIDataTable from "mui-datatables";
import { useForm, Controller } from "react-hook-form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { useUserContext } from "../../Providers/UserProvider";
import CombinadasService from "../../api/services/combinadasService";
import OperadoresService from "../../api/services/operadoresService";
import moment from "moment/moment";

//TOOLTIPS
const tooltipE = (
    <Tooltip id="tooltipE">
      <strong>Editar</strong>
    </Tooltip>
  );
  const tooltipP = (
    <Tooltip id="tooltipP">
      <strong>Eliminar</strong>
    </Tooltip>
  );

const today = new Date();

const tipos = [
    {
        text: "A favor",
        value: 1,
    },
    {
        text: "En contra",
        value: 2,
    },
];

const moneda = [
    {
        text: "Dolares",
        value: 1,
    },
    {
        text: "Moneda local",
        value: 2,
    },
];

const Combinadas = () => {

    /* -----USEFORM----*/
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    /* -----BREADCRUMBS----*/
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/gastos", name: "Combinadas", active: true },
    ];
    /* -----FIN BREADCRUMBS----*/

    const columns1 = [
        {
          name: "id_combinada",
          label: "",
          options: {
            display: false,
          },
        },
        {
          name: "id_operador",
          label: "",
          options: {
            display: false,
          },
        },
        {
          name: "nom",
          label: "Operador",
          options: {
            filter: true,
            sort: true,
            setCellProps: () => ({
              align: "center",
            }),
            setCellHeaderProps: (value) => ({
              className: "centeredHeaderCell",
            }),
          },
        },
        {
          name: "tipo",
          label: "Tipo",
          options: {
            filter: true,
            sort: true,
            setCellProps: () => ({
              align: "center",
            }),
            setCellHeaderProps: (value) => ({
              className: "centeredHeaderCell",
            }),
            customBodyRender: (value, tableMeta, updateValue) => {
                return <span>{value == 1 ? 'A favor' : 'En contra'}</span>;
            },
          },
        },
        {
            name: "tipomoneda",
          label: "Moneda",
          options: {
            filter: true,
            sort: true,
            setCellProps: () => ({
              align: "center",
            }),
            setCellHeaderProps: (value) => ({
              className: "centeredHeaderCell",
            }),
            customBodyRender: (value, tableMeta, updateValue) => {
                return <span>{value == 1 ? 'Dolares' : 'Moneda local'}</span>;
            },
          },
        },
        {
          name: "monto",
          label: "DOLARES/MONEDA LOCAL",
          options: {
            setCellProps: () => ({
              align: "center",
            }),
            setCellHeaderProps: (value) => ({
              className: "centeredHeaderCell",
            }),
            customBodyRender: (value, tableMeta, updateValue) => {
              return <span>{parseFloat(value).toFixed(2)}</span>;
            },
          },
        },
        {
          name: "monto_cambio",
          label: "CONVERTIDO (DOLARES)",
          options: {
            setCellProps: () => ({
              align: "center",
            }),
            setCellHeaderProps: (value) => ({
              className: "centeredHeaderCell",
            }),
            customBodyRender: (value, tableMeta, updateValue) => {
              return <span>$ {parseFloat(value).toFixed(2)}</span>;
            },
          },
        },
        {
          name: "fecha",
          label: "Fecha",
          options: {
            setCellProps: () => ({
              align: "center",
            }),
            setCellHeaderProps: (value) => ({
              className: "centeredHeaderCell",
            }),
            customBodyRender: (value) => {
              return <span> {moment(value).format("DD-MM-yyyy")}</span>;
            },
          },
        },
        {
          name: "Acciones",
          label: "ACCIONES",
          options: {
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div className="d-flex gap-3 flex-wrap justify-content-center">
                  <OverlayTrigger placement="top" overlay={tooltipE}>
                    <button className="btn btn-success btn-sm btn-icon" onClick={() => editCombinada(tableMeta)}>
                      <FaPencilAlt></FaPencilAlt>
                    </button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={tooltipP}>
                        <button className="btn btn-danger btn-sm btn-icon" onClick={() => handleMyState(tableMeta)}>
                      <FaTrash></FaTrash>
                    </button>
                  </OverlayTrigger>
                </div>
              );
            },
            setCellProps: () => ({
              align: "center",
            }),
            setCellHeaderProps: (value) => ({
              className: "centeredHeaderCell",
            }),
          },
        },
      ];

    const options = {
        print: false,
        viewColumns: false,
        filter: false,
        filterType: "dropdown",
        selectableRows: "none",
        responsive: "standard",
        fixedSelectColumn: false,
        download: true,
        textLabels: {
            body: {
                noMatch: "No se encontraron datos",
                toolTip: "Sort",
                columnHeaderTooltip: (column) => `Ordenador por ${column.label}`,
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Elementos por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscador",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtrar Tabla",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Resetear",
            },
            viewColumns: {
                title: "Mostrar Columnas",
                titleAria: "Mostrar/Ocultar Columnas",
            },
            selectedRows: {
                text: "fila(s) seleccionada(s)",
                delete: "Eliminar",
                deleteAria: "Eliminar fila seleccionada",
            },
            centeredTableHead: {
                "& > span": {
                    justifyContent: "center",
                },
            },
        },
    };

    const { user } = useUserContext();
    const [filtros, setFiltros] = useState({
        f_inicio: moment(today).format("yyyy-MM-DD"),
        f_fin: moment(today).format("yyyy-MM-DD"),
    });
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [total, setTotal] = useState(0);
    const [operadores, setOperadores] = useState([]);
    const [items, setItems] = useState([]);
    const [isOpe, setIsOpe] = useState(false);
    const [fechaM, setFechaM] = useState(moment(today).format("yyyy-MM-DD"));
    const [selectType, setSelectType] = useState(0);
    const [selectTypeCurrency, setSelectTypeCurrency] = useState(0);

    /* -----DATOS----*/
    const refreshToken = localStorage.getItem("refreshToken");
    /* -----FIN DATOS----*/

    //LISTAR DATA
    const selectData = async () => {
        const body = {
            refreshToken: "Bearer " + refreshToken,
        };
        const respOper = await OperadoresService.getOperadores(body);
        // console.log("respOper", respOper);
        if (respOper.status) {
            setOperadores(respOper.data[0]);
        }
        changeType(0);
    };

    const getData = async () => {
        setLoading(true);
        // selectData()
        const body = {
            refreshToken: "Bearer " + refreshToken,
            fechainicio: filtros.f_inicio,
            fechafin: filtros.f_fin,
        };
        // console.log("body", body);
        const respCombinadas = await CombinadasService.getCombinadas(body);
        if (respCombinadas.status) {
            if (respCombinadas.data[0] != null) {
                let tot = 0;
                setItems(respCombinadas.data[0]);
                for (let index = 0; index < respCombinadas.data[0].length; index++) {
                    const element = respCombinadas.data[0][index];
                    tot += parseFloat(element.monto_cambio);
                }
                setTotal(tot);
            }
        }
        // console.log("respCombinadas", respCombinadas);
        setLoading(false);
    };

    //AGREGAR CUENTA
    const addCombinada = () => {
        setIsOpe(true);
        selectData()
        $("#kt_datepicker_8").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            onChange: function (selectedDates, dateStr, instance) {
                setFechaM(moment(dateStr).format("yyyy-MM-DD"));
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        setValue("operador", 0);
        setValue("tipo", 1);
        setValue("tipomoneda", 1);
        setValue("fechaM", moment(today).format("yyyy-MM-DD"));
        setValue("monto", "");
        $("#modal_movs_add_combinada").modal("show");
    };

    //EDITAR CUENTA
    const editCombinada = (data) => {
        setIsOpe(false);
        let itemSelectE = {};
        itemSelectE = items[data.rowIndex];
        setValue("idcombinada", itemSelectE.id_combinada);
        setValue("operador", itemSelectE.id_operador);
        setValue("idcombinada", itemSelectE.id_combinada);
        setValue("tipo", itemSelectE.tipo);
        setValue("tipomoneda", itemSelectE.tipomoneda);
        if (itemSelectE.monto < 0) {
            setValue("monto", itemSelectE.monto * -1);
        } else {
            setValue("monto", itemSelectE.monto);
        }
        $("#modal_movs_add_combinada").modal("show");
    };

    //ELIMINAR
    const handleMyState = (data) => {
        let datosCB = {
            id: data.rowData[0],
        };
        //console.log("datosCB", datosCB);
        Swal.fire({
            icon: "error",
            title: `¿Seguro que desea eliminar la combinada?`,
            showCancelButton: true,
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                confirmChange(datosCB);
            }
        });
    };

    const onSubmit = async (data) => {
        // console.log("data", data);
        setLoading2(true);
        const datos = data;

        if (datos.operador == 0){
            Swal.fire({
                title: "Info!",
                icon: "info",
                text: "Seleccione un operador",
            });
            return;
        }

        const bodyAdd = {
            operador: datos.operador,
            tipo: datos.tipo,
            tipomoneda: parseInt(datos.tipomoneda),
            tcambio: user.tipocambio,
            monto: parseFloat(datos.monto),
            fecha: datos.fechaM,
            refreshToken: "Bearer " + refreshToken,
        };
        
        const bodyEdit = {
            idcombinada: datos.idcombinada,
            tipo: datos.tipo,
            tipomoneda: parseInt(datos.tipomoneda),
            tcambio: user.tipocambio,
            monto: parseFloat(datos.monto),
            refreshToken: "Bearer " + refreshToken,
        };

        if (isOpe) {
             const respAdd = await CombinadasService.addCombinadas(bodyAdd);
            $("#modal_movs_add_combinada").modal("hide");
            // console.log("respAdd", respAdd);
            if (respAdd.status == true) {
                Swal.fire({
                    title: "Registrado",
                    icon: "success",
                    text: "Datos registrados con éxito.",
                });
            } else if (respAdd.status == 202) {
                Swal.fire({
                    title: "Error!",
                    icon: "warning",
                    text: respAdd.data.message,
                });
            } else {
                Swal.fire({
                    title: "Error!",
                    icon: "error",
                    text: "Ocurrió un error al registrar los datos.",
                });
            }
        } else {
            const respEdit = await CombinadasService.updateCombinadas(bodyEdit);
            $("#modal_movs_add_combinada").modal("hide");
            if (respEdit.status) {
                Swal.fire({
                    title: "Actualizado!",
                    icon: "success",
                    text: "Datos actualizados con éxito.",
                });
            } else {
                Swal.fire({
                    title: "Error!",
                    icon: "error",
                    text: "Ocurrió un error al actualizar los datos.",
                });
            }
        }
       
        setLoading2(false);
        getData();

        // resetForm();
    };

    //CONFIRMACIÓN DE ELIMINACIÓN
    const confirmChange = async (data) => {
        const body = {
            idcombinada: data.id,
            refreshToken: "Bearer " + refreshToken,
        };
        // console.log("body", body);
        const respEstado = await CombinadasService.updateStatusCombinadas(body);
        if (respEstado.status) {
            Swal.fire("Actualizado!", "", "success");
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al actualizar los datos.",
            });
        }
        getData();
    };

    const montoValidate = (e) => {
        if (
            (e.keyCode < 48 || e.keyCode > 57) &&
            e.keyCode != 110 &&
            e.keyCode != 190 &&
            e.keyCode != 8 &&
            e.keyCode != 9 &&
            e.keyCode != 46 &&
            (e.keyCode < 96 || e.keyCode > 105)
        ) {
            e.preventDefault();
        }
    };

    const changeType = (val) => {
        setSelectType(parseInt(val));
    };
    const changeTypeCurrency = (val) => {
        setSelectTypeCurrency(parseInt(val));
    };

    //MOUNTED
    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        // selectData();
        $("#kt_datepicker_12").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        $("#kt_datepicker_13").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        $("#kt_datepicker_8").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            // minDate: "today",
            onChange: function (selectedDates, dateStr, instance) {
              setFechaM(moment(dateStr).format("yyyy-MM-DD"));
              setValue("fechaM", moment(dateStr).format("yyyy-MM-DD"));
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
          });
        // showData();
    }, []);

    return (
        <Main>
            <div className="container-xxl mw-100">
                <div className="toolbar" id="kt_toolbar">
                    <div className="w-100 px-2 d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                    <div className="overlay-wrapper">
                        <div className="card p-0 shadow">
                            <div className="card-header border-0 p-6">
                                <div className="card-toolbar w-100">
                                    <div className="row mx-0 w-100 h-100 justify-content-start align-items-end">
                                        <div className="col-12 col-sm-3">
                                            <div className="w-100">
                                                <label htmlFor="" className="form-label">
                                                    Inicio
                                                </label>
                                                <input className="form-control form-control-solid" placeholder="Fecha Inicio" id="kt_datepicker_12" 
                                                onInput={(e) => setFiltros({...filtros, f_inicio: moment(e.target.value).format("yyyy-MM-DD")})}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-3">
                                            <div className="w-100">
                                                <label htmlFor="" className="form-label">
                                                    Fin
                                                </label>
                                                <input className="form-control form-control-solid" placeholder="Fecha Fin" id="kt_datepicker_13" 
                                                onInput={(e) => setFiltros({ ...filtros, f_fin: moment(e.target.value).format("yyyy-MM-DD") })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="d-flex align-content-end justify-content-xl-end justify-content-center h-100  w-100">
                                                <button
                                                    disabled={loading}
                                                    type="button"
                                                    className="btn btn-primary mt-auto me-3"
                                                    onClick={getData}
                                                >
                                                    {loading ? (
                                                        <span className="indicator-label">
                                                            Buscando...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    ) : (
                                                        <span className="indicator-label ">
                                                            <i className="bi bi-search" /> Buscar
                                                        </span>
                                                    )}
                                                </button>
                                                <button type="button" className="btn btn-success mt-auto" onClick={addCombinada}>
                                                    <i className="bi bi-plus-circle fs-4 me-2" /> Agregar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <MUIDataTable
                                    title={
                                        <div className="d-flex gap-3 px-4">
                                            <span className="fw-bolder fs-14">Lista de Combinadas</span> |{" "}
                                            {<span className="fw-bolder">Total: {parseFloat(total).toFixed(2)}</span>}
                                        </div>
                                    }
                                    data={items}
                                    columns={columns1}
                                    options={options}
                                />
                            </div>
                        </div>
                        <div className="modal fade" id="modal_movs_add_combinada" tabIndex="-1" aria-modal="true" role="dialog">
                            <div className="modal-dialog modal-dialog-centered mw-650px">
                                <div className="modal-content">
                                <form
                                    className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                    id="modal_movs_add_mov_form"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <div className="modal-header" id="modal_movs_add_mov_header">
                                            <h2 className="fw-bold">{isOpe ? "Agregar" : "Editar"} Combinada</h2>
                                        <div
                                            id="modal_movs_add_mov_close"
                                            className="btn btn-icon btn-sm btn-active-icon-primary"
                                            data-bs-dismiss="modal"
                                            // onClick={resetForm}
                                        >
                                            <span className="svg-icon svg-icon-1">
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                opacity="0.5"
                                                x="6"
                                                y="17.3137"
                                                width="16"
                                                height="2"
                                                rx="1"
                                                transform="rotate(-45 6 17.3137)"
                                                fill="currentColor"
                                                ></rect>
                                                <rect
                                                x="7.41422"
                                                y="6"
                                                width="16"
                                                height="2"
                                                rx="1"
                                                transform="rotate(45 7.41422 6)"
                                                fill="currentColor"
                                                ></rect>
                                            </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="modal-body py-5 px-lg-10">
                                        <div
                                            id="modal_movs_add_mov_scroll"
                                            data-kt-scroll-dependencies="#modal_movs_add_mov_header"
                                            data-kt-scroll-wrappers="#modal_movs_add_mov_scroll"
                                        >
                                            <div className="row mx-0 w-100 mb-7 fv-plugins-icon-container">
                                                {isOpe ? (
                                                    <div className="col-12 mb-1">
                                                        <label htmlFor="operador" className="required">
                                                            Operador
                                                        </label>
                                                        <select
                                                            className="select2-selection select2-selection--single form-select form-select-solid"
                                                            name="operador"
                                                            id="operador"
                                                            {...register("operador")}
                                                            // onChange={(e) => changeOperador(e.target.value)}
                                                        >
                                                            <option value="0">Seleccionar</option>
                                                            {/*console.log(operadores)*/}
                                                            {operadores.map((per) => (
                                                                <option key={per.id_persona} value={per.id_persona}>
                                                                    {per.nom}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                                {isOpe ? (
                                                    <div className="col-12 mb-1">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="required">Fecha</span>
                                                        </label>
                                                        <input
                                                            className="form-control form-control-solid"
                                                            placeholder="Fecha"
                                                            id="kt_datepicker_8"
                                                            value={fechaM}
                                                            {...register("fechaM", {
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors.fechaM?.type === "required" && (
                                                            <p className="text-danger">El campo es requerido</p>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                                <div className="col-12 mb-1">
                                                    <label htmlFor="status" className="required">
                                                        Tipo
                                                    </label>
                                                    <select
                                                        className="select2-selection select2-selection--single form-select form-select-solid"
                                                        name="status"
                                                        id="status"
                                                        disabled={!isOpe}
                                                        {...register("tipo")}
                                                        onChange={(e) => changeType(e.target.value)}
                                                    >
                                                        {tipos.map((cuenta, index) => (
                                                            <option key={index} value={cuenta.value}>
                                                                {cuenta.text}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-12 mb-1">
                                                    <label htmlFor="money" className="required">
                                                        Tipo moneda
                                                    </label>
                                                    <select
                                                        className="select2-selection select2-selection--single form-select form-select-solid"
                                                        name="money"
                                                        id="money"
                                                        disabled={!isOpe}
                                                        {...register("tipomoneda")}
                                                        onChange={(e) => changeTypeCurrency(e.target.value)}
                                                    >
                                                        {moneda.map((money, index) => (
                                                            <option key={index} value={money.value}>
                                                                {money.text}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-12 mb-1">
                                                    <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                        <span className="required">Monto</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-solid"
                                                        placeholder="Monto"
                                                        name="monto"
                                                        onKeyDown={(e) => montoValidate(e)}
                                                        {...register("monto", {
                                                            required: true,
                                                            valueAsNumber: true, 
                                                            validate: (value, formValues) => parseFloat(value) > 0,
                                                        })}
                                                    />
                                                    {errors.monto?.type === "required" && (
                                                        <p className="text-danger">El campo es requerido</p>
                                                    )}
                                                    {errors.monto?.type === "validate" && (
                                                        <p className="text-danger">Debe ser mayor a 0</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer flex-center">
                                        <button
                                            type="reset"
                                            id="modal_movs_add_mov_cancel"
                                            className="btn btn-danger me-3"
                                            data-bs-dismiss="modal"
                                            // onClick={resetForm}
                                        >
                                            Cerrar
                                        </button>
                                        <button type="submit" id="modal_movs_add_mov_submit" className="btn btn-primary">
                                            {/*loading2 ? (
                                            <span className="indicator-label">
                                                Agregando....
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                            ) : (
                                            <span className="indicator-label">Agregar</span>
                                            )*/}
                                                <span className="indicator-label">{isOpe ? "Agregar" : "Editar"}</span>
                                        </button>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    )

}

export default Combinadas;