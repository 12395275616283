/* eslint-disable */

import UseService from "../UseServices";

const UtilidadesService = {
    async getMinifiUtilidades(data) {
        var resp = await UseService.post("utilidades/minified", data);
        return resp;
    },
    async getExtendUtilidades(data) {
        var resp = await UseService.post("utilidades/extended", data);
        return resp;
    },
};
export default UtilidadesService;