/* eslint-disable */

import UseService from "../UseServices";

const JugadasService = {
    async stateHome(id, data) {
        var resp = await UseService.put("apuestas/estadocasa/" + id, data);
        return resp;
    },
    async stateFinal(data) {
        var resp = await UseService.put("apuestas/estado", data);
        return resp;
    },
    async deleteBet(data) {
        var resp = await UseService.put("apuestas/ocultar", data);
        return resp;
    },
    async listCasas(data) {
        var resp = await UseService.post("constantes/listar", data);
        return resp;
    },
    async editApuesta(data) {
        var resp = await UseService.put("apuestas/actualizar", data);
        return resp;
    },

    //SUREBETS
    async changeWinner(data) {
        var resp = await UseService.put("surebets/ganador", data);
        return resp;
    },
    // async stateMiddle(id, data) {
    //     var resp = await UseService.put("surebets/middle/" + id, data);
    //     return resp;
    // },
    // async stateAntiMiddle(id, data) {
    //     var resp = await UseService.put("surebets/antimiddle/" + id, data);
    //     return resp;
    // },
    async stateHomeSureBet(data) {
        var resp = await UseService.put("surebets/estado", data);
        return resp;
    },
    async changeStateSurebet(data) {
        var resp = await UseService.put("surebets/ganador", data);
        return resp;
    },
    async deleteSureBet(data) {
        var resp = await UseService.put("surebets/ocultar", data);
        return resp;
    },
    // async stateGeneral(id, data) {
    //     var resp = await UseService.put("surebets/estadogeneral/" + id, data);
    //     return resp;
    // },
    // async closeSureBet(id, data) {
    //     var resp = await UseService.put("surebets/cerrar/" + id, data);
    //     return resp;
    // },
    // async pushSureBet(id, data) {
    //     var resp = await UseService.put("surebets/push/" + id, data);
    //     return resp;
    // },
    async editSurebet(data) {
        var resp = await UseService.put("surebets/actualizar", data);
        return resp;
    },
};

export default JugadasService;
