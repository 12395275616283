/* eslint-disable */
import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom'
import PortalReactDOM from 'react-dom'
import { render } from "@testing-library/react";
import { forwardRef } from "react";

const Ventana = (props) => {
  const containerEl = document.createElement('div');
  //COPIAR ESTILOS
  function copyStyles(sourceDoc, targetDoc) {
    Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
      if (styleSheet.cssRules) { // true for inline styles
        const newStyleEl = sourceDoc.createElement('style');
        // document.styleSheets[elem].cssRules
        Array.from(styleSheet.cssRules).forEach(cssRule => {
          newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
        });

        targetDoc.head.appendChild(newStyleEl);
      } else if (styleSheet.href) { // true for stylesheets loaded from a URL
        const newLinkEl = sourceDoc.createElement('link');

        newLinkEl.rel = 'stylesheet';
        newLinkEl.href = styleSheet.href;
        targetDoc.head.appendChild(newLinkEl);
      }
    });
  }
  const openView = (props) => {
    var win = window.open('/calculator?modal=A' + props.trigger + '&type=' + props.type + '&tc=' + props.tc + '&userTC=' + props.userTc + '&stake=' + props.stake + '&data=' + JSON.stringify(props.datos), '_blank', 'PopUp' + props.trigger + ', width=800,height=270,left=200,top=200');
    if (win) {
      //console.log('win', win)
      win.document.body.appendChild(containerEl);
      win.document.title = 'Calculadora | CCA';
      copyStyles(document, win.document);
      // win.addEventListener('beforeunload', () => {
      //   props.closeWindowPortal();
      // });
    }
  }
  useEffect(() => {
    if (props.trigger) {
      //console.log('ssadas')
      openView(props);
    }
  }, [props.trigger]);
  // ReactDOM.createPortal(props.children, containerEl)
  return (
    ReactDOM.createPortal(props.children, containerEl)

  )

}

export default Ventana

// render {
  //   return ReactDOM.createPortal(props.children, containerEl);
  // }