/* eslint-disable */
import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import MovimientosService from "../../../../api/services/operacionService";
import { useForm, Controller } from "react-hook-form";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import moment from "moment/moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useUserContext } from "../../../../Providers/UserProvider";
import "styled-components";
import "../../operaciones.scss";
import Main from "../../../../layout/main";
// import "react-datalist-input/dist/styles.css";

//data estatica
const today = new Date();
const tooltipE = (
    <Tooltip id="tooltipE">
        <strong>Editar</strong>
    </Tooltip>
);
const tooltipD = (
    <Tooltip id="tooltipD">
        <strong>Eliminar</strong>
    </Tooltip>
);
//DATA ESTATICA

const tipos = [
    {
        label: "Todos",
        value: "todos",
    },
    {
        label: "Recarga",
        value: "recarga",
    },
    {
        label: "Retiro",
        value: "retiro",
    },
];
const tiposA = [
    {
        label: "Recarga",
        value: "recarga",
    },
    {
        label: "Retiro",
        value: "retiro",
    },
];
const CajaMovimientos = () => {
    //USE FORM
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        reset,
        formState: { errors },
    } = useForm();

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        reset: reset2,
        setValue: setValue2,
        formState: { errors: errors2 },
    } = useForm();
    //COLUMNAS
    const columns1 = [
        {
            name: "id_movimiento",
            label: "",
            options: {
                display: false,
            },
        },
        {
            name: "titular",
            label: "TITULAR",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "banco",
            label: "banco",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "tipomoneda",
            label: "Moneda",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span>{value == 1 ? 'Dolares': 'Moneda Local'}</span>;
                },
            },
        },
        {
            name: "casa",
            label: "CASA",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "tipo",
            label: "Tipo",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "fecha",
            label: "FECHA",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span>{moment(value).format("DD-MM-yyyy")}</span>;
                },
            },
        },
        {
            name: "monto",
            label: "DOLARES / MONEDA LOCAL",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span>{parseFloat(value).toFixed(2)}</span>;
                },
            },
        },
        {
            name: "cambio_monto",
            label: "CONVERTIDO (DOLARES)",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span>{parseFloat(value).toFixed(2)}</span>;
                },
            },
        },
        {
            name: "observacion",
            label: "OBSERVACION",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "tipo",
            label: "ACCIONES",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    console.log(tableMeta);
                    return (value === 'RETIRO' || user.frqpoint == 0)?(
                        <div className="d-flex gap-3 flex-wrap justify-content-center">
                            <OverlayTrigger placement="top" overlay={tooltipE}>
                                <button className="btn btn-success btn-sm btn-icon" onClick={() => editCuenta(tableMeta)}>
                                    <FaPencilAlt></FaPencilAlt>
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={tooltipD}>
                                <button className="btn btn-danger btn-sm btn-icon" onClick={() => handleMyDelete(tableMeta)}>
                                    <FaTrash></FaTrash>
                                </button>
                            </OverlayTrigger>
                        </div>
                    ):(<></>);
                },
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
    ];
    //Configurando hooks
    const { user } = useUserContext();
    const [movimientos, setMovimientos] = useState([]);
    const [cuentasUsuario, setcuentasUsuario] = useState([]);
    const [fecha, setFecha] = useState(moment(today).format("yyyy-MM-DD"));
    const [fechaM, setFechaM] = useState(moment(today).format("yyyy-MM-DD"));
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0)
    const [filtros, setFiltros] = useState({
        fechainicio: moment(today).format("yyyy-MM-DD"),
        fechafin: moment(today).format("yyyy-MM-DD"),
        tipo: "todos",
    });
    
    //DATOS
    const refreshToken = localStorage.getItem("refreshToken");
    //TRAER DATA
    const selectData = async (fechita = fechaM)=>{
        const body1 = {
            fecha: moment(fechita).format("yyyy-MM-DD"),
            refreshToken: "Bearer " + refreshToken,
        };
        // console.log(body1)
        const respInitial = await MovimientosService.getMovSelectIngreso(body1);
        if (respInitial.status) {
            setcuentasUsuario(respInitial.data[0]);
        }
    }
    const showData = async () => {
        setLoading(true);
        const body = {
            fechainicio: filtros.fechainicio,
            fechafin: filtros.fechafin,
            tipo: filtros.tipo,
            tcambio: user.tipocambio,
            refreshToken: "Bearer " + refreshToken,
        };
        // console.log('body',body)
        const respMovs = await MovimientosService.getMovs(body);
        
        // console.log('respMovs movss',respMovs)
        if (respMovs.status) {
            let tot = 0
            setMovimientos(respMovs.data[0]);
            for (let index = 0; index < respMovs.data[0].length; index++) {
                const element = respMovs.data[0][index];
                tot += parseFloat(element.cambio_monto)
            }
            setTotal(tot)
        }
        
        resetForm();
        setLoading(false);
    };

    //MOUNTED
    useEffect(() => {
        $("#kt_datepicker_9").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        $("#kt_datepicker_10").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        $("#kt_datepicker_22").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
    }, []);

    useEffect(() => {
        selectData();
        // showData();
    }, []);

    //OPTIONS DEL DATATABLE
    const options = {
        print: false,
        viewColumns: false,
        filter: false,
        filterType: "dropdown",
        selectableRows: "none",
        responsive: "standard",
        fixedSelectColumn: false,
        textLabels: {
            body: {
                noMatch: "No se encontraron datos",
                toolTip: "Sort",
                columnHeaderTooltip: (column) => `Ordenador por ${column.label}`,
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Elementos por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscador",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtrar Tabla",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Resetear",
            },
            viewColumns: {
                title: "Mostrar Columnas",
                titleAria: "Mostrar/Ocultar Columnas",
            },
            selectedRows: {
                text: "fila(s) seleccionada(s)",
                delete: "Eliminar",
                deleteAria: "Eliminar fila seleccionada",
            },
            centeredTableHead: {
                "& > span": {
                    justifyContent: "center",
                },
            },
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <div>
                <FaTrash className="mx-4 cursor-pointer" onClick={() => handleMyDelete(selectedRows)} />
            </div>
        ),
    };

    //ELIMINAR
    const handleMyDelete = (data) => {
        let itemSelectE = {};
        itemSelectE = movimientos[data.rowIndex];
        Swal.fire({
            icon: "error",
            title: "¿Seguro que desea eliminar el movimiento?",
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                confirmDelete(itemSelectE);
            }
        });
    };

    //CONFIRMACIÓN DE ELIMINACIÓN
    const confirmDelete = async (data) => {
        setLoading(true);
        const body = {
            idmovimiento: data.id_movimiento,
            refreshToken: "Bearer " + refreshToken,
        };
        const respEstado = await MovimientosService.deleteMovs(body);
        if (respEstado.status) {
            Swal.fire({
                title: "Eliminado!",
                icon: "success",
                text: "El movimiento ha salido eliminado con éxito.",
            });
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al actualizar los datos.",
            });
        }
        showData();
        setLoading(false);
    };

    //EDITAR CUENTA
    const editCuenta = (data) => {
        let itemSelectE = {};
        itemSelectE = movimientos[data.rowIndex];
        setValue2("id_movimiento", itemSelectE.id_movimiento);
        if (itemSelectE.monto < 0) {
            setValue2("montoM", itemSelectE.monto * -1);
        } else {
            setValue2("montoM", itemSelectE.monto);
        }
        setValue2("tipomoneda", itemSelectE.tipomoneda);
        setValue2("observacion", itemSelectE.observacion);
        $("#modal_movs_edit_mov").modal("show");
    };

    //AGREGAR CUENTA
    const addCuenta = () => {
        $("#kt_datepicker_22").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            // minDate: "today",
            onChange: function (selectedDates, dateStr, instance) {
                setFechaM(moment(dateStr).format("yyyy-MM-DD"));
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        setValue("fechaM", moment(today).format("yyyy-MM-DD"));
        setValue("casaCuenta", "");
        setValue("monto", "");
        setValue("observacion", "");
        $("#modal_movs_add_mov").modal("show");
    };
    const changeCuenta = (e) => {
        if (e != null && e != "") {
            const filter = cuentasUsuario.filter((el) => el.id_casacuenta == parseInt(e));
            setValue("tipomoneda",filter[0].tipo)
        }
    };

    //SETEAR BANCO Y CASAS SELECCIONADOS
    // const selectType = (e) => {
    //     setFiltros({
    //         ...filtros,
    //         tipo: e,
    //     });
    // };
    const resetForm = () => {
        reset({
            fechaM: moment(today).format("yyyy-MM-DD"),
            casaCuenta: "",
            monto: "",
            observacion: "",
        });
    };
    const resetForm2 = () => {
        reset2({
            montoM: "",
            observacion: "",
        });
    };
    const montoValidate = (e) => {
        if (
            (e.keyCode < 48 || e.keyCode > 57) &&
            e.keyCode != 110 &&
            e.keyCode != 190 &&
            e.keyCode != 8 &&
            e.keyCode != 9 &&
            e.keyCode != 46 &&
            (e.keyCode < 96 || e.keyCode > 105)
        ) {
            e.preventDefault();
        }
    };
    //ENVIAR DATA
    const onSubmit = async (data) => {
        setLoading(true);
        const datos = data;

        $("#modal_movs_add_mov").modal("hide");
        const bodyAdd = {
            idcasacuenta: parseInt(datos.casaCuenta),
            monto: parseFloat(datos.monto),
            fecha: datos.fechaM,
            tipo: user.frqpoint == 0 ? datos.tipo : "retiro",
            observacion: datos.observacion,
            tipomoneda: parseInt(data.tipomoneda),
            tcambio: user.tipocambio,
            refreshToken: "Bearer " + refreshToken,
        };
        const respAdd = await MovimientosService.AddMovs(bodyAdd);
        if (respAdd.status == true) {
            Swal.fire({
                title: "Registrado",
                icon: "success",
                text: "Datos registrados con éxito.",
            });
        } else if (respAdd.status == 202) {
            Swal.fire({
                title: "Error!",
                icon: "warning",
                text: respAdd.data.message,
            });
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al registrar los datos.",
            });
        }
        showData();
        setLoading(false);
        // resetForm();
    };
    const onSubmit2 = async (data) => {
        setLoading(true);
        const datos = data;
        $("#modal_movs_edit_mov").modal("hide");
        const bodyUpd = {
            idmovimiento: parseInt(datos.id_movimiento),
            monto: parseFloat(datos.montoM),
            tipomoneda: parseInt(data.tipomoneda),
            tcambio: user.tipocambio,
            observacion: datos.observacion,
            refreshToken: "Bearer " + refreshToken,
        };
        const respAdd = await MovimientosService.updateMovs(bodyUpd);
        if (respAdd.status == true) {
            Swal.fire({
                title: "Actualizado!",
                icon: "success",
                text: "Datos actualizados con éxito.",
            });
        } else if (respAdd.status == 202) {
            Swal.fire({
                title: "Error!",
                icon: "warning",
                text: respAdd.data.message,
            });
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al actualizar los datos.",
            });
        }
        showData();
        setLoading(false);
        // resetForm();
    };

    return (
        <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
            <div className="overlay-wrapper">
                <div className="card">
                    <div className="card-header border-0 pt-6 h-100">
                        <div className="card-toolbar w-100 h-100">
                            <div className="row mx-0 w-100 h-100 justify-content-center align-items-end">
                                <div className="col-md-2 h-100">
                                    <label htmlFor="" className="form-label">
                                        Fecha Inicio
                                    </label>
                                    <input className="form-control form-control-solid" placeholder="Fecha Inicio" id="kt_datepicker_9" 
                                        onInput={(e) => setFiltros({ ...filtros, fechainicio: moment(e.target.value).format("yyyy-MM-DD") })}
                                    />
                                </div>
                                <div className="col-md-2 h-100">
                                    <label htmlFor="" className="form-label">
                                        Fecha Final
                                    </label>
                                    <input className="form-control form-control-solid" placeholder="Fecha Final" id="kt_datepicker_10" 
                                        onInput={(e) => setFiltros({ ...filtros, fechafin: moment(e.target.value).format("yyyy-MM-DD") })}/>
                                </div>
                                <div className="col-md-2 h-100">
                                    <label htmlFor="" className="form-label">
                                        Tipo
                                    </label>
                                    <select
                                        className="select2-selection select2-selection--single form-select form-select-solid"
                                        name="filtrotipo"
                                        value={filtros.tipo}
                                        onChange={(e) => setFiltros({ ...filtros, tipo: e.target.value })}
                                    >
                                        {tipos.map((tipo) => (
                                            <option key={tipo.value} value={tipo.value}>
                                                {tipo.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-6 h-100 mt-5 mt-md-0">
                                    <div className="d-flex justify-content-center mt-5 mt-md-0 justify-content-md-start align-content-end h-100">
                                        <button type="button" className="btn btn-primary mt-auto me-3" onClick={showData}>
                                            <i className="bi bi-search" /> Buscar
                                        </button>
                                        <button type="button" className="btn btn-primary mt-auto" onClick={addCuenta}>
                                        <i className="bi bi-plus-circle fs-4 me-2" /> Movimiento
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0">
                        <MUIDataTable
                            title={<div className="d-flex gap-3 px-4"><span className="fw-bolder fs-14">Lista de Movimientos</span> | <span className="fw-bolder">Total: {parseFloat(total).toFixed(2)}</span></div>}
                            data={movimientos}
                            columns={columns1}
                            options={options}
                        />
                    </div>
                </div>
                <div className="modal fade" id="modal_movs_add_mov" tabIndex="-1" aria-modal="true" role="dialog">
                    <div className="modal-dialog modal-dialog-centered mw-650px">
                        <div className="modal-content">
                            <form
                                className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                id="modal_movs_add_mov_form"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <div className="modal-header" id="modal_movs_add_mov_header">
                                    <h2 className="fw-bold">Agregar retiro</h2>
                                    <div
                                        id="modal_movs_add_mov_close"
                                        className="btn btn-icon btn-sm btn-active-icon-primary"
                                        data-bs-dismiss="modal"
                                        onClick={resetForm}
                                    >
                                        <span className="svg-icon svg-icon-1">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect
                                                    opacity="0.5"
                                                    x="6"
                                                    y="17.3137"
                                                    width="16"
                                                    height="2"
                                                    rx="1"
                                                    transform="rotate(-45 6 17.3137)"
                                                    fill="currentColor"
                                                ></rect>
                                                <rect
                                                    x="7.41422"
                                                    y="6"
                                                    width="16"
                                                    height="2"
                                                    rx="1"
                                                    transform="rotate(45 7.41422 6)"
                                                    fill="currentColor"
                                                ></rect>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                <div className="modal-body py-5 px-lg-10">
                                    <div
                                        id="modal_movs_add_mov_scroll"
                                        data-kt-scroll-dependencies="#modal_movs_add_mov_header"
                                        data-kt-scroll-wrappers="#modal_movs_add_mov_scroll"
                                    >
                                        <div className="fv-row mb-7 fv-plugins-icon-container">
                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                <span className="">Fecha</span>
                                            </label>
                                            <input
                                                className="form-control form-control-solid"
                                                placeholder="Fecha"
                                                id="kt_datepicker_22"
                                                value={fechaM}
                                                {...register("fechaM", {
                                                    required: true,
                                                })}
                                                onInput={(e) => {
                                                    setFechaM(moment(e.target.value).format("yyyy-MM-DD"));
                                                    setValue("fechaM", moment(e.target.value).format("yyyy-MM-DD"));
                                                    selectData(e.target.value);
                                                }}
                                            />
                                            {errors.fechaM?.type === "required" && <p className="text-danger">El campo es requerido</p>}

                                            {user.frqpoint == 0 ? (
                                                <>
                                                    <label htmlFor="" className="form-label">
                                                        Tipo
                                                    </label>
                                                    <select
                                                        className="select2-selection select2-selection--single form-select form-select-solid"
                                                        name="filtrotipo"
                                                        {...register("tipo", {
                                                            required: true,
                                                        })}
                                                    >
                                                        {tiposA.map((tipo) => (
                                                            <option key={tipo.value} value={tipo.value}>
                                                                {tipo.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.tipo?.type === "required" && (
                                                        <p className="text-danger">El campo es requerido</p>
                                                    )}
                                                </>
                                            ) : (
                                                <></>
                                            )}


                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                <span className="">Casa-Cuenta</span>
                                            </label>
                                            <select
                                                className="select2-selection select2-selection--single form-select form-select-solid"
                                                name="casa-cuenta"
                                                {...register("casaCuenta", {
                                                    required: true,
                                                })}
                                                onChange={(e)=> changeCuenta(e.target.value)}
                                            >
                                                <option value="">Seleccionar</option>
                                                {cuentasUsuario.length > 0 && cuentasUsuario.map((estado) => (
                                                    <option key={estado.id_casacuenta} value={estado.id_casacuenta}>
                                                        {estado.titular}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.casaCuenta?.type === "required" && (
                                                <p className="text-danger">El campo es requerido</p>
                                            )}

                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                <span className="required">Monto</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control form-control-solid"
                                                placeholder="Monto"
                                                name="monto"
                                                onKeyDown={(e) => montoValidate(e)}
                                                {...register("monto", {
                                                    required: true,
                                                    valueAsNumber: true,
                                                    validate: (value, formValues) => parseFloat(value) > 0,
                                                })}
                                            />
                                            {errors.monto?.type === "required" && <p className="text-danger">El campo es requerido</p>}
                                            {errors.monto?.type === "validate" && <p className="text-danger">Debe ser mayor a 0</p>}
                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                <span className="">Observacion</span>
                                            </label>
                                            <textarea
                                                type="text"
                                                className="form-control form-control-solid"
                                                placeholder="Observacion"
                                                name="observacion"
                                                rows={4}
                                                {...register("observacion")}
                                            />
                                            {errors.observacion?.type === "required" && (
                                                <p className="text-danger">El campo es requerido</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer flex-center">
                                    <button
                                        type="reset"
                                        id="modal_movs_add_mov_cancel"
                                        className="btn btn-danger me-3"
                                        data-bs-dismiss="modal"
                                        onClick={resetForm}
                                    >
                                        Cerrar
                                    </button>
                                    <button type="submit" id="modal_movs_add_mov_submit" className="btn btn-primary">
                                        <span className="indicator-label">Agregar</span>
                                        <span className="indicator-progress">
                                            Cargando....
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="modal_movs_edit_mov" tabIndex="-1" aria-modal="true" role="dialog">
                    <div className="modal-dialog modal-dialog-centered mw-650px">
                        <div className="modal-content">
                            <form
                                className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                id="modal_movs_edit_mov_form"
                                onSubmit={handleSubmit2(onSubmit2)}
                            >
                                <div className="modal-header" id="modal_movs_edit_mov_header">
                                    <h2 className="fw-bold">Editar Movimiento</h2>
                                    <div
                                        id="modal_movs_edit_mov_close"
                                        className="btn btn-icon btn-sm btn-active-icon-primary"
                                        data-bs-dismiss="modal"
                                        onClick={resetForm2}
                                    >
                                        <span className="svg-icon svg-icon-1">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect
                                                    opacity="0.5"
                                                    x="6"
                                                    y="17.3137"
                                                    width="16"
                                                    height="2"
                                                    rx="1"
                                                    transform="rotate(-45 6 17.3137)"
                                                    fill="currentColor"
                                                ></rect>
                                                <rect
                                                    x="7.41422"
                                                    y="6"
                                                    width="16"
                                                    height="2"
                                                    rx="1"
                                                    transform="rotate(45 7.41422 6)"
                                                    fill="currentColor"
                                                ></rect>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                <div className="modal-body py-5 px-lg-10">
                                    <div
                                        id="modal_movs_edit_mov_scroll"
                                        data-kt-scroll-dependencies="#modal_movs_edit_mov_header"
                                        data-kt-scroll-wrappers="#modal_movs_edit_mov_scroll"
                                    >
                                        <div className="fv-row mb-7 fv-plugins-icon-container">
                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                <span className="required">Monto</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control form-control-solid"
                                                placeholder="Monto"
                                                name="montoM"
                                                id="montoM"
                                                onKeyDown={(e) => montoValidate(e)}
                                                {...register2("montoM", {
                                                    required: true,
                                                    valueAsNumber: true,
                                                    validate: (value, formValues) => parseFloat(value) > 0,
                                                })}
                                            />
                                            {errors.montoM?.type === "required" && <p className="text-danger">El campo es requerido</p>}
                                            {errors2.montoM?.type === "validate" && <p className="text-danger">Debe ser mayor a 0</p>}
                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                <span className="required">Observacion</span>
                                            </label>
                                            <textarea
                                                type="text"
                                                className="form-control form-control-solid"
                                                placeholder="Observacion"
                                                name="observacion"
                                                rows={4}
                                                {...register2("observacion")}
                                            />
                                            {errors.observacion?.type === "required" && (
                                                <p className="text-danger">El campo es requerido</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer flex-center">
                                    <button
                                        type="reset"
                                        id="modal_movs_edit_mov_cancel"
                                        className="btn btn-danger me-3"
                                        data-bs-dismiss="modal"
                                        onClick={resetForm2}
                                    >
                                        Cerrar
                                    </button>
                                    <button type="submit" id="modal_movs_edit_mov_submit" className="btn btn-primary">
                                        <span className="indicator-label">Editar</span>
                                        <span className="indicator-progress">
                                            Cargando....
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {loading ? (
                <div className="overlay-layer card-rounded bg-black bg-opacity-50">
                    <div className="spinner-border text-primary" role="status"></div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default CajaMovimientos;
