/* eslint-disable */
import React, { useState, useEffect } from "react";
import Main from "../../layout/main";
import Breadcrumbs from "../../Components/utils/BreadCrumb";
import MUIDataTable from "mui-datatables";
import CajaCuentaService from "../../api/services/cajacuentaService";
import { useForm, Controller } from "react-hook-form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaEye, FaTrash } from "react-icons/fa";
import { useUserContext } from "../../Providers/UserProvider";
import ConsolidadoService from "../../api/services/consolidadoService";
import Select from "react-select";
import moment from "moment/moment";
//TOOLTIPS
const tooltipE = (
  <Tooltip id="tooltipE">
    <strong>Preview</strong>
  </Tooltip>
);
const tooltipP = (
  <Tooltip id="tooltipP">
    <strong>Eliminar</strong>
  </Tooltip>
);
/* DATA ESTATICA */
const today = new Date();
const tipos = [
  {
    text: "Inicio de Mes",
    value: 0,
  },
  {
    text: "Ingreso",
    value: 1,
  },
  {
    text: "Egreso",
    value: 2,
  },
];
const motivos = [
  {
    text: "ADM",
    value: "ADM",
  },
  {
    text: "Comisión",
    value: "comision",
  },
  {
    text: "Devolución",
    value: "devolucion",
  },
  {
    text: "Falta de Capital",
    value: "falta de capital",
  },
  {
    text: "Llego Hoy",
    value: "llego hoy",
  },
  {
    text: "Retención Banco",
    value: "retencion banco",
  },
  {
    text: "Sobre Capital",
    value: "sobrecapital",
  },
  {
    text: "Otros",
    value: 0,
  },
];
/* FIN */
const Consolidado = () => {
  /* -----USEFORM----*/
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    setValue: setValue2,
    formState: { errors: errors2 },
  } = useForm();

  /* -----FIN USEFORM----*/

  /* -----BREADCRUMBS----*/
  const crumbs = [
    { path: "/perfil", name: "Home", active: true },
    { path: "/consolidado", name: "Capital Global", active: true },
  ];
  /* -----FIN BREADCRUMBS----*/

  /* -----MUIDATATABLE----*/
  //COLUMNAS
  const columns1 = [
    {
      name: "fecha",
      label: "Fecha",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
        customBodyRender: (value) => {
          return <span>{moment(value).format("DD-MM-yyyy")}</span>;
        },
      },
    },
    {
      name: "cnooperable",
      label: "No Operable",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
        customBodyRender: (value) => {
          return <span>$ {parseFloat(value).toFixed(2)}</span>;
        },
      },
    },
    {
      name: "coperable",
      label: "Operable",
      options: {
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
        customBodyRender: (value) => {
          return <span>$ {parseFloat(value).toFixed(2)}</span>;
        },
      },
    },
    {
      name: "porllegar",
      label: "Por Llegar",
      options: {
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
        customBodyRender: (value) => {
          return <span>$ {parseFloat(value).toFixed(2)}</span>;
        },
      },
    },
    {
      name: "total",
      label: "Total",
      options: {
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
        customBodyRender: (value) => {
          return <span>$ {parseFloat(value).toFixed(2)}</span>;
        },
      },
    },
    {
      name: "produccion",
      label: "Producción",
      options: {
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
        customBodyRender: (value) => {
          return <span>$ {parseFloat(value).toFixed(2)}</span>;
        },
      },
    },

  ];
  const options = {
    print: false,
    viewColumns: false,
    filter: false,
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    fixedSelectColumn: false,
    download: true,
    textLabels: {
      body: {
        noMatch: "No se encontraron datos",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenador por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Elementos por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscador",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Columnas",
        filterTable: "Filtrar Tabla",
      },
      filter: {
        all: "Todos",
        title: "Filtros",
        reset: "Resetear",
      },
      viewColumns: {
        title: "Mostrar Columnas",
        titleAria: "Mostrar/Ocultar Columnas",
      },
      selectedRows: {
        text: "fila(s) seleccionada(s)",
        delete: "Eliminar",
        deleteAria: "Eliminar fila seleccionada",
      },
      centeredTableHead: {
        "& > span": {
          justifyContent: "center",
        },
      },
    },
  };

  /* -----FIN MUIDATATABLE----*/

  /* -----HOOKS----*/
  const { user } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [cuentasBancarias, setCuentasBancarias] = useState([]);
  const [cuentasUsuario, setCuentasUsuario] = useState([]);
  const [operadores, setOperadores] = useState([]);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0)
  const [filtros, setFiltros] = useState({
    fecha: moment(today).format("yyyy-MM-DD"),
    mes: moment(today).format("MM"),
    anio: moment(today).format("yyyy"),
  });
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [fecha, setFecha] = useState(moment(today).format("yyyy-MM-DD"));
  const [fechaM, setFechaM] = useState(moment(today).format("yyyy-MM-DD"));
  /* -----FIN HOOKS----*/

  /* -----DATOS----*/
  const refreshToken = localStorage.getItem("refreshToken");
  /* -----FIN DATOS----*/

  /* -----METODOS----*/
  //LISTAR DATA
  const getData = async () => {
    setLoading(true);
    const body = {
      refreshToken: "Bearer " + refreshToken,
      fecha: filtros.fecha,
    };
    // console.log('body', body)
    const respConso = await ConsolidadoService.getConsolidados(body);
    if (respConso.status) {
      let tot = 0
      setItems(respConso.data[0]);
      for (let index = 0; index < respConso.data[0].length; index++) {
        const element = respConso.data[0][index];
        tot += parseFloat(element.produccion)
      }
      setTotal(tot)
    }
    // console.log("respConso", respConso);
    setLoading(false);
  };
  //EDITAR DATA
  //CAMBIAR ESTADO
  const handleMyState = (data) => {
    // console.log("data", data);
    let datosMov = {
      idcuadrecuentas: data.rowData[0],
    };
    // console.log("datosMov", datosMov);
    Swal.fire({
      icon: "warning",
      title: `¿Seguro que desea eliminar esta caja cuenta?`,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        confirmChange(datosMov);
      }
    });
  };
  //CONFIRMACIÓN DE ELIMINACIÓN
  const confirmChange = async (data) => {
    const body = {
      idcuadrecuentas: data.idcuadrecuentas,
      refreshToken: "Bearer " + refreshToken,
    };
    //console.log("body", body);
    const resp = await CajaCuentaService.statusCajaCuenta(body);
    // console.log("resp", resp);
    if (resp.status) {
      Swal.fire("Eliminado!", "", "success");
    } else {
      Swal.fire({
        title: "Error!",
        icon: "error",
        text: "Ocurrió un error al actualizar los datos.",
      });
    }
    getData();
  };
  //EDITAR CUENTA
  const preview = async () => {
    const body = {
      refreshToken: "Bearer " + refreshToken,
      fecha: filtros.fecha,
      tcambio: user.tipocambio
    };
    // console.log('body', body)
    const respConso = await ConsolidadoService.previewConsolidado(body);
    // console.log('respConso',respConso)
    if (respConso.status) {
      setValue2("fecha", respConso.data[0][0].fecha)
      setValue2("operable", respConso.data[0][0].operable)
      setValue2("nooperable", respConso.data[0][0].nooperable)
      setValue2("porllegar", respConso.data[0][0].porllegar)
      setValue2("total", respConso.data[0][0].total)
      setValue2("produccion", respConso.data[0][0].produccion)
    }
    $("#modal_global").modal("show");
  };
  //AGREGAR CUENTA
  const addConsolidado = () => {
    $("#kt_datepicker_8").flatpickr({
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      // minDate: "today",
      onChange: function (selectedDates, dateStr, instance) {
        setFechaM(moment(dateStr).format("yyyy-MM-DD"));
      },
      defaultDate: moment(today).format("yyyy-MM-DD"),
    });
    setValue("fechaM", moment(today).format("yyyy-MM-DD"));
    setValue("monto", "");
    $("#modal_capital_global").modal("show");
  };
  //RESETEAR FORMS
  const resetForm = () => {
    reset({
      fechaM: moment(today).format("yyyy-MM-DD"),
      idoperador: "",
      idcuentabancaria: "",
      idcasaapuesta: "",
      monto: "",
      tipo: ""
    });
  };
  const resetForm2 = () => {
    reset2({
      fecha: moment(today).format("yyyy-MM-DD"),
      operable: "",
      nooperable: "",
      porllegar: "",
      total: "",
      produccion: ""
    });
  };
  const montoValidate = (e) => {
    if (
      (e.keyCode < 48 || e.keyCode > 57) &&
            e.keyCode != 110 &&
            e.keyCode != 190 &&
            e.keyCode != 8 &&
            e.keyCode != 9 &&
            e.keyCode != 46 &&
            (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  };
  //ENVIAR DATA
  const onSubmit = async (data) => {
    // console.log('data', data)
    // console.log('show', show)
    setLoading2(true);
    const datos = data;
    const bodyAdd = {
      monto: parseFloat(datos.monto),
      fecha: datos.fechaM,
      refreshToken: "Bearer " + refreshToken,
    };
    // console.log('bodyAdd', bodyAdd)
    const respAdd = await ConsolidadoService.addInicioConsolidado(bodyAdd);
    $("#modal_capital_global").modal("hide");
    // console.log("respAdd", respAdd);
    if (respAdd.status == true) {
      Swal.fire({
        title: "Registrado",
        icon: "success",
        text: "Datos registrados con éxito.",
      });
    } else if (respAdd.status == 202) {
      Swal.fire({
        title: "Error!",
        icon: "warning",
        text: "¡El registro existe previamente o datos incorrectos!",
      });
    } else {
      Swal.fire({
        title: "Error!",
        icon: "error",
        text: "Ocurrió un error al registrar los datos.",
      });
    }
    setLoading2(false);
    getData();


    // resetForm();
  };
  const onSubmit2 = async (data) => {
    // console.log('data',data)
    setLoading3(true);
    let datos = data;
    datos.refreshToken = "Bearer " + refreshToken
    // console.log('datos', datos)
    const respAdd = await ConsolidadoService.addConsolidado(datos);
    $("#modal_global").modal("hide");
    if (respAdd.status == true) {
      Swal.fire({
        title: "Agregado!",
        icon: "success",
        text: "Datos actualizados con éxito.",
      });
    } else {
      Swal.fire({
        title: "Error!",
        icon: "error",
        text: "Ocurrió un error al actualizar los datos.",
      });
    }
    getData();
    setLoading3(false);
    // resetForm();
  };
  const changeType = (val) => {
    // console.log('val', val)
    if (parseInt(val) == 0) {
      setShow(true)
    } else {
      setShow(false)
    }
  }
  const changeType2 = (val) => {
    // console.log('val', val)
    if (parseInt(val) == 0) {
      setShow2(true)
    } else {
      setShow2(false)
    }
  }

  /* -----FIN METODOS----*/

  /* -----MOUNTED----*/
  useEffect(() => {
    // getData();
    resetForm();
  }, []);
  useEffect(() => {
    $("#kt_datepicker_18").flatpickr({
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      onChange: function (selectedDates, dateStr, instance) {
        setFiltros({ ...filtros, fecha: moment(dateStr).format("yyyy-MM-DD"), mes: moment(dateStr).format("MM"), anio: moment(dateStr).format("yyyy") });
      },
      defaultDate: moment(today).format("yyyy-MM-DD"),
    });
    $("#kt_datepicker_8").flatpickr({
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      // minDate: "today",
      onChange: function (selectedDates, dateStr, instance) {
        setFechaM(moment(dateStr).format("yyyy-MM-DD"));
        setValue("fechaM", moment(dateStr).format("yyyy-MM-DD"));
      },
      defaultDate: moment(today).format("yyyy-MM-DD"),
    });
    $("#kt_datepicker_9").flatpickr({
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      // minDate: "today",
      onChange: function (selectedDates, dateStr, instance) {
        setValue2("fecha", moment(dateStr).format("yyyy-MM-DD"));
      },
      defaultDate: moment(today).format("yyyy-MM-DD"),
    });
  }, []);

  /* -----FIN MOUNTED----*/
  return (
    <div className="container-xxl mw-100 px-0">
        <div className="toolbar" id="kt_toolbar">
          <div className="w-100 px-2 d-flex flex-stack flex-wrap flex-sm-nowrap">
            <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
          </div>
        </div>
        <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
          <div className="overlay-wrapper">
            <div className="card p-0 shadow">
              <div className="card-header border-0 p-6">
                <div className="card-toolbar w-100">
                  <div className="d-flex flex-column flex-lg-row  w-100 justify-content-between h-100">
                    <div className="row mx-0 w-100">

                      <div className="col-4 ">
                        <label htmlFor="" className="form-label">
                          Fecha
                        </label>
                        <input
                          className="form-control form-control-solid"
                          placeholder="Fecha Inicio"
                          id="kt_datepicker_18"
                        />
                      </div>
                      <div className="col-8 d-flex justify-content-between">
                        <div className="d-flex align-content-end justify-content-xl-between justify-content-between h-100  w-100">
                          <button
                            disabled={loading}
                            type="button"
                            className="btn btn-primary mt-auto me-3"
                            onClick={getData}
                          >
                            {loading ? (
                              <span className="indicator-label">
                                Buscando...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            ) : (
                              <span className="indicator-label ">
                                <i className="bi bi-search" /> Buscar
                              </span>
                            )}
                          </button>
                          <div className="d-flex">
                            <button type="button" className="btn btn-success mt-auto me-2" onClick={addConsolidado}>
                              <i className="bi bi-plus-circle fs-4 me-2" /> Agregar
                            </button>
                            <button type="button" className="btn btn-warning mt-auto" onClick={() => preview()}>
                              <FaEye></FaEye> Preview
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <MUIDataTable
                  title={<div className="d-flex gap-3 px-4"><span className="fw-bolder fs-14">Lista de Consolidados</span> | <span className="fw-bolder">Total de Producción: {parseFloat(total).toFixed(2)}</span></div>}
                  data={items}
                  columns={columns1}
                  options={options}
                />
              </div>
            </div>
            <div className="modal fade" id="modal_capital_global" tabIndex="-1" aria-modal="true" role="dialog">
              <div className="modal-dialog modal-dialog-centered mw-650px">
                <div className="modal-content">
                  <form
                    className="form fv-plugins-bootstrap5 fv-plugins-framework"
                    id="modal_movs_add_mov_form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="modal-header" id="modal_movs_add_mov_header">
                      <h2 className="fw-bold">Agregar Consolidado</h2>
                      <div
                        id="modal_movs_add_mov_close"
                        className="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal"
                        onClick={resetForm}
                      >
                        <span className="svg-icon svg-icon-1">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.5"
                              x="6"
                              y="17.3137"
                              width="16"
                              height="2"
                              rx="1"
                              transform="rotate(-45 6 17.3137)"
                              fill="currentColor"
                            ></rect>
                            <rect
                              x="7.41422"
                              y="6"
                              width="16"
                              height="2"
                              rx="1"
                              transform="rotate(45 7.41422 6)"
                              fill="currentColor"
                            ></rect>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div className="modal-body py-5 px-lg-10">
                      <div
                        id="modal_movs_add_mov_scroll"
                        data-kt-scroll-dependencies="#modal_movs_add_mov_header"
                        data-kt-scroll-wrappers="#modal_movs_add_mov_scroll"
                      >
                        <div className="row mx-0 w-100 mb-7 fv-plugins-icon-container">

                          <div className="col-12 mb-1">
                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                              <span className="">Fecha</span>
                            </label>
                            <input
                              className="form-control form-control-solid"
                              placeholder="Fecha"
                              id="kt_datepicker_8"
                              value={fechaM}
                              {...register("fechaM", {
                                required: true,
                              })}
                            />
                            {errors.fechaM?.type === "required" && (
                              <p className="text-danger">El campo es requerido</p>
                            )}
                          </div>
                          <div className="col-12 mb-1">
                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                              <span className="required">Monto</span>
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              placeholder="Monto"
                              name="monto"
                              onKeyDown={(e) => montoValidate(e)}
                              {...register("monto", {
                                required: true,
                                valueAsNumber: true,
                                validate: (value, formValues) => parseFloat(value) > 0,
                              })}
                            />
                            {errors.monto?.type === "required" && (
                              <p className="text-danger">El campo es requerido</p>
                            )}
                            {errors.monto?.type === "validate" && <p className="text-danger">Debe ser mayor a 0</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer flex-center">
                      <button
                        type="reset"
                        id="modal_movs_add_mov_cancel"
                        className="btn btn-danger me-3"
                        data-bs-dismiss="modal"
                        onClick={resetForm}
                      >
                        Cerrar
                      </button>
                      <button type="submit" id="modal_movs_add_mov_submit" className="btn btn-primary">
                        {loading2 ? (
                          <span className="indicator-label">
                            Agregando....
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        ) : (
                          <span className="indicator-label">Agregar</span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="modal fade" id="modal_global" tabIndex="-1" aria-modal="true" role="dialog">
              <div className="modal-dialog modal-dialog-centered mw-650px">
                <div className="modal-content">
                  <form
                    className="form fv-plugins-bootstrap5 fv-plugins-framework"
                    id="modal_movs_edit_mov_form"
                    onSubmit={handleSubmit2(onSubmit2)}
                  >
                    <div className="modal-header" id="modal_movs_edit_mov_header">
                      <h2 className="fw-bold">PREVIEW</h2>
                      <div
                        id="modal_movs_edit_mov_close"
                        className="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal"
                        onClick={resetForm2}
                      >
                        <span className="svg-icon svg-icon-1">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.5"
                              x="6"
                              y="17.3137"
                              width="16"
                              height="2"
                              rx="1"
                              transform="rotate(-45 6 17.3137)"
                              fill="currentColor"
                            ></rect>
                            <rect
                              x="7.41422"
                              y="6"
                              width="16"
                              height="2"
                              rx="1"
                              transform="rotate(45 7.41422 6)"
                              fill="currentColor"
                            ></rect>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div className="modal-body py-5 px-lg-10">
                      <div
                        id="modal_movs_edit_mov_scroll"
                        data-kt-scroll-dependencies="#modal_movs_edit_mov_header"
                        data-kt-scroll-wrappers="#modal_movs_edit_mov_scroll"
                      >
                        <div className="row mx-0 w-100 mb-7 fv-plugins-icon-container">
                          <div className="col-6 mb-1">
                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                              <span className="">Fecha</span>
                            </label>
                            <input
                              className="form-control form-control-solid"
                              placeholder="Fecha"
                              id="kt_datepicker_9"
                              disabled
                              {...register2("fecha", {
                                required: true,
                              })}
                            />
                            {errors.fecha?.type === "required" && (
                              <p className="text-danger">El campo es requerido</p>
                            )}
                          </div>
                          <div className="col-6 mb-1">
                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                              <span className="required">Operable</span>
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              placeholder="operable"
                              name="operable"
                              readOnly
                              onKeyDown={(e) => montoValidate(e)}
                              {...register2("operable", {
                                valueAsNumber: true,
                              })}
                            />
                            {errors.operable?.type === "required" && (
                              <p className="text-danger">El campo es requerido</p>
                            )}
                            {errors.operable?.type === "validate" && <p className="text-danger">Debe ser mayor a 0</p>}
                          </div>
                          <div className="col-6 mb-1">
                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                              <span className="required">No Operable</span>
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              placeholder="nooperable"
                              name="nooperable"
                              readOnly
                              onKeyDown={(e) => montoValidate(e)}
                              {...register2("nooperable", {
                                valueAsNumber: true,
                              })}
                            />
                            {errors.nooperable?.type === "required" && (
                              <p className="text-danger">El campo es requerido</p>
                            )}
                            {errors.nooperable?.type === "validate" && <p className="text-danger">Debe ser mayor a 0</p>}
                          </div>
                          <div className="col-6 mb-1">
                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                              <span className="required">Por Llegar</span>
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              placeholder="porllegar"
                              name="porllegar"
                              readOnly
                              onKeyDown={(e) => montoValidate(e)}
                              {...register2("porllegar", {
                                valueAsNumber: true,
                              })}
                            />
                            {errors.porllegar?.type === "required" && (
                              <p className="text-danger">El campo es requerido</p>
                            )}
                            {errors.porllegar?.type === "validate" && <p className="text-danger">Debe ser mayor a 0</p>}
                          </div>
                          <div className="col-6 mb-1">
                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                              <span className="required">Total</span>
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              placeholder="total"
                              name="total"
                              readOnly
                              onKeyDown={(e) => montoValidate(e)}
                              {...register2("total", {
                                valueAsNumber: true,
                              })}
                            />
                            {errors.total?.type === "required" && (
                              <p className="text-danger">El campo es requerido</p>
                            )}
                            {errors.total?.type === "validate" && <p className="text-danger">Debe ser mayor a 0</p>}
                          </div>
                          <div className="col-6 mb-1">
                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                              <span className="required">Producción</span>
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              placeholder="produccion"
                              name="produccion"
                              readOnly
                              onKeyDown={(e) => montoValidate(e)}
                              {...register2("produccion", {
                                valueAsNumber: true,
                              })}
                            />
                            {errors.produccion?.type === "required" && (
                              <p className="text-danger">El campo es requerido</p>
                            )}
                            {errors.produccion?.type === "validate" && <p className="text-danger">Debe ser mayor a 0</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer flex-center">
                      <button
                        type="reset"
                        id="modal_movs_edit_mov_cancel"
                        className="btn btn-danger me-3"
                        data-bs-dismiss="modal"
                        onClick={resetForm2}
                      >
                        Cerrar
                      </button>
                      <button type="submit" id="modal_movs_edit_mov_submit" className="btn btn-primary">
                        {loading3 ? (
                          <span className="indicator-label">
                            Guardando....
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        ) : (
                          <span className="indicator-label">Guardar</span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <div className="overlay-layer card-rounded bg-black bg-opacity-50">
              <div className="d-flex flex-column align-items-center mt-10">
                <div className="spinner-border text-white" role="status"></div>
                <span>Buscando datos...</span>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
  );
};
export default Consolidado;
