/* eslint-disable */

import UseService from "../UseServices";

const HistorialService = {
    async getConexiones(data) {
        var resp = await UseService.post("users/conexiones/", data);
        return resp;
    },
    async getHPlanes(data) {
        var resp = await UseService.post("users/historialplanes/", data);
        return resp;
    },
};
export default HistorialService;
