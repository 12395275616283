/* eslint-disable */

import UseService from "../UseServices";

const NotiService = {
    async getNotificaciones(data) {
        var resp = await UseService.post("users/notificaciones", data);
        return resp;
    },
    async updateVisto(data) {
        var resp = await UseService.post("users/notificacionesvisto", data);
        return resp;
    },
};
export default NotiService;