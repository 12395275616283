/* eslint-disable */
import React, { useEffect, useState } from "react";
import Main from "../../../../layout/main";
import MUIDataTable from "mui-datatables";
import Breadcrumbs from "../../../../Components/utils/BreadCrumb";
import ReportesService from "../../../../api/services/reportesService";
import { FaSearch } from "react-icons/fa"
import moment from "moment/moment";
import { useUserContext } from "../../../../Providers/UserProvider";
import "../../operaciones.scss"
const today = new Date();
const BancaDiario = () => {
  const [loading, setLoading] = useState(false);
  const [itemsLeft, setItemsLeft] = useState([]);
  const [itemsRight, setItemsRight] = useState([]);
  const [operadores, setOperadores] = useState([]);
  const [fecha, setFecha] = useState(moment(today).format("yyyy-MM-DD"));
  const [disInput, setDisInput] = useState(true);
  const refreshToken = localStorage.getItem("refreshToken");
  const { user } = useUserContext();

  const columns1 = [
    {
      name: "casaapuesta",
      label: "Casa",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
      },
    },
    {
      name: "titular",
      label: "Titular",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
      },
    },
    {
      name: "monto",
      label: "Monto",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
      },
    }
  ];
  const options = {
    print: false,
    viewColumns: false,
    filter: false,
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    fixedSelectColumn: false,
    download: false,
    pagination: false,
    search: false,
    textLabels: {
      body: {
        noMatch: "No se encontraron datos",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenador por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Elementos por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscador",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Columnas",
        filterTable: "Filtrar Tabla",
      },
      filter: {
        all: "Todos",
        title: "Filtros",
        reset: "Resetear",
      },
      viewColumns: {
        title: "Mostrar Columnas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Eliminar",
        deleteAria: "Delete Selected Rows",
      },
    },
    // customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
    //     <div>
    //         <FaTrash className="mx-4 cursor-pointer" onClick={() => handleMyState(selectedRows)} />
    //     </div>
    // ),
  };
  const getData = async () => {
    setLoading(true)
    const body = {
      fecha: fecha,
      tcambio: user.tipocambio,
      refreshToken: "Bearer " + refreshToken
    }
    const resp = await ReportesService.getBankDiario(body)
    // console.log("resp",resp)
    if (resp.status) {
      setItemsLeft(resp.data[0].left)
      setItemsRight(resp.data[0].right)
    }
    setLoading(false)
    // console.log('resp', resp)
  }
  //BreadCrumb
  const crumbs = [
    { path: "/perfil", name: "Home", active: true },
    { path: "/movimientos", name: "Movimientos", active: false },
    { path: "/movimientos/bankdiario", name: "Capital Diario", active: false },
  ];
  useEffect(() => {
    // getData()
  }, [])
  useEffect(() => {
    $("#kt_datepicker_11").flatpickr({
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      // minDate: "today",
      onChange: function (selectedDates, dateStr, instance) {
        setFecha(moment(dateStr).format("yyyy-MM-DD"));
      },
      defaultDate: moment(today).format("yyyy-MM-DD"),
    });
  }, [])
  return (
    user.frqpoint == 0 ?
      <div className="container-xxl mw-100 px-0">
        <div className="toolbar" id="kt_toolbar">
          <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
            <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
          </div>
        </div>
        <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
          <div className="overlay-wrapper">
            <div className="row mx-0 mb-4">
              <div className="col-12">
                <div className="card">
                  <div className="card-header border-0 p-6">
                    <div className="col-6 d-flex justify-content-start align-items-center gap-2 px-4">
                      <div className="d-flex gap-2 align-items-center">
                        <span>Fecha: </span>
                        <input className="form-control form-control-solid" placeholder="Fecha" id="kt_datepicker_11" />
                      </div>
                      <button onClick={() => getData()} className="btn btn-primary btn-icon"><FaSearch></FaSearch></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-0 ">
              <div className=" col-6 mb-4">
                <div className="tabs">
                  <div className="tab">
                    <input type="checkbox" id="chck1" />
                    <label className="tab-label p-2" htmlFor="chck1">
                      <div className="d-flex box_options ps-5 gap-2 justify-content-between w-100 pe-5 align-items-center">
                        <span className="fw-bold fs-2">Capital Inicial</span>
                        <span className="fs-5">$ {itemsLeft.length > 0 ? parseFloat(itemsLeft[0].total).toFixed(2) : (0).toFixed(2)}</span>
                      </div>
                    </label>
                    <div className="tab-content_cal card_contenido">
                      <div className="">
                        <MUIDataTable
                          data={itemsLeft.length > 0 ? itemsLeft[0].data : []}
                          columns={columns1}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-6 mb-4">
                <div className="tabs">
                  <div className="tab">
                    <input type="checkbox" id="chck2" />
                    <label className="tab-label p-2" htmlFor="chck2">
                      <div className="d-flex box_options ps-5 gap-2 justify-content-between w-100 pe-5 align-items-center">
                        <span className="fw-bold fs-2">Capital Final</span>
                        <span className="fs-5">$ {itemsRight.length > 0 ? parseFloat(itemsRight[0].total).toFixed(2) : (0).toFixed(2)}</span>
                      </div>
                    </label>
                    <div className="tab-content_cal card_contenido">
                      <div className="">
                        <MUIDataTable
                          data={itemsRight.length > 0 ? itemsRight[0].data : []}
                          columns={columns1}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-6 mb-4">
                <div className="tabs">
                  <div className="tab">
                    <input type="checkbox" id="chck3" />
                    <label className="tab-label p-2" htmlFor="chck3">
                      <div className="d-flex box_options ps-5 gap-2 justify-content-between w-100 pe-5 align-items-center">
                        <span className="fw-bold fs-2">Recargas</span>
                        <span className="fs-5">$ {itemsLeft.length > 0 ? parseFloat(itemsLeft[1].total).toFixed(2) : (0).toFixed(2)}</span>
                      </div>
                    </label>
                    <div className="tab-content_cal card_contenido">
                      <div className="">
                        <MUIDataTable
                          data={itemsLeft.length > 0 ? itemsLeft[1].data : []}
                          columns={columns1}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-6 mb-4">
                <div className="tabs">
                  <div className="tab">
                    <input type="checkbox" id="chck4" />
                    <label className="tab-label p-2" htmlFor="chck4">
                      <div className="d-flex box_options ps-5 gap-2 justify-content-between w-100 pe-5 align-items-center">
                        <span className="fw-bold fs-2">Retiros</span>
                        <span className="fs-5">$ {itemsRight.length > 0 ? parseFloat(itemsRight[1].total).toFixed(2) : (0).toFixed(2)}</span>
                      </div>
                    </label>
                    <div className="tab-content_cal card_contenido">
                      <div className="">
                        <MUIDataTable
                          data={itemsRight.length > 0 ? itemsRight[1].data : []}
                          columns={columns1}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-6 mb-4">
                <div className="tabs">
                  <div className="tab">
                    <label className="tab-label-w p-2" htmlFor="chck5">
                      <div className="d-flex box_options ps-5 gap-2 justify-content-between w-100 pe-5 align-items-center">
                        <span className="fw-bold fs-2">Total</span>
                        <span className="fs-5">$ {itemsLeft.length > 0 ? parseFloat(itemsLeft[2].total).toFixed(2) : (0).toFixed(2)}</span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className=" col-6 mb-4">
                <div className="tabs">
                  <div className="tab">
                    {/* <input type="checkbox" id="chck6" /> */}
                    <label className="tab-label-w p-2" htmlFor="chck6">
                      <div className="d-flex box_options ps-5 gap-2 justify-content-between w-100 pe-5 align-items-center">
                        <span className="fw-bold fs-2">Producción</span>
                        <span className="fs-5">$ {itemsRight.length > 0 ? parseFloat(itemsRight[3].total).toFixed(2) : (0).toFixed(2)}</span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </div>
          {loading ? (
            <div className="overlay-layer card-rounded bg-black bg-opacity-50">
              <div className="spinner-border text-primary" role="status"></div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      :
      <Main>
        <div className="container-xxl mw-100">
          <div className="toolbar" id="kt_toolbar">
            <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
              <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
            </div>
          </div>
          <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
            <div className="overlay-wrapper">
              <div className="row mx-0 mb-4">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header border-0 p-6">
                      <div className="col-6 d-flex justify-content-start align-items-center gap-2 px-4">
                        <div className="d-flex gap-2 align-items-center">
                          <span>Fecha: </span>
                          <input className="form-control form-control-solid" placeholder="Fecha" id="kt_datepicker_11" />
                        </div>
                        <button onClick={() => getData()} className="btn btn-primary btn-icon"><FaSearch></FaSearch></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mx-0 ">
                <div className=" col-6 mb-4">
                  <div className="tabs">
                    <div className="tab">
                      <input type="checkbox" id="chck1" />
                      <label className="tab-label p-2" htmlFor="chck1">
                        <div className="d-flex box_options ps-5 gap-2 justify-content-between w-100 pe-5 align-items-center">
                          <span className="fw-bold fs-2">Capital Inicial</span>
                          <span className="fs-5">$ {itemsLeft.length > 0 ? parseFloat(itemsLeft[0].total).toFixed(2) : (0).toFixed(2)}</span>
                        </div>
                      </label>
                      <div className="tab-content_cal card_contenido">
                        <div className="">
                          <MUIDataTable
                            data={itemsLeft.length > 0 ? itemsLeft[0].data : []}
                            columns={columns1}
                            options={options}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-6 mb-4">
                  <div className="tabs">
                    <div className="tab">
                      <input type="checkbox" id="chck2" />
                      <label className="tab-label p-2" htmlFor="chck2">
                        <div className="d-flex box_options ps-5 gap-2 justify-content-between w-100 pe-5 align-items-center">
                          <span className="fw-bold fs-2">Capital Final</span>
                          <span className="fs-5">$ {itemsRight.length > 0 ? parseFloat(itemsRight[0].total).toFixed(2) : (0).toFixed(2)}</span>
                        </div>
                      </label>
                      <div className="tab-content_cal card_contenido">
                        <div className="">
                          <MUIDataTable
                            data={itemsRight.length > 0 ? itemsRight[0].data : []}
                            columns={columns1}
                            options={options}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-6 mb-4">
                  <div className="tabs">
                    <div className="tab">
                      <input type="checkbox" id="chck3" />
                      <label className="tab-label p-2" htmlFor="chck3">
                        <div className="d-flex box_options ps-5 gap-2 justify-content-between w-100 pe-5 align-items-center">
                          <span className="fw-bold fs-2">Recargas</span>
                          <span className="fs-5">$ {itemsLeft.length > 0 ? parseFloat(itemsLeft[1].total).toFixed(2) : (0).toFixed(2)}</span>
                        </div>
                      </label>
                      <div className="tab-content_cal card_contenido">
                        <div className="">
                          <MUIDataTable
                            data={itemsLeft.length > 0 ? itemsLeft[1].data : []}
                            columns={columns1}
                            options={options}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-6 mb-4">
                  <div className="tabs">
                    <div className="tab">
                      <input type="checkbox" id="chck4" />
                      <label className="tab-label p-2" htmlFor="chck4">
                        <div className="d-flex box_options ps-5 gap-2 justify-content-between w-100 pe-5 align-items-center">
                          <span className="fw-bold fs-2">Retiros</span>
                          <span className="fs-5">$ {itemsRight.length > 0 ? parseFloat(itemsRight[1].total).toFixed(2) : (0).toFixed(2)}</span>
                        </div>
                      </label>
                      <div className="tab-content_cal card_contenido">
                        <div className="">
                          <MUIDataTable
                            data={itemsRight.length > 0 ? itemsRight[1].data : []}
                            columns={columns1}
                            options={options}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-6 mb-4">
                  <div className="tabs">
                    <div className="tab">
                      <label className="tab-label-w p-2" htmlFor="chck5">
                        <div className="d-flex box_options ps-5 gap-2 justify-content-between w-100 pe-5 align-items-center">
                          <span className="fw-bold fs-2">Total</span>
                          <span className="fs-5">$ {itemsLeft.length > 0 ? parseFloat(itemsLeft[2].total).toFixed(2) : (0).toFixed(2)}</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className=" col-6 mb-4">
                  <div className="tabs">
                    <div className="tab">
                      {/* <input type="checkbox" id="chck6" /> */}
                      <label className="tab-label-w p-2" htmlFor="chck6">
                        <div className="d-flex box_options ps-5 gap-2 justify-content-between w-100 pe-5 align-items-center">
                          <span className="fw-bold fs-2">Combinadas</span>
                          <span className="fs-5">$ {itemsRight.length > 0 ? parseFloat(itemsRight[2].total).toFixed(2) : (0).toFixed(2)}</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className=" col-6 mb-4"></div>
                <div className=" col-6 mb-4">
                  <div className="tabs">
                    <div className="tab">
                      {/* <input type="checkbox" id="chck6" /> */}
                      <label className="tab-label-w p-2" htmlFor="chck6">
                        <div className="d-flex box_options ps-5 gap-2 justify-content-between w-100 pe-5 align-items-center">
                          <span className="fw-bold fs-2">Producción</span>
                          <span className="fs-5">$ {itemsRight.length > 0 ? parseFloat(itemsRight[3].total).toFixed(2) : (0).toFixed(2)}</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            {loading ? (
              <div className="overlay-layer card-rounded bg-black bg-opacity-50">
                <div className="spinner-border text-primary" role="status"></div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Main>
  )
}

export default BancaDiario