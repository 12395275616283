/* eslint-disable */
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../Components/utils/BreadCrumb";
import ReportesService from "../../../api/services/reportesService";
import { useForm, Controller } from "react-hook-form";
import ReactApexChart from "react-apexcharts";
import moment from "moment/moment";
import Select from "react-select";
import "styled-components";
import "../reportes.css";
import Main from "../../../layout/main";
import { useUserContext } from "../../../Providers/UserProvider";
const today = new Date();
const tipos = [
    {
        text: "Individual",
        value: "0",
    },
    {
        text: "Grupal",
        value: "1",
    },
];
const ReporteApuestas = () => {
    //BreadCrumb
    const crumbs = [
        { name: "Reportes", active: false },
        { path: "/reporte-apuestas", name: "Graficas de Apuestas", active: false },
    ];

    //hooks
    const [filtros, setFiltros] = useState({
        f_inicio: moment(today).format("yyyy-MM-DD"),
        f_fin: moment(today).format("yyyy-MM-DD"),
        operador: "0",
        tipo: "0",
    });
    const { user } = useUserContext();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        options: {
            chart: {
                type: "bar",
                height: 450,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "35%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: [],
            },
            yaxis: {
                title: {
                    text: "$",
                    color: "#fff",
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "$ " + val;
                    },
                },
            },
        },
        series: [],
    });
    const [operadores, setOperadores] = useState([]);
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: localStorage.getItem("userTheme") === "dark" ? "#1b1b29" : "#f9f9f9",
            border: "0px",
            fontWeight: "500",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? localStorage.getItem("userTheme") === "dark"
                        ? "#2B2B3D"
                        : "#D0D2D7"
                    : isFocused
                        ? localStorage.getItem("userTheme") === "dark"
                            ? "#2B2B3D"
                            : "#F4F6FA"
                        : localStorage.getItem("userTheme") === "dark"
                            ? "#1b1b29"
                            : "#f9f9f9",
                color: isSelected
                    ? localStorage.getItem("userTheme") === "dark"
                        ? "white"
                        : "black"
                    : localStorage.getItem("userTheme") === "dark"
                        ? "white"
                        : "black",
                fontWeight: "500",
                cursor: "pointer",
            };
        },
        placeholder: (styles) => ({ ...styles }),
        singleValue: (styles) => ({
            ...styles,
            color: localStorage.getItem("userTheme") === "dark" ? "white" : "black",
            fontWeight: "500",
        }),
        valueContainer: (styles) => ({ ...styles, padding: "0.85rem" }),
        menu: (styles) => ({
            ...styles,
            backgroundColor: localStorage.getItem("userTheme") === "dark" ? "#1b1b29" : "#f9f9f9",
        }),
    };
    //DATOS LOCALSTORAGE
    const refreshToken = localStorage.getItem("refreshToken");

    //FUNCION TRAER DATA

    const showData = async () => {
        setLoading(true)
        const body = {
            fechafin: filtros.f_fin,
            fechainicio: filtros.f_inicio,
            tipo: filtros.tipo,
            refreshToken: "Bearer " + refreshToken,
        };
        const resp = await ReportesService.getReporteApuestas(body);
        let arrayCategories = [];
        let arraySeriesE = [];
        let arraySeriesR1 = [];
        let arraySeriesR2 = [];
        let arraySeriesG = [];
        if (resp.status) {
            for (let index = 0; index < resp.data[0].length; index++) {
                const element = resp.data[0][index];
                arrayCategories.push(element.label);
                arraySeriesE.push(element.pendiente);
                arraySeriesR1.push(element.ganado);
                arraySeriesR2.push(element.perdido);
                arraySeriesG.push(element.total);
            }
            setState({
                ...state,
                options: {
                    xaxis: {
                        categories: arrayCategories,
                    },
                },
                series: [
                    {
                        name: "Jugado",
                        data: arraySeriesE,
                    },
                    {
                        name: "R+",
                        data: arraySeriesR1,
                    },
                    {
                        name: "R-",
                        data: arraySeriesR2,
                    },
                    {
                        name: "Ganancia",
                        data: arraySeriesG,
                    },
                ],
            });
        }
        setLoading(false)
    };
    const getData = async () => {
        const body = {
            franquicia: "1",
            refreshToken: "Bearer " + refreshToken,
        };
        const resp = await ReportesService.getOperadores(body);
        if (resp.status) {
            resp.data[0].unshift({
                id_persona: "0",
                nom: "Todos",
            });
            setOperadores(resp.data[0]);
        }
    };
    //MOUNTED
    useEffect(() => {
        // getData();
        $("#kt_datepicker_5").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            onChange: function (selectedDates, dateStr, instance) {
                setFiltros({ ...filtros, f_inicio: moment(dateStr).format("yyyy-MM-DD") });
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        $("#kt_datepicker_6").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            onChange: function (selectedDates, dateStr, instance) {
                setFiltros({ ...filtros, f_fin: moment(dateStr).format("yyyy-MM-DD") });
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        // showData();
    }, []);
    return (
        user.frqpoint == 0 ?
            <div className="container-xxl mw-100 px-0 ">
                <div className="toolbar" id="kt_toolbar">
                    <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header border-0 p-6">
                        <div className="card-title">
                            <div className="d-flex align-items-center position-relative my-1"></div>
                        </div>
                        <div className="card-toolbar w-100">
                            <div className="d-flex flex-column flex-lg-row  w-100 justify-content-between h-100">
                                <div className="me-3 d-flex gap-10 flex-column flex-md-row w-100">
                                    <div className="w-100">
                                        <label htmlFor="" className="form-label">
                                            Inicio
                                        </label>
                                        <input className="form-control form-control-solid" placeholder="Fecha Inicio" id="kt_datepicker_5" />
                                    </div>
                                    <div className="w-100">
                                        <label htmlFor="" className="form-label">
                                            Fin
                                        </label>
                                        <input className="form-control form-control-solid" placeholder="Fecha Fin" id="kt_datepicker_6" />
                                    </div>
                                    {/* <div className=" w-100">
                                        <label htmlFor="casas-estado" className="form-label">
                                            Operador
                                        </label>
                                        <Select
                                            options={operadores.map((cu) => ({
                                                label: cu.nom,
                                                value: cu.id_persona,
                                            }))}
                                            styles={colourStyles}
                                            defaultValue={{ label: "Todos", value: "0" }}
                                            isClearable={false}
                                            isSearchable={false}
                                            onChange={(e) => setFiltros({ ...filtros, operador: e.value.toString() })}
                                            placeholder={"Seleccionar"}
                                        />
                                    </div> */}
                                    <div className=" w-100">
                                        <label htmlFor="casas-estado" className="form-label">
                                            Tipo
                                        </label>
                                        <Select
                                            options={tipos.map((cu) => ({
                                                label: cu.text,
                                                value: cu.value,
                                            }))}
                                            defaultValue={{ label: "Individual", value: "0" }}
                                            styles={colourStyles}
                                            isClearable={false}
                                            isSearchable={false}
                                            onChange={(e) => setFiltros({ ...filtros, tipo: e.value })}
                                            placeholder={"Seleccionar"}
                                        />
                                    </div>
                                    <div className="d-flex align-content-end justify-content-lg-end justify-content-center h-100  w-100">
                                        <button disabled={loading} type="button" className="btn btn-primary mt-auto" onClick={showData}>
                                            {loading ? (
                                                <span className="indicator-label">
                                                    Buscando...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            ) : (
                                                <span className="indicator-label "><i className="bi bi-search" /> Buscar</span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <ReactApexChart options={state.options} series={state.series} type="bar" height={350} />
                    </div>
                </div>
            </div>
            :
            <Main>
                <div className="container-xxl mw-100">
                    <div className="toolbar" id="kt_toolbar">
                        <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                            <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header border-0 p-6">
                            <div className="card-title">
                                <div className="d-flex align-items-center position-relative my-1"></div>
                            </div>
                            <div className="card-toolbar w-100">
                                <div className="d-flex flex-column flex-lg-row  w-100 justify-content-between h-100">
                                    <div className="me-3 d-flex gap-10 flex-column flex-md-row w-100">
                                        <div className="w-100">
                                            <label htmlFor="" className="form-label">
                                                Inicio
                                            </label>
                                            <input className="form-control form-control-solid" placeholder="Fecha Inicio" id="kt_datepicker_5" />
                                        </div>
                                        <div className="w-100">
                                            <label htmlFor="" className="form-label">
                                                Fin
                                            </label>
                                            <input className="form-control form-control-solid" placeholder="Fecha Fin" id="kt_datepicker_6" />
                                        </div>
                                        {/* <div className=" w-100">
                                        <label htmlFor="casas-estado" className="form-label">
                                            Operador
                                        </label>
                                        <Select
                                            options={operadores.map((cu) => ({
                                                label: cu.nom,
                                                value: cu.id_persona,
                                            }))}
                                            styles={colourStyles}
                                            defaultValue={{ label: "Todos", value: "0" }}
                                            isClearable={false}
                                            isSearchable={false}
                                            onChange={(e) => setFiltros({ ...filtros, operador: e.value.toString() })}
                                            placeholder={"Seleccionar"}
                                        />
                                    </div> */}
                                        <div className=" w-100">
                                            <label htmlFor="casas-estado" className="form-label">
                                                Tipo
                                            </label>
                                            <Select
                                                options={tipos.map((cu) => ({
                                                    label: cu.text,
                                                    value: cu.value,
                                                }))}
                                                defaultValue={{ label: "Todos", value: "0" }}
                                                styles={colourStyles}
                                                isClearable={false}
                                                isSearchable={false}
                                                onChange={(e) => setFiltros({ ...filtros, tipo: e.value })}
                                                placeholder={"Seleccionar"}
                                            />
                                        </div>
                                        <div className="d-flex align-content-end justify-content-lg-end justify-content-center h-100  w-100">
                                            <button disabled={loading} type="button" className="btn btn-primary mt-auto" onClick={showData}>
                                                {loading ? (
                                                    <span className="indicator-label">
                                                        Buscando...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                ) : (
                                                    <span className="indicator-label "><i className="bi bi-search" /> Buscar</span>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <ReactApexChart options={state.options} series={state.series} type="bar" height={350} />
                        </div>
                    </div>
                </div>
            </Main>
    );
};

export default ReporteApuestas;
