/* eslint-disable */
import React from "react";

const CheckPay = () => {
  return (
    <svg id="successAnimation" className="animated" xmlns="http://www.w3.org/2000/svg" width="70" height="70"
      viewBox="0 0 70 70">
      <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" strokeWidth="2"
        strokeLinecap="round" fill="transparent" />
      <polyline id="successAnimationCheck" stroke="#979797" strokeWidth="2" points="23 34 34 43 47 27"
        fill="transparent" />
    </svg>
  );
};

export default CheckPay;
