/* eslint-disable */
import React, { useEffect, useState } from "react";
import Main from "../../layout/main";
import MUIDataTable from "mui-datatables";
import Breadcrumbs from "../../Components/utils/BreadCrumb";
import UtilidadesService from "../../api/services/utilidadesService";
import { FaSearch } from "react-icons/fa"
import moment from "moment/moment";
import { useUserContext } from "../../Providers/UserProvider";
const today = new Date();
const Utilidades = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState("");
  const [fecha, setFecha] = useState(moment(today).format("yyyy-MM-DD"));
  const refreshToken = localStorage.getItem("refreshToken");
  const { user } = useUserContext();
  const getData = async () => {
    setLoading(true)
    const body = {
      fecha: fecha,
      tcambio: user.tipocambio,
      refreshToken: "Bearer " + refreshToken
    }
    const resp = await UtilidadesService.getExtendUtilidades(body)
    if (resp.status) {
      setItems(resp.data[0])
    }
    setLoading(false)
    // console.log('resp.data[0]', resp.data[0])
  }
  //BreadCrumb
  const crumbs = [
    { path: "/perfil", name: "Home", active: true },
    { path: "/utilidades", name: "Utilidades", active: false },
  ];
  useEffect(() => {
    // getData()
  }, [])
  useEffect(() => {
    $("#kt_datepicker_26").flatpickr({
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      // minDate: "today",
      onChange: function (selectedDates, dateStr, instance) {
        setFecha(moment(dateStr).format("yyyy-MM-DD"));
      },
      defaultDate: moment(today).format("yyyy-MM-DD"),
    });
  }, [])
  return (
    <div className="container-xxl mw-100 px-0">
      <div className="toolbar" id="kt_toolbar">
        <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
          <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
        </div>
      </div>
      <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
        <div className="overlay-wrapper">
          <div className="row mx-0 mb-4">
            <div className="col-12">
              <div className="card">
                <div className="card-header border-0 p-6">
                  <div className="col-6 d-flex justify-content-start align-items-center gap-2 px-4">
                    <div className="d-flex gap-2 align-items-center">
                      <span>Fecha: </span>
                      <input className="form-control form-control-solid" placeholder="Fecha" id="kt_datepicker_26" />
                    </div>
                    <button onClick={() => getData()} className="btn btn-primary btn-icon"><FaSearch></FaSearch></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0 ">
            <div className=" col-6 mb-4">
              <div className="card">
                <div className="card-header border-0 p-6 text-center border-2 border-bottom">
                  <h4 className="w-100">PRODUCCIÓN ACUMULADA</h4>
                </div>
                <div className="card-body px-0 py-2">
                  <div className="d-flex text-center border-2 border-bottom">
                    <div className="w-100 p-3 text-center">
                      <h5 className="w-100">Fecha</h5>
                    </div>
                    <div className="w-100 p-3 text-center">
                      <h5 className="w-100">Monto</h5>
                    </div>
                  </div>
                  {items != "" > 0 ?
                    items.produccion.map((prod, index) => {
                      return (
                        <div className="d-flex" key={index}>
                          <div className="w-100 p-2 text-center border-2 border-bottom">
                            <span className="font-bold fs-5">{prod.fecha}</span>
                          </div>
                          <div className="w-100 p-2 text-center border-2 border-bottom">
                            <span className="fs-5">{parseFloat(prod.produccion).toFixed(2)}</span>
                          </div>
                        </div>
                      )
                    }) :
                    <div className="p-4 d-flex justify-content-center">
                      Sin Producciones
                    </div>
                  }

                </div>
              </div>
            </div>
            <div className=" col-6 mb-4 d-flex flex-column gap-4">
              <div className="card">
                <div className="card-header border-0 p-6 text-center border-2 border-bottom">
                  <h4 className="w-100">COSTOS</h4>
                </div>
                <div className="card-body px-0 py-2">
                  <div className="d-flex text-center border-2 border-bottom">
                    <div className="w-100 p-3 text-center">
                      <h5 className="w-100">Fijo</h5>
                    </div>
                    <div className="w-100 p-3 text-center">
                      <h5 className="w-100">Variado</h5>
                    </div>
                  </div>
                  {items != "" ?
                    items.gastos.map((gast, index) => {
                      return (
                        <div className="d-flex" key={index}>
                          <div className="w-100 p-2 text-center border-2 border-bottom">
                            <span className="font-bold fs-5">{parseFloat(gast.dolaresfijo).toFixed(2)}</span>
                          </div>
                          <div className="w-100 p-2 text-center border-2 border-bottom">
                            <span className="fs-5">{parseFloat(gast.dolaresvariado).toFixed(2)}</span>
                          </div>
                        </div>
                      )
                    }) :
                    <div className="p-4 d-flex justify-content-center">
                      Sin Gastos
                    </div>
                  }

                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0 mb-10">
            {items != "" ?
              items.resumen.map((res, index) => {
                return (
                  <div className="col-12 d-flex gap-2" key={index}>
                    <div className="card d-flex w-100">
                      <div className="card-body p-4 d-flex">
                        <div className="w-100 p-3 text-center">
                          <h4 className="w-100">PRODUCCIÓN TOTAL</h4>
                        </div>
                        <div className="w-100 p-3 text-center">
                          <span>{parseFloat(res.produccion).toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="card d-flex w-100">
                      <div className="card-body p-4 d-flex">
                        <div className="w-100 p-3 text-center border-2 border-right-2">
                          <h4 className="w-100">COSTO TOTAL</h4>
                        </div>
                        <div className="w-100 p-3 text-center">
                          <span>{parseFloat(res.gasto).toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="card d-flex w-100">
                      <div className="card-body p-4 d-flex">
                        <div className="w-100 p-3 text-center">
                          <h4 className="w-100">UTILIDAD</h4>
                        </div>
                        <div className="w-100 p-3 text-center">
                          <span>{parseFloat(res.utilidad).toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }) :
              <div className="col-12 ">
                <div className="card p-4">
                  <span className="w-100 d-flex justify-content-center">
                    Sin Resumen
                  </span>
                </div>
              </div>
            }

          </div>
          
        </div>
        {loading ? (
          <div className="overlay-layer card-rounded bg-black bg-opacity-50">
            <div className="spinner-border text-primary" role="status"></div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>

  )
}

export default Utilidades