/* eslint-disable */

import UseService from "../UseServices";

const MovimientosService = {
    //CUADRE INGRESO
    //Listar
    async getMovIngreso(data) {
        var resp = await UseService.post("cuadreingreso/", data);
        return resp;
    },
    //SELECTINICIAL
    async getMovSelectInicial(data) {
        var resp = await UseService.post("cuadreingreso/selectinicial", data);
        return resp;
    },
    //Agregar
    async AddMov(data) {
        var resp = await UseService.post("cuadreingreso/registrar", data);
        return resp;
    },
    //Actualizar
    async updateMov(data) {
        var resp = await UseService.put("cuadreingreso/actualizar", data);
        return resp;
    },
    //Eliminar
    async deleteMov(data) {
        var resp = await UseService.put("cuadreingreso/estado", data);
        return resp;
    },

    async getCuentas(data) {
        var resp = await UseService.post("movimientos/buscardatosrecarga/", data);
        return resp;
    },

    //CUADRE FINAL
    //Listar
    async getMovIngresoFinal(data) {
        var resp = await UseService.post("cuadresalida/", data);
        return resp;
    },
    //Select
    async getMovSelectIngreso(data) {
        var resp = await UseService.post("cuadreingreso/select", data);
        return resp;
    },
    //Agregar
    async AddMovSalida(data) {
        var resp = await UseService.post("cuadresalida/registrar", data);
        return resp;
    },
    //Actualizar
    async updateMovSalida(data) {
        var resp = await UseService.put("cuadresalida/actualizar", data);
        return resp;
    },
    //Eliminar
    async deleteMovSalida(data) {
        var resp = await UseService.put("cuadresalida/estado", data);
        return resp;
    },

    //MOVIMIENTOS
    //Listar
    async getMovs(data) {
        var resp = await UseService.post("movimientos/", data);
        return resp;
    },
    //Agregar
    async AddMovs(data) {
        var resp = await UseService.post("movimientos/registrar", data);
        return resp;
    },
    //Actualizar
    async updateMovs(data) {
        var resp = await UseService.put("movimientos/actualizar", data);
        return resp;
    },
    //Eliminar
    async deleteMovs(data) {
        var resp = await UseService.put("movimientos/estado", data);
        return resp;
    },
      //OTRO MODULO
      async getConfirm(data) {
        var resp = await UseService.post("movimientos/listconfirm", data);
        return resp;
    },
    async saveConfirm(data) {
        var resp = await UseService.post("movimientos/confirm", data);
        return resp;
    },
    async restConfirm(data) {
        var resp = await UseService.put("movimientos/restart", data);
        return resp;
    },
};

export default MovimientosService;
