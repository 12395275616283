/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react';
import { FaUserAlt, FaRegTimesCircle } from "react-icons/fa";
import io from 'socket.io-client';
import { useUserContext } from "../../Providers/UserProvider";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const socket = io('https://servicesurebets.com:3003'); // Reemplaza con la URL de tu servidor socket

const Chat = () => {
    const { user } = useUserContext();
    const [messages, setMessages] = useState([]);
    const [colorText, setColorText] = useState({});
    const [nomUser, setNomUser] = useState('');
    const [colorRespuesta, setColorRespuesta] = useState({});
    const [userRespuesta, setUserRespuesta] = useState('');
    const [tokenRespuesta, setTokenRespuesta] = useState('');
    const [msgRespuesta, setMsgRespuesta] = useState('');
    const [inputMessage, setInputMessage] = useState('');
    const contenedorRef = useRef(null);
    const inputRef = useRef(null);

    const containerChat = {
        minHeight: "15rem",
        maxHeight: "24rem",
        lineHeight: "1.4rem",
        overflowX: "hidden",
        overflowY: "scroll",
        "width": "100%"
    }

    const horaChat = {
        fontSize: "0.8rem",
        fontWeight: 100
    }

    const msgChat = {
        fontSize: "1.2rem"
    }

    const msgRespChat = {
        fontSize: "0.9rem"
    }

    const linkRespChat = {
        color: "#00B74F"
    }

    const cancelarResp = {
        "cursor": "pointer"
    }

    const NewResp = (datos) => {
        if (datos.tokenresp ==  user.token){
            toast.success("El usuario " + datos.usuario + " respondio a tu mensaje", {
                autoClose: 1500
            });
        }
    }

    useEffect(() => {
        handleNomUser();
        handleColor();

        socket.on('chat message', handleNotification);

        return () => {
            // socket.off('chat message', handleNotification);
            socket.disconnect();
        };

    }, []);

    useEffect(() => {
        const contenedor = contenedorRef.current;
        contenedor.scrollTop = contenedor.scrollHeight;
    }, [messages]);
    
    const handleNotification = (datos, color, hora) => {
        setMessages((prevMessages) => [...prevMessages, { datos, color, hora }]);
        NewResp(datos);
    };

    const handleColor = () => {
        let colors = ["#00B74F", "#C843D6", "#FFD113", "#F47E2F",
            "#d9214e", "#4892FF", "#20c997", "#d63384"];
        var rand = Math.floor(Math.random() * colors.length);
        setColorText(colors[rand]);
    }

    const handleNomUser = () => {
        let nom = (user.nombres).split(' ');
        let ape = (user.apellidos).split(' ');
        let nomcompleto = nom[0] + " " + ape[0];
        setNomUser(nomcompleto);
    }

    const handleCancelar = () => {
        setTokenRespuesta('');
        setMsgRespuesta('');
        setUserRespuesta('');
        setColorRespuesta({});
        inputRef.current.focus();
    }

    const handleTokenResp = (usuarioresp, token, msgresp, color) => {
        setUserRespuesta("@" + usuarioresp);
        setTokenRespuesta(token);
        setMsgRespuesta(msgresp);
        setColorRespuesta(color);
        inputRef.current.focus();
    }

    const handleMessageSubmit = (e) => {
        e.preventDefault();
        if (inputMessage.trim() !== '') {

            let respboolean = tokenRespuesta != "" ? true : false;

            let datos = {
                msg: inputMessage,
                usuario: nomUser,
                token: user.token,
                resp: respboolean,
                userresp: userRespuesta,
                colorresp: colorRespuesta,
                tokenresp: tokenRespuesta,
                msgresp: msgRespuesta,
            }

            socket.emit('chat message', datos, colorText);
            setInputMessage('');
            handleCancelar();
        }
    };

    return (
        <div className="d-none d-sm-block mt-auto box_filters mb-4" style={{marginLeft: "6rem"}}>
            <div className="px-6 bg_surebet_primary fw-bolder fs-14 d-flex justify-content-between rounded-top">
                <div className="d-flex align-items-center justify-content-between w-100 py-2">
                    <h6 className="fs-14 fw-bolder px-1 mb-0 text-white">CHAT</h6>
                </div>
            </div>
            <div className='p-4'>
                <div ref={contenedorRef} style={containerChat} className='mb-2'>
                    {messages.map((message, index) => (
                        <div className='row' key={index}>
                            <div className='col-1 d-flex align-items-center justify-content-center'>
                                <FaUserAlt className='fs-1' style={message.color}></FaUserAlt>
                            </div>
                            <div className='col-11'>
                                <span className='fw-bold'
                                    style={message.color}>{message.datos.usuario}</span>
                                {message.datos.resp ? <span className='d-block text-muted text-break'
                                    style={msgRespChat}>Respondio
                                    <span style={message.datos.colorresp}> {message.datos.userresp} : </span>
                                    {message.datos.msgresp}</span> : <></>}
                                <span className='d-block text-break' style={msgChat}>
                                    {message.datos.msgsalida}</span>
                                <span className='fst-italic' style={horaChat}>
                                    <label className='me-5'>{message.hora}</label>
                                    {user.token == message.datos.token ? <></> : 
                                    <a style={linkRespChat} href="#" onClick={() =>
                                        handleTokenResp(message.datos.usuario, message.datos.token,
                                            message.datos.msgsalida, message.color)}>Responder</a>}
                                </span>
                            </div>
                        </div>
                    ))
                }
                </div>
                <form onSubmit={handleMessageSubmit}>
                    <div className='row'>
                        <div className="input-group">
                            <span className="input-group-text text-danger border-0" style={cancelarResp}
                                onClick={() => handleCancelar()}>x</span>
                            <span className="input-group-text border-0" style={colorRespuesta}>{userRespuesta == "" ? "@todos" : userRespuesta}</span>
                            <input
                                type="text"
                                placeholder="Mensaje"
                                className='form-control form-control-sm form-control-solid border-0'
                                value={inputMessage}
                                maxLength={150}
                                onChange={(e) => setInputMessage(e.target.value)} 
                                ref={inputRef}/>
                            <button type="submit" className="btn btn-sm btn-primary">Enviar</button>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer
                theme={localStorage.getItem("userTheme") === "dark" ? "dark" : "light"}
                position={toast.POSITION.BOTTOM_RIGHT}
            />
        </div>
    );
};

export default Chat;
