/* eslint-disable */
import React, { useState, lazy, Suspense, useEffect, useRef } from "react";
// import { Calculadora, Scanner } from "../../../Components";
import "./live.css";
import { FaWindowRestore, FaCalculator } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { NavLink, Link, useNavigate } from "react-router-dom";
const Filtro = lazy(() => import("../../../Components/filtro/Filtro"));
const Calculadora = lazy(() => import("../../../Components/caluladora/Calculadora"));
const Scanner = lazy(() => import("../../../Components/scanner/Scanner"));
const ScannerEspecial = lazy(() => import("../../../Components/scanner/ScannerFiltrado"));
import Ventana from "./Ventana";
import Main from "../../../layout/main";
import { useUserContext } from "../../../Providers/UserProvider";
const tooltip = (
    <Tooltip id="tooltip">
        <strong>Abrir en nueva ventana</strong>
    </Tooltip>
);
const tooltipF = (
    <Tooltip id="tooltipF">
        <strong>Solo ventanas flotantes</strong>
    </Tooltip>
);
const tooltipTC = (
    <Tooltip id="tooltipTC">
        <strong>Ver tipo de cambio</strong>
    </Tooltip>
);
const Live = () => {
    // let dataLive = []
    const { user, setUser } = useUserContext();
    const navigate = useNavigate();
    const [liveSure, setLiveSure] = useState([]);
    const [tc, setTc] = useState("normal");
    const [stake, setStake] = useState(user.stake);
    const [userTc, setUserTc] = useState(user.tipocambio);
    const [toggle, setToggle] = useState(false);
    const [onlyF, setOnlyF] = useState(false);
    const [disInput, setDisInput] = useState(true);
    const [trigger, setTrigger] = useState(0);
    const [showWindowPortal, setShowWindowPortal] = useState(false);
    const onOptionChange = (e) => {
        setTc(e.target.checked);
    };
    const openWindow = () => {
        setTrigger((trigger) => trigger + 1);
        setShowWindowPortal(true);
    };
    const closeWindowPortal = () => {
        setShowWindowPortal(false);
    };
    useEffect(() => {
        window.addEventListener("beforeunload", () => {
            closeWindowPortal();
        });
    }, []);
    const onlyFloat = (e) => {
        // console.log("liveSure", liveSure);
        var checkBox = document.getElementById("chck1");
        if (e.target.checked) {
            setToggle(false);
            setOnlyF(true);
            setDisInput(true);

            if (checkBox.checked == true) {
                checkBox.checked = false;
                checkBox.disabled = true;
            }
        } else {
            setOnlyF(false);
            if (liveSure.length > 0) {
                setDisInput(false);
                if (checkBox.checked == false) {
                    checkBox.checked = true;
                    checkBox.disabled = false;
                }
            }
        }
    };
    const setData = (data) => {
        // dataLive = data
        setLiveSure(data);
        if (data.length != 0) {
            if (onlyF) {
                openWindow();
            } else {
                setToggle(true);
                setDisInput(false);
                var checkBox = document.getElementById("chck1");
                if (checkBox.checked == false) {
                    checkBox.checked = true;
                }
            }
        }
    };
    return (
        <Main userStake={(datos) => setStake(datos)} userTC={(datos) => setUserTc(datos)}>
            <Suspense
                fallback={
                    <div className="py-6 d-flex justify-content-center align-items-center">
                        <h1>Cargando...</h1>
                    </div>
                }
            >
                <div className="row w-100 mx-auto">
                    <div className="col-md-6 col-sm-12 order-2 order-md-1">
                        <div className="card">
                            <Filtro type="live" />
                            <Scanner type={2} setLiveSure={(liveSure) => setData(liveSure)} />
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 mb-6 order-1 order-md-2 ">
                        <div className="d-flex flex-column position-relative calculadora_content">
                            <div className="card mb-4 w-100">
                                <div className="w-100 d-flex flex-column">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="tabs">
                                                <div className="tab">
                                                    <input type="checkbox" id="chck1" disabled={disInput} />
                                                    <label className="tab-label" htmlFor="chck1">
                                                        <div className="d-flex box_options ">
                                                            <div className="d-flex align-items-center cursor-pointer me-2">
                                                                {/* <h6 className="fs-14 fw-bolder px-1 mb-0 text-white">CALCULADORA</h6> */}
                                                                <FaCalculator size={15}></FaCalculator>
                                                            </div>
                                                            <div className="form-check form-switch form-check-custom form-check-success form-check-solid me-2">
                                                                <OverlayTrigger placement="top" overlay={tooltipF}>
                                                                    <input
                                                                        className="form-check-input ms-1 cursor-pointer"
                                                                        type="checkbox"
                                                                        value="1"
                                                                        id="flexSwitchDefault"
                                                                        onChange={onlyFloat}
                                                                    />
                                                                </OverlayTrigger>
                                                            </div>
                                                            {/* <OverlayTrigger placement="top" overlay={tooltip}>
                                                                <button className="btn btn-warning btn-sm" onClick={openWindow}>
                                                                    <FaWindowRestore></FaWindowRestore>
                                                                </button>
                                                            </OverlayTrigger> */}
                                                            <OverlayTrigger placement="top" overlay={tooltipTC}>
                                                                <div className="d-flex gap-2 justify-content-center align-items-center form-check form-check-custom form-check-primary form-check-solid">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        name="tipo_cambio"
                                                                        id="option-2"
                                                                        value={"tc"}
                                                                        onChange={onOptionChange}
                                                                    />
                                                                    <label htmlFor="option-2">TC</label>
                                                                </div>
                                                            </OverlayTrigger>
                                                            {/* <input
                                                                type="radio"
                                                                name="tipo_cambio"
                                                                id="option-1"
                                                                value={"normal"}
                                                                onChange={onOptionChange}
                                                                defaultChecked                                                                
                                                            />
                                                            <input type="radio" name="tipo_cambio" id="option-2" value={"tc"} onChange={onOptionChange} /> */}
                                                            {/* <label htmlFor="option-1" className="option option-1">
                                                                <div className="dot"></div>
                                                                <span>Normal</span>
                                                            </label>
                                                            <label htmlFor="option-2" className="option option-2">
                                                                <div className="dot"></div>
                                                                <span>TC</span>
                                                            </label> */}
                                                        </div>
                                                    </label>
                                                    <div className="tab-content_cal card_contenido">
                                                        {/* {toggle && <Calculadora actSurebet={liveSure} tc={tc} stake={stake} userTc={userTc} />} */}
                                                        <Calculadora actSurebet={liveSure} tc={tc} stake={stake} userTc={userTc} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div id="cal_cont_acordion" className="card_contenido colapse">
                                        {toggle && <Calculadora actSurebet={liveSure} tc={tc} stake={stake} userTc={userTc} />}
                                    </div> */}
                                </div>
                            </div>
                            <div className="card d-none d-md-block">
                                <div className=" px-6 py-3 bg_surebet_primary   fw-bolder fs-14 d-flex justify-content-between rounded-top">
                                    <div className="d-flex align-items-center ">
                                        <i className="bet-calendar fs-5 color_gray"></i>
                                        <h6 className="fs-14 fw-bolder px-1 mb-0 text-white">FILTRADO ESPECIAL</h6>
                                    </div>
                                </div>
                                <ScannerEspecial type={2} setLiveSure={(liveSure) => setData(liveSure)} />
                            </div>
                        </div>
                    </div>
                    {showWindowPortal ? (
                        <Ventana
                            trigger={trigger}
                            closeWindowPortal={closeWindowPortal}
                            datos={liveSure}
                            type={"live"}
                            tc={tc}
                            stake={stake}
                            userTc={userTc}
                        ></Ventana>
                    ) : (
                        <></>
                    )}
                </div>
            </Suspense>
        </Main>
    );
};

export default Live;
