/* eslint-disable */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import "../auth.css";
import AuthService from "../../../api/services/authService";

const schema = yup.object({
    email: yup.string().email().required("Este campo es requerido"),
});

const ForgotPass = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const sendEmailForgotten = async (data) => {
        setLoading(true)
        const body = {
            correo: data.email,
        };
        const resp = await AuthService.restorePassword(body);
        if (resp.status) {
            Swal.fire({
                title: "Enviado",
                icon: "success",
                text: "Se ha enviado un enlace para restaurar su contraseña a su correo.",
            });
            navigate("/login");
        } else {
            Swal.fire({
                title: "Error",
                icon: "error",
                text: "Correo no registrado.",
            });
        }
        setLoading(false)
        //console.log("body", body);
    };
    return (
        <div className="d-flex flex-column flex-root h-100">
            <div className="row h-100 w-100 gx-0">
                {/* <div className="d-none d-lg-block col-lg-6 login_aside h-100"></div> */}
                <div className="col-12 h-100">
                    <div className="h-100 d-flex flex-center w-100 content_login">
                        <div className="login_form w-100 w-lg-600px p-15  flex-center mx-10 mx-lg-4 d-flex flex-column gap-8">
                            <div className="py-2 pb-lg-5">
                                <img alt="Logo" className="logo_login " />
                            </div>
                            <form
                                className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                                id="kt_password_reset_form"
                                onSubmit={handleSubmit(sendEmailForgotten)}
                            >
                                <div className="text-center mb-10">
                                    <h1 className="text-black mb-3">¿Olvidaste tu contraseña?</h1>
                                    <div className="text-gray-400 fw-semibold fs-4">
                                        Ingresa tu correo electrónico para restaurar tu contraseña.
                                    </div>
                                </div>
                                <div className="fv-row mb-10 fv-plugins-icon-container">
                                    <label className="form-label fw-bold text-gray-900 fs-6">Email</label>
                                    <input
                                        className="form-control border form_select_login border-login"
                                        type="email"
                                        placeholder="Email"
                                        name="email"
                                        autoComplete="off"
                                        {...register("email")}
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback">
                                        {errors.email?.type === "required" && (
                                            <div data-field="email" data-validator="regexp">
                                                No ha ingresado un correo valido
                                            </div>
                                        )}
                                        {errors.email?.type === "email" && (
                                            <div data-field="email" data-validator="regexp">
                                                Debe de ingresar un correo
                                            </div>
                                        )}
                                    </div>
                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                </div>
                                <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                                    <button disabled={loading} type="Submit" id="kt_password_reset_submit" className="btn btn-lg btn-primary fw-bold me-4">
                                        {loading ? (
                                            <span className="indicator-label">
                                                Enviando...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        ) : (
                                            <span className="indicator-label ">Enviar</span>
                                        )}
                                    </button>
                                    <Link to="/login" className="btn btn-lg btn-danger fw-bold">
                                        Cancelar
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPass;
