/* eslint-disable */
import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../Components/utils/BreadCrumb";
import { FaShareSquare } from "react-icons/fa";
import clones from "../../Assets/clones.json";
import Main from "../../layout/main";
import "./style.scss";
const url = window.location.origin;
const Clones = () => {
    //hooks
    const [items, setItems] = useState(clones);
    //BreadCrumb
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/clones", name: "Clones", active: false },
    ];
    //LOGICA PARA ELIMINAR ELEMENTO DEL DOM
    return (
        <Main>
            <div className="container-xxl mw-100 ">
            <div className="toolbar" id="kt_toolbar">
                <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                    <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                </div>
            </div>
            <div className="card">
                <div className="card-title">
                    <div className="d-flex align-items-center position-relative my-1"></div>
                </div>
                <div className="card-body ">
                    <div className="d-none d-lg-flex row mx-0">
                        {items.map((item) => {
                            return (
                                <div className="col-card" key={item.id}>
                                    <div className="wrapper_clone">
                                        <div className="card_wrapper">
                                            <img loading={item.id == 1 ? "eager" : "lazy"} src={url + item.img} alt="" />
                                            <div className="info d-flex flex-column text-center">
                                                <button>
                                                    <a href={item.url} target="_blank">
                                                        {item.casa}
                                                    </a>{" "}
                                                </button>
                                                <div className="info_content d-flex flex-column text-center mt-4">
                                                    {item.items.map((lista) => {
                                                        return (
                                                            <a
                                                                key={lista.id}
                                                                href={lista.url}
                                                                target="_blank"
                                                                className=" shadow-sm p-2 d-flex justify-content-center align-items-center rounded "
                                                            >
                                                                <p className="mb-0">{lista.name}</p>
                                                            </a>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="d-flex d-lg-none row mx-0">
                        {items.map((item) => {
                            return (
                                <div className="col-12 col-sm-6" key={item.id}>
                                    <div className="card shadow-sm mb-6 border">
                                        <div
                                            className="card-header card_nom_header"
                                            style={{
                                                backgroundImage: "url(" + url + item.img + ")",
                                            }}
                                        >
                                            <div className="header_screen position-absolute top-0 w-100 h-100"></div>
                                            <h3 className="card-title">{item.casa}</h3>
                                            <div className="card-toolbar">
                                                <button type="button" className="btn btn-sm btn-light">
                                                    <a target="_blank" href={item.url} className="text_link">
                                                        <FaShareSquare></FaShareSquare> Ir al sitio
                                                    </a>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="card-body card_body_clone d-flex flex-column p-4">
                                            {item.items.map((lista) => {
                                                return (
                                                    <a
                                                        key={lista.id}
                                                        href={lista.url}
                                                        target="_blank"
                                                        className=" mb-4  py-4 d-flex justify-content-center align-items-center rounded h-100"
                                                    >
                                                        <p className="mb-0">{lista.name}</p>
                                                    </a>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
        </Main>
    );
};

export default Clones;
