/* eslint-disable */

import UseService from "../UseServices";

const EventosService = {
    async getEventos(data) {
        var resp = await UseService.postEvent("events/eventos", data);
        return resp;
    },
    async getGraficos(data) {
        var resp = await UseService.postEvent("events/eventosgrafica", data);
        return resp;
    },
};

export default EventosService;