/* eslint-disable */
import React from "react";
import Breadcrumbs from "../../../Components/utils/BreadCrumb";
import { FaHandHoldingUsd, FaHandHolding, FaMoneyBill } from "react-icons/fa";
import { useUserContext } from "../../../Providers/UserProvider";
import CajaInicio from "./caja_inicio/index";
import "styled-components";
// import "react-datalist-input/dist/styles.css";
import CajaFinal from "./caja_final";
import CajaMovimientos from "./caja_movimientos";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Col from "react-bootstrap/Col";
import Main from "../../../layout/main";
const Movimientos = () => {
    const { user } = useUserContext();
    // var firstTabEl = document.querySelector("#myTab li:last-child a");
    // console.log("firstTabEl", firstTabEl);
    // if (firstTabEl != null) {
    //     const firstTab = new bootstrap.Tab(firstTabEl);
    //     console.log("firstTab", new bootstrap.Tab(firstTabEl));
    //     firstTab.show();
    // }

    // firstTab.show();
    //BreadCrumb
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/operaciones", name: "Operaciones", active: false },
        { path: "/movimientos", name: "Saldos en casas y movimientos", active: false },
    ];
    return (
        user.frqpoint == 0 ?
            <div className="container-xxl mw-100 px-0">
                <div className="toolbar" id="kt_toolbar">
                    <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Col sm={12} className="my-6">
                            <Nav variant="pills" className="flex-row gap-6">
                                <Nav.Item>
                                    <Nav.Link eventKey="first" className=" nav_mov nav-link border btn align-items-center d-flex gap-4">
                                        {" "}
                                        <span>
                                            <FaHandHoldingUsd size={18}></FaHandHoldingUsd>
                                        </span>{" "}
                                        <strong>Caja Inicio</strong>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second" className="nav_mov nav-link btn border align-items-center d-flex gap-4">
                                        <FaHandHolding size={18}></FaHandHolding> <strong>Caja Final</strong>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="three" className="nav_mov nav-link btn border align-items-center d-flex gap-4">
                                        <FaMoneyBill size={18}></FaMoneyBill> <strong>Movimientos</strong>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <CajaInicio></CajaInicio>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second" unmountOnExit={true}>
                                    <CajaFinal></CajaFinal>
                                </Tab.Pane>
                                <Tab.Pane eventKey="three" unmountOnExit={true}>
                                    <CajaMovimientos></CajaMovimientos>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>

            </div>
            :
            <Main>
                <div className="container-xxl mw-100">
                    <div className="toolbar" id="kt_toolbar">
                        <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                            <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                        </div>
                    </div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col sm={12} className="my-6">
                                <Nav variant="pills" className="flex-row gap-6">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first" className=" nav_mov nav-link border btn align-items-center d-flex gap-4">
                                            {" "}
                                            <span>
                                                <FaHandHoldingUsd size={18}></FaHandHoldingUsd>
                                            </span>{" "}
                                            <strong>Caja Inicio</strong>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second" className="nav_mov nav-link btn border align-items-center d-flex gap-4">
                                            <FaHandHolding size={18}></FaHandHolding> <strong>Caja Final</strong>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="three" className="nav_mov nav-link btn border align-items-center d-flex gap-4">
                                            <FaMoneyBill size={18}></FaMoneyBill> <strong>Movimientos</strong>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <CajaInicio></CajaInicio>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second" unmountOnExit={true}>
                                        <CajaFinal></CajaFinal>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="three" unmountOnExit={true}>
                                        <CajaMovimientos></CajaMovimientos>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>

                </div>
            </Main>
    );
};

export default Movimientos;
