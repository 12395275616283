/* eslint-disable */

import UseService from "../UseServices";

const ProfileService = {
    async changeAvatar(data) {
        var resp = await UseService.put("users/updateavatar/", data);
        return resp;
    },
    async updateUser(data) {
        var resp = await UseService.put("users/datospersonales/", data);
        return resp;
    },
    async updatePassword(data) {
        var resp = await UseService.put("users/password/", data);
        return resp;
    },

    async reportSureBet(data) {
        var resp = await UseService.post("users/reportesurebet/", data);
        return resp;
    },
};
export default ProfileService;
