/* eslint-disable */
import React, { useState, useEffect } from "react";
import Main from "../../layout/main";
import Breadcrumbs from "../../Components/utils/BreadCrumb";
import MUIDataTable from "mui-datatables";
import { useForm, Controller } from "react-hook-form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { useUserContext } from "../../Providers/UserProvider";
import GastosService from "../../api/services/gastosService";
import moment from "moment/moment";
//TOOLTIPS
const tooltipE = (
    <Tooltip id="tooltipE">
        <strong>Preview</strong>
    </Tooltip>
);
const tooltipP = (
    <Tooltip id="tooltipP">
        <strong>Eliminar</strong>
    </Tooltip>
);
/* DATA ESTATICA */
const today = new Date();
const tipos = [
    {
        text: "Costos Fijos",
        value: 1,
        motivos: [
            {
                text: "Planilla",
                value: "Planilla",
            },
            {
                text: "Servicio Internet",
                value: "Servicio Internet",
            },
            {
                text: "Servicio Telefonia",
                value: "Servicio Telefonia",
            },
            {
                text: "Servicio Mantenimiento",
                value: "Servicio Mantenimiento",
            },
            {
                text: "Pago Prestamos",
                value: "Pago Prestamos",
            },
            {
                text: "Escaner",
                value: "Escaner",
            },
            {
                text: "Otros",
                value: "Otros",
            },
        ],
    },
    {
        text: "Costos Variables",
        value: 2,
        motivos: [
            {
                text: "Servicio Luz",
                value: "Servicio Luz",
            },
            {
                text: "Servicio Agua",
                value: "Servicio Agua",
            },
            {
                text: "Utiles de Oficina",
                value: "Utiles de Oficina",
            },
            {
                text: "Productos de Limpieza",
                value: "Productos de Limpieza",
            },
            {
                text: "Comisiones",
                value: "Comisiones",
            },
            {
                text: "ITF",
                value: "ITF",
            },
            {
                text: "Otros",
                value: "Otros",
            },
        ],
    },
];

const tiposG = [
    {
        text: "Dolares",
        value: 1
    },
    {
        text: "Moneda Local",
        value: 2
    }
]
/* FIN */
const Gastos = () => {
    /* -----USEFORM----*/
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        reset,
        formState: { errors },
    } = useForm();

    /* -----FIN USEFORM----*/

    /* -----BREADCRUMBS----*/
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/gastos", name: "Costos", active: true },
    ];
    /* -----FIN BREADCRUMBS----*/

    /* -----MUIDATATABLE----*/
    //COLUMNAS
    const columns1 = [
        {
            name: "id_gasto",
            label: "",
            options: {
                display: false,
            },
        },
        {
            name: "fecha",
            label: "Fecha",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span>{moment(value).format("DD-MM-yyyy")}</span>;
                },
            },
        },
        {
            name: "tipo",
            label: "Tipo",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span className={`badge badge-square badge-lg p-4 ${value == 1 ? "badge-success" : "badge-warning"}`}>{value == 1 ? "Costo Fijo" : "Costo Variable"}</span>;
                },
            },
        },
        {
            name: "tipo_moneda",
            label: "Moneda",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span>{value == 1 ? 'Dolares' : 'Moneda Local'}</span>;
                },
            },
        },
        {
            name: "motivo",
            label: "Motivo",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "monto",
            label: "Monto",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span> {parseFloat(value).toFixed(2)}</span>;
                },
            },
        },
        {
            name: "cambio_monto",
            label: "Dolares",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "Acciones",
            label: "ACCIONES",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="d-flex gap-3 flex-wrap justify-content-center">
                            <OverlayTrigger placement="top" overlay={tooltipE}>
                                <button className="btn btn-success btn-sm btn-icon" onClick={() => editGasto(tableMeta)}>
                                    <FaPencilAlt></FaPencilAlt>
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={tooltipP}>
                                <button className="btn btn-danger btn-sm btn-icon" onClick={() => handleMyState(tableMeta)}>
                                    <FaTrash></FaTrash>
                                </button>
                            </OverlayTrigger>
                        </div>
                    );
                },
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
    ];
    const options = {
        print: false,
        viewColumns: false,
        filter: false,
        filterType: "dropdown",
        selectableRows: "none",
        responsive: "standard",
        fixedSelectColumn: false,
        download: true,
        textLabels: {
            body: {
                noMatch: "No se encontraron datos",
                toolTip: "Sort",
                columnHeaderTooltip: (column) => `Ordenador por ${column.label}`,
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Elementos por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscador",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtrar Tabla",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Resetear",
            },
            viewColumns: {
                title: "Mostrar Columnas",
                titleAria: "Mostrar/Ocultar Columnas",
            },
            selectedRows: {
                text: "fila(s) seleccionada(s)",
                delete: "Eliminar",
                deleteAria: "Eliminar fila seleccionada",
            },
            centeredTableHead: {
                "& > span": {
                    justifyContent: "center",
                },
            },
        },
    };

    /* -----FIN MUIDATATABLE----*/

    /* -----HOOKS----*/
    const { user } = useUserContext();
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [totalFijo, setTotalFijo] = useState(0);
    const [totalVa, setTotalVa] = useState(0);
    const [filtros, setFiltros] = useState({
        fecha: moment(today).format("yyyy-MM-DD"),
    });
    const [isEdit, setIsEdit] = useState(false);
    const [motivos, setMotivos] = useState([]);
    const [fechaM, setFechaM] = useState(moment(today).format("yyyy-MM-DD"));
    /* -----FIN HOOKS----*/

    /* -----DATOS----*/
    const refreshToken = localStorage.getItem("refreshToken");
    /* -----FIN DATOS----*/

    /* -----METODOS----*/
    //LISTAR DATA
    const getData = async () => {
        setLoading(true);
        const body = {
            refreshToken: "Bearer " + refreshToken,
            fecha: filtros.fecha,
            tcambio: user.tipocambio,
        };
        // console.log("body", body);
        const resGastos = await GastosService.getGastos(body);
        if (resGastos.status) {
            let tot = 0;
            let tot1 = 0;
            let tot2 = 0;
            setItems(resGastos.data[0]);
            for (let index = 0; index < resGastos.data[0].length; index++) {
                const element = resGastos.data[0][index];
                tot += parseFloat(element.cambio_monto)
                if (element.tipo == 1) {
                    tot1 += parseFloat(element.cambio_monto)
                }
                if (element.tipo == 2) {
                    tot2 += parseFloat(element.cambio_monto)
                }
            }
            setTotal(tot)
            setTotalFijo(tot1)
            setTotalVa(tot2)
        }
        changeType(1);
        // console.log("resGastos", resGastos);
        setLoading(false);
    };
    //EDITAR DATA
    //CAMBIAR ESTADO
    const handleMyState = (data) => {
        // console.log("data", data);
        let datosMov = {
            idgasto: data.rowData[0],
        };
        // console.log("datosMov", datosMov);
        Swal.fire({
            icon: "warning",
            title: `¿Seguro que desea eliminar este gasto?`,
            showCancelButton: true,
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                confirmChange(datosMov);
            }
        });
    };
    //CONFIRMACIÓN DE ELIMINACIÓN
    const confirmChange = async (data) => {
        const body = {
            idgasto: data.idgasto,
            refreshToken: "Bearer " + refreshToken,
        };
        //console.log("body", body);
        const resp = await GastosService.statusGastos(body);
        // console.log("resp", resp);
        if (resp.status) {
            Swal.fire("Eliminado!", "", "success");
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al actualizar los datos.",
            });
        }
        getData();
    };
    //EDITAR CUENTA
    const editGasto = async (data) => {
        setIsEdit(true)
        let itemSelectE = {};
        itemSelectE = items[data.rowIndex];
        $("#kt_datepicker_25").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            // minDate: "today",
            onChange: function (selectedDates, dateStr, instance) {
                setFechaM(moment(dateStr).format("yyyy-MM-DD"));
            },
            defaultDate: moment(itemSelectE.fecha).format("yyyy-MM-DD"),
        });
        setValue("id_gasto", itemSelectE.id_gasto)
        setValue("tipo", itemSelectE.tipo);
        setValue("tipomoneda", itemSelectE.tipo_moneda);
        changeType(itemSelectE.tipo)
        await new Promise((r) => setTimeout(r, 200));
        setValue("motivo", itemSelectE.motivo);
        setValue("observacion", itemSelectE.observacion);
        setValue("monto", itemSelectE.monto)
        // console.log('itemSelectE', itemSelectE)
        $("#modal_movs_add_gastos").modal("show");
    }
    //AGREGAR CUENTA
    const addGasto = () => {
        setIsEdit(false)
        $("#kt_datepicker_25").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            // minDate: "today",
            onChange: function (selectedDates, dateStr, instance) {
                setFechaM(moment(dateStr).format("yyyy-MM-DD"));
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        setValue("tipo", 1);
        setValue("motivo", "Planilla");
        setValue("tipomoneda", 1);
        setValue("observacion", "");
        setValue("monto", null)
        $("#modal_movs_add_gastos").modal("show");
    };
    //RESETEAR FORMS
    const resetForm = () => {
        setIsEdit(false)
        reset({
            fechaM: moment(today).format("yyyy-MM-DD"),
            tipo: 1,
            tipomoneda: 1,
            observacion: "",
            motivo: "",
            monto: ""
        });
    };

    const montoValidate = (e) => {
        if (
            (e.keyCode < 48 || e.keyCode > 57) &&
            e.keyCode != 110 &&
            e.keyCode != 190 &&
            e.keyCode != 8 &&
            e.keyCode != 9 &&
            e.keyCode != 46 &&
            (e.keyCode < 96 || e.keyCode > 105)
        ) {
            e.preventDefault();
        }
    };
    //ENVIAR DATA
    const onSubmit = async (data) => {
        // console.log("data", data);
        setLoading2(true);
        const datos = data;
        var body = {
            monto: parseFloat(datos.monto),
            fecha: datos.fechaM,
            tipo: datos.tipo,
            tipomoneda: parseInt(datos.tipomoneda),
            tcambio: user.tipocambio,
            motivo: datos.motivo,
            observacion: datos.observacion,
            refreshToken: "Bearer " + refreshToken,
        };
        var resp = ""
        if (isEdit) {
            body.idgasto = datos.id_gasto
            resp = await GastosService.updateGastos(body);
        } else {
            resp = await GastosService.addGastos(body);
        }

        $("#modal_movs_add_gastos").modal("hide");
        if (resp.status == true) {
            Swal.fire({
                title: "Registrado",
                icon: "success",
                text: "Datos registrados con éxito.",
            });
        } else if (resp.status == 202) {
            Swal.fire({
                title: "Error!",
                icon: "warning",
                text: "¡El registro existe previamente o datos incorrectos!",
            });
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al registrar los datos.",
            });
        }
        setLoading2(false);
        getData();

        // resetForm();
    };
    const changeType = (val) => {
        const moti = tipos.filter((e) => e.value == parseInt(val));
        if (moti.length > 0) {
            setMotivos(moti[0].motivos);
        }
        // console.log("val", val);
        // console.log("moti", moti);
        // console.log('moti[0].motivos[0].value',moti[0].motivos[0].value)
        setValue("motivo", "");
    };
    /* -----FIN METODOS----*/

    /* -----MOUNTED----*/
    useEffect(() => {
        // getData();
        resetForm();
    }, []);
    useEffect(() => {
        $("#kt_datepicker_24").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            onChange: function (selectedDates, dateStr, instance) {
                setFiltros({ ...filtros, fecha: moment(dateStr).format("yyyy-MM-DD") });
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        $("#kt_datepicker_25").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            // minDate: "today",
            onChange: function (selectedDates, dateStr, instance) {
                setFechaM(moment(dateStr).format("yyyy-MM-DD"));
                setValue("fechaM", moment(dateStr).format("yyyy-MM-DD"));
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
    }, []);

    /* -----FIN MOUNTED----*/
    return (
        <div className="container-xxl mw-100 px-0">
            <div className="toolbar" id="kt_toolbar">
                <div className="w-100 px-2 d-flex flex-stack flex-wrap flex-sm-nowrap">
                    <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                </div>
            </div>
            <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                <div className="overlay-wrapper">
                    <div className="card p-0 shadow">
                        <div className="card-header border-0 p-6">
                            <div className="card-toolbar w-100">
                                <div className="d-flex flex-column flex-lg-row  w-100 justify-content-between h-100">
                                    <div className="row mx-0 w-100">
                                        <div className="col-md-4 ">
                                            <label htmlFor="" className="form-label">
                                                Fecha
                                            </label>
                                            <input
                                                className="form-control form-control-solid"
                                                placeholder="Fecha Inicio"
                                                id="kt_datepicker_24"
                                            />
                                        </div>
                                        <div className="col-md-8 mt-5 mt-md-0 d-flex justify-content-between">
                                            <div className="d-flex align-content-end justify-content-md-start justify-content-center h-100  w-100">
                                                <button
                                                    disabled={loading}
                                                    type="button"
                                                    className="btn btn-primary mt-auto me-3"
                                                    onClick={getData}
                                                >
                                                    {loading ? (
                                                        <span className="indicator-label">
                                                            Buscando...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    ) : (
                                                        <span className="indicator-label ">
                                                            <i className="bi bi-search" /> Buscar
                                                        </span>
                                                    )}
                                                </button>
                                                <div className="d-flex">
                                                    <button type="button" className="btn btn-success mt-auto me-2" onClick={addGasto}>
                                                        <i className="bi bi-plus-circle fs-4 me-2" /> Agregar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <MUIDataTable
                                title={
                                    <div className="d-flex gap-3 px-4">
                                        <span className="fw-bolder fs-14">Lista de Consolidados</span> |{" "}
                                        <span className="fw-bolder">Suma de Costos Fijos: {parseFloat(totalFijo).toFixed(2)}</span>
                                        |{" "}
                                        <span className="fw-bolder">Suma de Costos Variables: {parseFloat(totalVa).toFixed(2)}</span>
                                        |{" "}
                                        <span className="fw-bolder">Suma de Costos Totales: {parseFloat(total).toFixed(2)}</span>
                                    </div>
                                }
                                data={items}
                                columns={columns1}
                                options={options}
                            />
                        </div>
                    </div>
                    <div className="modal fade" id="modal_movs_add_gastos" tabIndex="-1" aria-modal="true" role="dialog">
                        <div className="modal-dialog modal-dialog-centered mw-650px">
                            <div className="modal-content">
                                <form
                                    className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                    id="modal_movs_add_mov_form"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <div className="modal-header" id="modal_movs_add_mov_header">
                                        <h2 className="fw-bold">{isEdit ? 'Editar' : 'Agregar'} Gasto</h2>
                                        <div
                                            id="modal_movs_add_mov_close"
                                            className="btn btn-icon btn-sm btn-active-icon-primary"
                                            data-bs-dismiss="modal"
                                            onClick={resetForm}
                                        >
                                            <span className="svg-icon svg-icon-1">
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <rect
                                                        opacity="0.5"
                                                        x="6"
                                                        y="17.3137"
                                                        width="16"
                                                        height="2"
                                                        rx="1"
                                                        transform="rotate(-45 6 17.3137)"
                                                        fill="currentColor"
                                                    ></rect>
                                                    <rect
                                                        x="7.41422"
                                                        y="6"
                                                        width="16"
                                                        height="2"
                                                        rx="1"
                                                        transform="rotate(45 7.41422 6)"
                                                        fill="currentColor"
                                                    ></rect>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="modal-body py-5 px-lg-10">
                                        <div
                                            id="modal_movs_add_mov_scroll"
                                            data-kt-scroll-dependencies="#modal_movs_add_mov_header"
                                            data-kt-scroll-wrappers="#modal_movs_add_mov_scroll"
                                        >
                                            <div className="row mx-0 w-100 mb-7 fv-plugins-icon-container">
                                                <div className="col-12 mb-1">
                                                    <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                        <span className="">Fecha</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-control-solid"
                                                        placeholder="Fecha"
                                                        id="kt_datepicker_25"
                                                        value={fechaM}
                                                        {...register("fechaM", {
                                                            required: true,
                                                        })}
                                                    />
                                                    {errors.fechaM?.type === "required" && (
                                                        <p className="text-danger">El campo es requerido</p>
                                                    )}
                                                </div>
                                                <div className="col-12 mb-1">
                                                    <label htmlFor="status" className="form-label">
                                                        Moneda
                                                    </label>
                                                    <select
                                                        className="select2-selection select2-selection--single form-select form-select-solid "
                                                        name="status"
                                                        id="status"
                                                        {...register("tipomoneda")}
                                                    >
                                                        {tiposG.map((cuenta, index) => (
                                                            <option key={index} value={cuenta.value}>
                                                                {cuenta.text}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-12 mb-1">
                                                    <label htmlFor="status" className="form-label">
                                                        Tipo
                                                    </label>
                                                    <select
                                                        className="select2-selection select2-selection--single form-select form-select-solid "
                                                        name="status"
                                                        id="status"
                                                        {...register("tipo")}
                                                        onChange={(e) => changeType(e.target.value)}
                                                    >
                                                        {tipos.map((tip) => (
                                                            <option key={tip.value} value={tip.value}>
                                                                {tip.text}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-12 mb-1">
                                                    <label htmlFor="status" className="form-label">
                                                        Motivo
                                                    </label>
                                                    <select
                                                        className="select2-selection select2-selection--single form-select form-select-solid "
                                                        name="status"
                                                        id="status"
                                                        {...register("motivo", {
                                                            required: true,
                                                        })}
                                                    >
                                                        <option value="">Seleccionar</option>
                                                        {motivos.map((moti, index) => (
                                                            <option key={index} value={moti.value}>
                                                                {moti.text}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.motivo?.type === "required" && (
                                                        <p className="text-danger">El campo es requerido</p>
                                                    )}
                                                </div>
                                                <div className="col-12 mb-1">
                                                    <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                        <span className="">Monto</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-solid"
                                                        placeholder="Monto"
                                                        name="monto"
                                                        onKeyDown={(e) => montoValidate(e)}
                                                        {...register("monto", {
                                                            required: true,
                                                            valueAsNumber: true,
                                                            validate: (value, formValues) => parseFloat(value) > 0,
                                                        })}
                                                    />
                                                    {errors.monto?.type === "required" && (
                                                        <p className="text-danger">El campo es requerido</p>
                                                    )}
                                                    {errors.monto?.type === "validate" && (
                                                        <p className="text-danger">Debe ser mayor a 0</p>
                                                    )}
                                                </div>
                                                <div className="col-12 mb-1">
                                                    <label className="fs-6 fw-semibold my-2" htmlFor="observacion">
                                                        <span className="">Observacion</span>
                                                    </label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control form-control-solid"
                                                        placeholder="Observacion"
                                                        name="observacion"
                                                        id="observacion"
                                                        rows={4}
                                                        {...register("observacion")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer flex-center">
                                        <button
                                            type="reset"
                                            id="modal_movs_add_mov_cancel"
                                            className="btn btn-danger me-3"
                                            data-bs-dismiss="modal"
                                            onClick={resetForm}
                                        >
                                            Cerrar
                                        </button>
                                        <button type="submit" id="modal_movs_add_mov_submit" className="btn btn-primary">
                                            {loading2 ? (
                                                <span className="indicator-label">
                                                    {isEdit ? 'Actualizando...' : 'Agregando....'}
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            ) : (
                                                <span className="indicator-label">{isEdit ? 'Editar' : 'Agregar'}</span>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {loading ? (
                    <div className="overlay-layer card-rounded bg-black bg-opacity-50">
                        <div className="d-flex flex-column align-items-center mt-10">
                            <div className="spinner-border text-white" role="status"></div>
                            <span>Buscando datos...</span>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};
export default Gastos;
