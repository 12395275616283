/* eslint-disable */
import axios from "axios";

// export default axios.create({
//     baseURL: REACT_APP_URL_API
// })

const execute = async(request) => {
    var resData = await axios(request)
        .then((res) => {
            if (res.status == 202) {
                return { data: res.data, status: 202 };
            }
            return { data: res.data, status: true };
        })
        .catch((e) => {
            if (e.response) {
                if (e.response.status == 401 || e.response.status == 403) {
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("userCca");
                    window.location.href = "/";
                }
                return { data: e.response.data, status: false, code: e.response.status };
            } else {
                return { data: e.response, status: false, code: e.response.status };
            }
        });
    return resData;
};

export default execute;