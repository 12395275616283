/* eslint-disable */

import UseService from "../UseServices";

const OperadoresService = {
    async getOperadores(data) {
        var resp = await UseService.post("operadores/list", data);
        return resp;
    },
};
export default OperadoresService;