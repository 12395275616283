/* eslint-disable */

import UseService from "../UseServices";

const ValidateService = {
    async validateToken(data) {
        var resp = await UseService.post("validate/", data);
        return resp;
    },
};
export default ValidateService;