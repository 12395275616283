/* eslint-disable */
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../Components/utils/BreadCrumb";
import KRGlue from "@lyracom/embedded-form-glue";
import { useParams } from "react-router-dom";
import { useUserContext } from "../../../Providers/UserProvider";
import { useNavigate } from "react-router-dom";
import { FaClone, FaRegIdCard, FaLocationArrow, FaMobileAlt, FaEnvelope, FaRegUserCircle } from "react-icons/fa";
import { FcOk, FcCancel } from "react-icons/fc";
import CheckPay from "../../../Components/utils/icons/CheckPay";
import moment from "moment/moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PlanesService from "../../../api/services/planesService";
import "../planes.scss";
import "../../../Assets/css/lyra.css"
import Main from "../../../layout/main"

let formToken = "DEMO-TOKEN-TO-BE-REPLACED";
const endpoint = process.env.REACT_APP_END_POINT;
const publicKey = process.env.REACT_APP_PUBLIC_KEY;
const today = new Date();
const tooltipC = (
    <Tooltip id="tooltipC">
        <strong>Copiar</strong>
    </Tooltip>
);
const copyCC = async (id) => {
    var aux = document.createElement("input");
    aux.setAttribute("value", document.getElementById(id).innerHTML);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    var buttonR = document.getElementById("btn_" + id);
    buttonR.classList.toggle("btn_copy");
    await new Promise((r) => setTimeout(r, 1000));
    buttonR.classList.toggle("btn_copy");
};

const DetallePlanes = ({ params }) => {
    //DATOS
    const navigate = useNavigate();
    const refreshToken = localStorage.getItem("refreshToken");
    const { user, setLocalStorage } = useUserContext();
    const urlBase = window.location.origin + "/assets/media/avatars/";
    const { dataPlan } = useUserContext();
    const { plan } = useParams();
    //Hooks
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [loadingForm, setLoadingForm] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [status, setStatus] = useState(false);
    const [isFinal, setIsFinal] = useState(false);
    const [existCupon, setExistCupon] = useState(false)
    const [dscto, setDscto] = useState(0)
    const [total, setTotal] = useState(dataPlan.precio.toFixed(2))
    const [nroCupon, setNroCupon] = useState("")
    const [cupon, setCupon] = useState(null)
    const [loadCup, setLoadCup] = useState(false);
    //BreadCrumb
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/planes", name: "Planes", active: true },
        { name: "Detalle", active: false },
    ];
    const modalPay = async () => {
        setIsLoading(true);
        //DATOS A ENVIAR Y GENERAR TOKEN
        const body = {
            refreshToken: "Bearer " + refreshToken,
            paymentConf: {
                amount: (total * 100) <= 0 ? 100 : total * 100,
                currency: "USD",
                customer: {
                    email: user.correo,
                    billingDetails: {
                        title: "Paquete prueba",
                        firstName: user.nombres,
                        lastName: user.apellidos,
                        phoneNumber: user.celular,
                        identityCode: dataPlan.id,
                    },
                },
            },
        };
        //SERVICIO DE CREAR PAGO
        const respPay = await PlanesService.createPayment(body);
        //SI ES EXITOSO
        if (respPay.status) {
            //TOMANDO EL TOKEN GENERADO
            formToken = respPay.data.token;
            //ENVIANDO A LA LIBRERIA EL TOKEN Y TRAYENDO EL FORMULARIO
            const respuesta = await KRGlue.loadLibrary(endpoint, publicKey);
            //CONFIGURANDO EL IDIOMA DEL FORMULARIO
            respuesta.KR.setFormConfig({
                formToken: formToken,
                "kr-language": "es-US",
            });
            //AÑADIENDO EL FORMULARIO AL DIV
            const respForm = await respuesta.KR.addForm("#myPaymentForm");
            //TRAE ALGO EL RESPFORM
            if (respForm) {
                await respuesta.KR.showForm(respForm.result.formId);
            }
            //FUNCION PARA ENVIAR LOS DATOS DE TARJETA
            const aaaa = await respuesta.KR.onSubmit(async (paymentData) => {
                setLoadingForm(true);
                //SERVICIO PARA VALIDAR PAGO
                const respValidate = await PlanesService.validatePayment({
                    paymentData,
                    idcupon: cupon,
                    refreshToken: "Bearer " + refreshToken,
                });
                //OCULTAR Y REMOVER EL FORMULARIO Y EVENTO, ASI FUNCIONE O NO EL SERVICIO
                respuesta.KR.hideForm(respForm.result.formId);
                respuesta.KR.removeForms();
                // SI LA VALIDACION ES EXITOSA
                
                if (respValidate.status) {
                    user.fecha_fin = respValidate.data.fecha_fin;
                    user.fecha_inicio = respValidate.data.fecha_inicio;
                    user.fecha_nomplan = respValidate.data.fecha_nomplan;
                    user.pkgxd = respValidate.data.pkgxd;
                    user.pvgxd = respValidate.data.pvgxd;
                    user.statrefresh = respValidate.data.statrefresh;
                    user.refreshToken = respValidate.data.refreshToken;
                    user.token = respValidate.data.token;
                    setLocalStorage;
                    setLocalStorage("userCca", user);
                    window.localStorage.accessToken = respValidate.data.token.toString();
                    window.localStorage.refreshToken = respValidate.data.refreshToken.toString();
                    setStatus(true);
                    setMessage("!Tu pago se realiazó con éxito!");
                } else {
                    //SETEAMOS VALOR DE ESTADO A FALSO Y EL MENSAJ DE ERROR
                    setStatus(false);
                    setMessage("Ocurrió un error al procesar su pago.");
                }
                //SETEAMOS ISFINAL A FALSE PARA REDIRECCIONAR A PERFIL (FALTARIA VER SI OCURRIÓ UN ERROR EN EL VALIDATE Y NO ENVIAR EL SETISFINAL A TRUE)
                setIsFinal(true);
                setShowStatus(true);
                setLoadingForm(false);
                // await new Promise((r) => setTimeout(r, 500));                
                replay()
                return false;
            });
            //FUNCION DE ERRORES
            const errores = await respuesta.KR.onError(async () => {
                setLoadingForm(true);
                respuesta.KR.hideForm(respForm.result.formId);
                respuesta.KR.removeForms();
                setStatus(false);
                setMessage("Ocurrió un error al procesar su pago.");
                setShowStatus(true);
                setIsFinal(false);
                setLoadingForm(false);
                // await new Promise((r) => setTimeout(r, 500));                
                replay()
                return false;
            });
            // TERMINA EL LOADER DEL MODAL
            setLoadingForm(false);
        } else {
            setMessage(respPay.error);
        }
        // TERMINA EL LOADER DEL MODAL- TA PA REVISAR
        setLoadingForm(false);
        setIsLoading(false);
        $("#modal_pay_plan").modal("show");
    };
    const closeModal = () => {
        if (isFinal) {
            setShowStatus(false);
            $("#modal_pay_plan").modal("hide");
            navigate("/perfil");
        } else {
            setShowStatus(false);
            $("#modal_pay_plan").modal("hide");
        }
    };
    const validCupon = async () => {
        setLoadCup(true)
        if (nroCupon != "") {
            const respCupon = await PlanesService.validateCupon({
                codigo: nroCupon,
                idplan: dataPlan.id,
                refreshToken: "Bearer " + refreshToken,
            })
            if (respCupon.status == 202) {
                Swal.fire({
                    title: "Cupón inválido!",
                    icon: "warning",
                    text: "El cupon ingresado no es válido",
                });
            } else if (respCupon.status) {
                setExistCupon(true)
                setCupon(respCupon.data[0][0].id_cupon)
                setDscto(respCupon.data[0][0].monto)
                Swal.fire({
                    title: "Cupón válido",
                    icon: "success",
                    text: "Aplicando descuento de cupón.",
                });
                //setear valor del descuento
                let tot = respCupon.data[0][0].monto ? ((dataPlan.precio - respCupon.data[0][0].monto) < 0 ? 0 : dataPlan.precio - respCupon.data[0][0].monto).toFixed(2) : dataPlan.precio.toFixed(2)
                setTotal(parseFloat(tot).toFixed(2))

            } else {
                Swal.fire({
                    title: "Error!",
                    icon: "error",
                    text: "Ocurrió un error al validar el cupón.",
                });
            }
        }
        setLoadCup(false)
    }
    const deleteCupon = () => {
        setExistCupon(false)
        setTotal(parseFloat(dataPlan.precio).toFixed(2))
    }
    const replay = () => {
        var animation = document.getElementById('successAnimation');
        // e.preventDefault;
        if (animation != null) {
            animation.classList.remove('animated');
            void animation.parentNode.offsetWidth;
            animation.classList.add('animated');
        }

    }
    return (
        <Main>
            <div className="container-xxl mw-100 ">
                <div className="toolbar" id="kt_toolbar">
                    <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className="row mx-0">
                    <div className="col-12 d-flex flex-column gap-5 mb-4">
                        <div className="row mx-0 gap-5 gap-md-0">
                            <div className="col-12 col-md-8 order-1 order-md-0">
                                <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                                    <div className="card-header">
                                        <div className="card-title justify-content-between w-100">
                                            <h2>Orden de Compra</h2>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                                <thead>
                                                    <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                                        <th className="min-w-175px">Producto</th>
                                                        <th className="min-w-100px text-end">Tipo</th>
                                                        <th className="min-w-70px text-end">Cantidad</th>
                                                        <th className="min-w-100px text-end">Precio</th>
                                                        <th className="min-w-100px text-end">Total</th>
                                                    </tr>
                                                </thead>

                                                <tbody className="fw-semibold text-gray-600">
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="symbol symbol-50px">
                                                                    <span
                                                                        className="symbol-label"
                                                                    // style="background-image:url(../../../assets/media/stock/ecommerce/1.gif);"
                                                                    ></span>
                                                                </div>
                                                                <div className="ms-5">
                                                                    <span className="fw-bold text-gray-600 text-hover-primary">
                                                                        {/* Plan {dataPlan.tiempo != 4 ? "Mensual" : "Anual"}{" "} */}
                                                                        Plan {dataPlan.tiempo == 1 ? "Quincenal" : "Mensual"}{" "}
                                                                        {plan == "live" ? "LIVE" : plan == "prematch" ? "PRE-MATCH" : plan == "live&prematch" ? "LIVE & PRE-MATCH": 'PLUS'}
                                                                    </span>
                                                                    <div className="fs-7 text-muted">Fecha: {moment(today).format("DD/MM/yyyy")}</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="text-end">{plan}</td>
                                                        <td className="text-end">1</td>
                                                        <td className="text-end">$ {dataPlan.precio.toFixed(2)}</td>
                                                        <td className="text-end">$ {dataPlan.precio.toFixed(2)}</td>
                                                    </tr>
                                                    {/* 
                                                <tr>
                                                    <td colSpan="1" className="">
                                                        <div className="d-flex align-items-center justify-content-start h-35px border-bottom-0">
                                                            <span className="w-100"> Nro.</span>
                                                            {existCupon ? <input type="text" className="form-control form-control-sm w-100" placeholder="Cupon">
                                                            </input> : <></>}
                                                        </div>

                                                    </td>
                                                    <td colSpan="3" className="text-end">
                                                        Subtotal
                                                    </td>
                                                    <td colSpan={1} className="text-end">$ {dataPlan.precio.toFixed(2)}</td>
                                                </tr> */}
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <div className="d-flex align-items-center justify-content-between h-35px border-bottom-0 gap-5">
                                                                <button disabled={existCupon} className="btn btn-primary btn-sm" onClick={validCupon}> {loadCup ? "Validando..." : "Validar!"}</button>
                                                                <input disabled={existCupon} value={nroCupon} onChange={(e) => setNroCupon(e.target.value)} type="text" className="form-control form-control-sm w-100" placeholder="Cupon">
                                                                </input>
                                                            </div>
                                                        </td>
                                                        <td className="text-end" colSpan={2}>Subtotal</td>
                                                        <td className="text-end" colSpan={1}>$ {dataPlan.precio.toFixed(2)}</td>
                                                    </tr>
                                                    {existCupon ? <tr>
                                                        <td className="text-start" colSpan={1}>
                                                            <button className="btn btn-danger btn-sm" onClick={deleteCupon}> Eliminar Cupon</button>
                                                        </td>
                                                        <td className="text-end" colSpan={3}>Descuento</td>
                                                        <td className="text-end" colSpan={1}>- $ {dscto}</td>
                                                    </tr> : <></>}

                                                    <tr>
                                                        <td colSpan="4" className="text-end">
                                                            IGV (0%)
                                                        </td>
                                                        <td className="text-end">$0.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4" className="fs-3 text-dark text-end">
                                                            Gran Total
                                                        </td>
                                                        <td className="text-dark fs-3 fw-bolder text-end">$ {total}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 order-0 order-md-1">
                                <div className="card card-flush py-4 flex-row-fluid h-100">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h2>Detalles de Usuario</h2>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                                <tbody className="fw-semibold text-gray-600">
                                                    <tr>
                                                        <td className="text-muted">
                                                            <div className="d-flex align-items-center">
                                                                <span className="svg-icon svg-icon-2 me-2">
                                                                    <FaRegUserCircle></FaRegUserCircle>
                                                                </span>
                                                                Usuario
                                                            </div>
                                                        </td>
                                                        <td className="fw-bold text-end">
                                                            <div className="d-flex align-items-center justify-content-end">
                                                                <div className="symbol symbol-circle symbol-25px overflow-hidden me-3">
                                                                    <a href="../customers/details.html">
                                                                        <div className="symbol-label">
                                                                            <img loading="lazy" src={urlBase + user.avatar} alt="image" className="w-100 h-100" />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <span className="text-gray-600 text-hover-primary">
                                                                    {user.nombres} {user.apellidos}
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-muted">
                                                            <div className="d-flex align-items-center">
                                                                <span className="svg-icon svg-icon-2 me-2">
                                                                    <FaEnvelope></FaEnvelope>
                                                                </span>
                                                                Email
                                                            </div>
                                                        </td>
                                                        <td className="fw-bold text-end">
                                                            <a href="../../user-management/users/view.html" className="text-gray-600 text-hover-primary">
                                                                {user.correo}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-muted">
                                                            <div className="d-flex align-items-center">
                                                                <span className="svg-icon svg-icon-2 me-2">
                                                                    <FaRegIdCard></FaRegIdCard>
                                                                </span>
                                                                Documento
                                                            </div>
                                                        </td>
                                                        <td className="fw-bold text-end">
                                                            <a href="../../user-management/users/view.html" className="text-gray-600 text-hover-primary">
                                                                {user.documento}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-muted">
                                                            <div className="d-flex align-items-center">
                                                                <span className="svg-icon svg-icon-2 me-2">
                                                                    <FaLocationArrow></FaLocationArrow>
                                                                </span>
                                                                Pais
                                                            </div>
                                                        </td>
                                                        <td className="fw-bold text-end">
                                                            <a href="../../user-management/users/view.html" className="text-gray-600 text-hover-primary">
                                                                {user.pais}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-muted">
                                                            <div className="d-flex align-items-center">
                                                                <span className="svg-icon svg-icon-2 me-2">
                                                                    <FaMobileAlt></FaMobileAlt>
                                                                </span>
                                                                Celular
                                                            </div>
                                                        </td>
                                                        <td className="fw-bold text-end">
                                                            +{user.codcelular} {user.celular}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 flex-column">
                        <div className="row mx-0 gap-5 gap-lg-0">
                            <div className="col-12 col-lg-4 order-1 order-lg-0">
                                <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                                    <div className="card-header mb-2">
                                        <div className="card-title d-flex justify-content-between w-100">
                                            <h2>Transferencia Bancaria</h2>
                                            <a href="https://wa.link/wau3h8" target="_blank" className="btn btn-primary">
                                                Enviar Comprobante
                                            </a>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        {/* <div className="row mx-0 mb-6">
                                            <div className="col-6 d-flex justify-content-center align-items-center">
                                                <img src={"/assets/bullbet/logobcp-1.webp"} alt="" className="w-150px rounded h-70px" />
                                            </div>
                                            <div className="col-6 flex-collumn">
                                                <div className="d-flex mb-4">
                                                    <div className="d-flex flex-column w-100">
                                                        <label htmlFor="">Nro Cuenta Corriente USD:</label>
                                                        <strong id="bcp_cc">570-04113668-1-74</strong>
                                                    </div>
                                                    <div className="d-flex justify-content-end align-items-center">
                                                        <OverlayTrigger placement="top" overlay={tooltipC}>
                                                            <button className="btn btn-icon btn-warning btn-sm " id="btn_bcp_cc" onClick={() => copyCC("bcp_cc")}>
                                                                <FaClone></FaClone>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="d-flex flex-column w-100">
                                                        <label htmlFor="">CCI USD:</label>
                                                        <strong id="bcp_cci">002-570-104113668174-02</strong>
                                                    </div>
                                                    <div className="d-flex justify-content-end align-items-center">
                                                        <OverlayTrigger placement="top" overlay={tooltipC}>
                                                            <button className="btn btn-icon btn-warning btn-sm" id="btn_bcp_cci" onClick={() => copyCC("bcp_cci")}>
                                                                <FaClone></FaClone>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr /> */}
                                        <div className="row mx-0">
                                            <div className="col-6 d-flex justify-content-center align-items-center">
                                                <img src={"/assets/bullbet/Interbank_logo-1.webp"} alt="" className="w-150px rounded h-70px" />
                                            </div>
                                            <div className="col-6 flex-collumn">
                                                <div className="d-flex mb-4 mt-4">
                                                    <div className="d-flex flex-column w-100">
                                                        <label htmlFor="">Nro Cuenta Corriente USD:</label>
                                                        <strong id="ibk_cc">6003004794300</strong>
                                                    </div>
                                                    <div className="d-flex justify-content-end align-items-center">
                                                        <OverlayTrigger placement="top" overlay={tooltipC}>
                                                            <button className="btn btn-icon btn-warning btn-sm" id="btn_ibk_cc" onClick={() => copyCC("ibk_cc")}>
                                                                <FaClone></FaClone>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="d-flex flex-column w-100">
                                                        <label htmlFor="">CCI USD:</label>
                                                        <strong id="ibk_cci">003-600-003004794300-43</strong>
                                                    </div>
                                                    <div className="d-flex justify-content-end align-items-center">
                                                        <OverlayTrigger placement="top" overlay={tooltipC}>
                                                            <button className="btn btn-icon btn-warning btn-sm" id="btn_ibk_cci" onClick={() => copyCC("ibk_cci")}>
                                                                <FaClone></FaClone>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 order-2 order-lg-1">
                                <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                                    <div className="card-header mb-2">
                                        <div className="card-title d-flex justify-content-between w-100">
                                            <h2>Binance</h2>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="row mt-0">
                                            <div className="col-5 d-flex justify-content-center align-items-center">
                                                <img src={"/assets/bullbet/binance1.png"} alt="" className="w-150px rounded h-150px" />
                                            </div>
                                            <div className="col-7 flex-collumn">
                                                <div className="d-flex mb-2">
                                                    <div className="d-flex flex-column w-85">
                                                        <label htmlFor="">Dirección billetera USDT:</label>
                                                        <strong id="binance_cc">0xcf030463db3410fcc476751c501fa03a0bcec95f</strong>
                                                        <label htmlFor="" className="mt-3">Binance pay ID:</label>
                                                        <strong id="binance_id">361613431</strong>
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-center align-items-center ms-3">
                                                        <OverlayTrigger placement="top" overlay={tooltipC}>
                                                            <button className="btn btn-icon btn-warning btn-sm" id="btn_binance_cc" onClick={() => copyCC("binance_cc")}>
                                                                <FaClone></FaClone>
                                                            </button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger placement="top" overlay={tooltipC}>
                                                            <button className="btn btn-icon btn-warning btn-sm mt-5" id="btn_binance_id" onClick={() => copyCC("binance_id")}>
                                                                <FaClone></FaClone>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column w-100">
                                                    <strong htmlFor="" className="mt-4">Instrucciones</strong>
                                                    <span id="">* Envía solo USDT a esta dirección de depósito.</span>
                                                    <span id="">* Asegúrate que la red sea BNB Smart Chain (BEP20).</span>
                                                    <span id="">* Depósito mínimo: +0.01 USDT.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 order-0 order-lg-2">
                                <div className="card card-flush py-4 flex-row-fluid h-100">
                                    <div className="card-header">
                                        <div className="card-title d-flex justify-content-between w-100">
                                            <h2>Pago Online</h2>
                                            <button className="btn btn-success" onClick={modalPay}>
                                                Pagar $ {total}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0 d-flex justify-content-around align-items-center">
                                        <img src={"/assets/bullbet/visa.png"} alt="" className="w-100px h-70px rounded" />
                                        <img src={"/assets/bullbet/Mastercard.png"} alt="" className="w-100px h-70px rounded" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading ? (
                    <div className=" position-absolute w-100 h-100 back_spinner">
                        <div className="spinner"></div>
                    </div>
                ) : (
                    <></>
                )}
                <div className="modal fade modal-sm" id="modal_pay_plan" tabIndex="-1" aria-modal="true" role="dialog" data-bs-backdrop="static">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header" id="modal_pay_plan_header">
                                <h2 className="fw-bold">Pago de Plan</h2>
                                <div
                                    id="modal_pay_plan_close"
                                    className="btn btn-icon btn-sm btn-active-icon-primary"
                                    data-bs-dismiss="modal"
                                    onClick={() => closeModal()}
                                >
                                    <span className="svg-icon svg-icon-1">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect
                                                opacity="0.5"
                                                x="6"
                                                y="17.3137"
                                                width="16"
                                                height="2"
                                                rx="1"
                                                transform="rotate(-45 6 17.3137)"
                                                fill="currentColor"
                                            ></rect>
                                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className={`modal-body position-relative  py-10 mx-lg-2`}>
                                <div className="form">
                                    <div
                                        id="myPaymentForm"
                                        className="myPaymentForm d-flex justify-content-center align-items-center "
                                    >
                                        {loadingForm ? <div className="position-absolute back_spinner_form">
                                            <div className="spinner"></div>
                                        </div> : <></>}

                                    </div>
                                    {showStatus ? (
                                        <div className=" h-300px d-flex flex-column justify-content-center align-items-center gap-5">
                                            {status ? <CheckPay></CheckPay> : <FcCancel size={30}></FcCancel>}
                                            <span className="text-center fw-bolder">{message}</span>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    );
};

export default DetallePlanes;
