/* eslint-disable */
import React, { useEffect } from 'react'
import ControllerSesion from './Controller/ControllerSesion'
import { UserProvider } from './Providers/UserProvider'

// const { REACT_APP_URL_API } = process.env

const App = () => {
  useEffect(() => {
    window.KTComponents.init()
  }, []
  )
  return (
    <UserProvider>
      <ControllerSesion>
      </ControllerSesion>
    </UserProvider>
  )
}
export default App
