/* eslint-disable */
import React, { useState, useEffect } from "react";
import Main from "../../layout/main";
import { useUserContext } from "../../Providers/UserProvider";
import Breadcrumbs from "../../Components/utils/BreadCrumb";
import promociones from "../../Assets/promociones.json";

const Promociones = () => {
    const { user } = useUserContext();
    const [stake, setStake] = useState(user.stake);
    const [userTc, setUserTc] = useState(user.tipocambio);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    //BreadCrumb
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/promociones", name: "Promociones", active: false },
    ];
    //GET DATA
    const showData = async () => {
        // const body = {
        //     refreshToken: "Bearer " + refreshToken,
        // };
        setLoading(true);
        setItems(promociones);
        // const resp = await HistorialService.getConexiones(body);
        // if (resp.status) {
        //     setItems(resp.data[0]);
        // } else {
        //     setItems([]);
        // }
        setLoading(false);
    };
    //MOUNTED
    useEffect(() => {
        showData();
    }, []);
    return (
        <Main userStake={(datos) => setStake(datos)} userTC={(datos) => setUserTc(datos)}>
            <div className="container-xxl mw-100 ">
                <div className="toolbar" id="kt_toolbar">
                    <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                    <div className="overlay-wrapper">
                        <div className="row mx-0">
                            {items.map((item, index) => {
                                return (
                                    <div className="col-12 col-sm-6 col-md-4 col-xxl-3 mb-4" key={index}>
                                        {/* <div className="card p-0 shadow card_promotion">
                                            <div className="card-body d-flex flex-column justify-content-center align-items-center">
                                                <div className="d-flex justify-content-between align-items-center w-100">
                                                    <img src="/assets/bullbet/icon_logo.png" alt="iso_logo" className="h-20 w-20px" />
                                                    <span className="fs-2">
                                                        <strong>$ {parseFloat(item.price).toFixed(2)}</strong>
                                                    </span>
                                                </div>
                                                <div className="row mx-0 my-4">
                                                    <span className="d-flex fs-3 mb-2">
                                                        <strong>{item.name}</strong>
                                                    </span>
                                                    <span className="mb-2">
                                                        {item.description}
                                                    </span>
                                                </div>
                                                <img
                                                    src={`/assets/bullbet/promociones/` + item.image}
                                                    alt="icon_promo"
                                                    className="image_card h-200px"
                                                />
                                            </div>
                                            <div className="card-footer">
                                                <div className="row mx-0">
                                                    <span className="d-flex fs-5 mb-4">
                                                        <strong>Valida hasta: </strong><span className="px-2">{moment(item.date_start).format("yyyy-MM-DD")}</span>
                                                    </span>
                                                    <button className="btn btn-primary rounded-4 my-2">
                                                        Adquirir
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="card p-0 shadow card_promotion">
                                            <div className="card-body card_body_item position-relative overflow-hidden d-flex flex-column justify-content-center align-items-center">
                                                <div className="d-flex justify-content-between align-items-center w-100 mb-4">
                                                    <img src="/assets/bullbet/icon_logo.png" alt="iso_logo" className="h-20 w-20px" />
                                                    <span className="fs-2 text-white">
                                                        <strong>$ {parseFloat(item.price).toFixed(2)}</strong>
                                                    </span>
                                                </div>
                                                <img
                                                    src={`/assets/bullbet/promociones/` + item.image}
                                                    alt="icon_promo"
                                                    className="image_card h-200px"
                                                />
                                                <div className="text_card d-flex justify-content-center align-items-end w-100">
                                                    <span className="item_text_card">CCA</span>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <div className="row mx-0">
                                                <span className="d-flex fs-3 mb-2">
                                                        <strong>{item.name}</strong>
                                                    </span>
                                                    <span className="mb-2">
                                                        {item.description}
                                                    </span>
                                                    <span className="d-flex fs-5 mb-4">
                                                        <strong>Valida hasta: </strong><span className="px-2">{moment(item.date_start).format("yyyy-MM-DD")}</span>
                                                    </span>
                                                    <button className="btn btn-gradiente rounded-4 my-2 fs-4">
                                                        Adquirir
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {loading ? (
                        <div className="overlay-layer card-rounded bg-black over_loading">
                            <div className="spinner-border text-primary" role="status"></div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </Main>
    );
};
export default Promociones;
