/* eslint-disable */

import UseService from "../UseServices";

const ReportesService = {
    async getOperadores(data) {
        var resp = await UseService.post("constante/listaroperadores/", data);
        return resp;
    },
    async getReporteSureBets(data) {
        var resp = await UseService.post("reports/surebets/", data);
        return resp;
    },
    async getReporteApuestas(data) {
        var resp = await UseService.post("reports/apuestas/", data);
        return resp;
    },
    async getBankDiario(data) {
        var resp = await UseService.post("reports/cuadre", data);
        return resp;
    },
    async getGanancias(data) {
        var resp = await UseService.post("reports/ganancias", data);
        return resp;
    },
};

export default ReportesService;