/* eslint-disable */
import React, { useState, useEffect } from "react";
import Main from "../../../layout/main";
import Breadcrumbs from "../../../Components/utils/BreadCrumb";
import MontosService from "../../../api/services/montosService";
import { useUserContext } from "../../../Providers/UserProvider";
import MUIDataTable from "mui-datatables";
import moment from "moment/moment";

const today = new Date();

/* FIN */
const ReporteMontos = () => {
    /* -----BREADCRUMBS----*/
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/reporte-montos", name: "Reporte Montos", active: true },
    ];
    /* -----FIN BREADCRUMBS----*/

    /* -----MUIDATATABLE----*/
    /* -----FIN MUIDATATABLE----*/

    /* -----HOOKS----*/
    const { user } = useUserContext();
    const [loading, setLoading] = useState(false);

    const [items, setItems] = useState(null);
    const [columns, setColumns] = useState([]);
    const [filtros, setFiltros] = useState({
        fecha: moment(today).format("yyyy-MM-DD"),
    });
    /* -----FIN HOOKS----*/

    /* -----DATOS----*/
    const refreshToken = localStorage.getItem("refreshToken");
    /* -----FIN DATOS----*/

    /* -----METODOS----*/
    //LISTAR DATA
    const getData = async () => {
        setLoading(true);
        const body = {
            refreshToken: "Bearer " + refreshToken,
            fecha: filtros.fecha,
        };
        // console.log("body", body);
        const resMontos = await MontosService.reportMonto(body);

        if (resMontos.status) {
            setItems(resMontos.data[0]);
        }
        // console.log("resMontos", resMontos);
        setLoading(false);
    };
    //EDITAR DATA

    /* -----FIN METODOS----*/

    /* -----MOUNTED----*/
    useEffect(() => {
        // getData();
    }, []);
    useEffect(() => {
        $("#kt_datepicker_14").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            onChange: function (selectedDates, dateStr, instance) {
                setFiltros({ ...filtros, fecha: moment(dateStr).format("yyyy-MM-DD") });
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
    }, []);

    /* -----FIN MOUNTED----*/
    return (
        <div className="container-xxl mw-100 px-0">
            <div className="toolbar" id="kt_toolbar">
                <div className="w-100 px-2 d-flex flex-stack flex-wrap flex-sm-nowrap">
                    <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                </div>
            </div>
            <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                <div className="overlay-wrapper">
                    <div className="card p-0 shadow">
                        <div className="card-header border-0 p-6">
                            <div className="card-toolbar w-100">
                                <div className="d-flex flex-column flex-lg-row  w-100 justify-content-between h-100">
                                    <div className="row mx-0 w-100">
                                        <div className="col-md-3 ">
                                            <label htmlFor="" className="form-label">
                                                Fecha
                                            </label>
                                            <input className="form-control form-control-solid" placeholder="Fecha" id="kt_datepicker_14" />
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="d-flex align-content-end justify-content-xl-end justify-content-center h-100  w-100">
                                                <button
                                                    disabled={loading}
                                                    type="button"
                                                    className="btn btn-primary mt-auto me-3"
                                                    onClick={getData}
                                                >
                                                    {loading ? (
                                                        <span className="indicator-label">
                                                            Buscando...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    ) : (
                                                        <span className="indicator-label ">
                                                            <i className="bi bi-search" /> Buscar
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0 d-flex justify-content-start w-100 flex-column">
                            {items != null ? (
                                <div className="row mx-0 w-100 py-5 px-10 rounded-2">
                                    <div className="col-12 p-4">
                                        <h2>Cuentas Dolares</h2>
                                    </div>
                                    <div className="col-4 d-flex flex-column px-0">
                                        <span className="d-flex justify-content-center align-items-center border header_title bg-primary">
                                            TITULAR{" "}
                                        </span>
                                        {items.bodydolares.map((tit, index) => {
                                            return (
                                                <span
                                                    key={index}
                                                    className="d-flex justify-content-center align-items-center border p-4 second_row"
                                                >
                                                    {tit.titular}
                                                </span>
                                            );
                                        })}
                                        <span className="d-flex justify-content-center align-items-center border three_row bg-primary">
                                            TOTAL{" "}
                                        </span>
                                    </div>
                                    <div className="col-8 p-0">
                                        <div className="content_card_report w-100">
                                            <div className="d-flex border row_content header_title bg-primary">
                                                {items.cabecera.fechas.map((fecha, index) => {
                                                    return (
                                                        <div className="date_title  w-100 text-center " key={index}>
                                                            <span className="d-flex h-100 w-100 justify-content-center align-items-center ">
                                                                {fecha}
                                                            </span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            {items.bodydolares.map((dato, index) => {
                                                return (
                                                    <div className="d-flex row_content border second_row" key={index}>
                                                        {dato.valormontos.map((val, index) => {
                                                            return (
                                                                <div
                                                                    className="w-100 d-flex justify-content-center align-items-center p-4 "
                                                                    key={index}
                                                                >
                                                                    {parseFloat(val ? val : 0).toLocaleString("en", {
                                                                        minimumFractionDigits: 2,
                                                                    })}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                );
                                            })}
                                            <div className="d-flex border row_content header_title bg-light-primary">
                                                {items.footerdolares.montos.map((tot, index) => {
                                                    return (
                                                        <div className="date_title  w-100 text-center " key={index}>
                                                            <span className="d-flex h-100 w-100 justify-content-center align-items-center ">
                                                                {parseFloat(tot ? tot : 0).toLocaleString("en", {
                                                                    minimumFractionDigits: 2,
                                                                })}
                                                            </span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {items != null ? (
                                <div className="row mx-0 w-100 py-5 px-10 rounded-2">
                                    <div className="col-12 p-4">
                                        <h2>Cuentas Moneda Local</h2>
                                    </div>
                                    <div className="col-4 d-flex flex-column px-0">
                                        <span className="d-flex justify-content-center align-items-center border header_title bg-primary">
                                            TITULAR{" "}
                                        </span>
                                        {items.bodysoles.map((tit, index) => {
                                            return (
                                                <span
                                                    key={index}
                                                    className="d-flex justify-content-center align-items-center border p-4 second_row"
                                                >
                                                    {tit.titular}
                                                </span>
                                            );
                                        })}
                                        <span className="d-flex justify-content-center align-items-center border three_row bg-primary">
                                            TOTAL{" "}
                                        </span>
                                    </div>
                                    <div className="col-8 p-0">
                                        <div className="content_card_report w-100">
                                            <div className="d-flex border row_content header_title bg-primary">
                                                {items.cabecera.fechas.map((fecha, index) => {
                                                    return (
                                                        <div className="date_title  w-100 text-center " key={index}>
                                                            <span className="d-flex h-100 w-100 justify-content-center align-items-center ">
                                                                {fecha}
                                                            </span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            {items.bodysoles.map((dato, index) => {
                                                return (
                                                    <div className="d-flex row_content border second_row" key={index}>
                                                        {dato.valormontos.map((val, index) => {
                                                            return (
                                                                <div
                                                                    className="w-100 d-flex justify-content-center align-items-center p-4 "
                                                                    key={index}
                                                                >
                                                                    {parseFloat(val ? val : 0).toLocaleString("en", {
                                                                        minimumFractionDigits: 2,
                                                                    })}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                );
                                            })}
                                            <div className="d-flex border row_content header_title bg-light-primary">
                                                {items.footersoles.montos.map((tot, index) => {
                                                    return (
                                                        <div className="date_title  w-100 text-center " key={index}>
                                                            <span className="d-flex h-100 w-100 justify-content-center align-items-center ">
                                                                {parseFloat(tot ? tot : 0).toLocaleString("en", {
                                                                    minimumFractionDigits: 2,
                                                                })}
                                                            </span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
                {loading ? (
                    <div className="overlay-layer card-rounded bg-black bg-opacity-50">
                        <div className="d-flex flex-column align-items-center mt-10">
                            <div className="spinner-border text-white" role="status"></div>
                            <span>Buscando datos...</span>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};
export default ReporteMontos;
