/* eslint-disable */

import UseService from "../UseServices";

const AuthService = {
    async login(data) {
        var resp = await UseService.post("auth", data);
        return resp;
    },
    async register(data) {
        var resp = await UseService.post("auth/addusuario", data);
        return resp;
    },
    async restorePassword(data) {
        var resp = await UseService.post("auth/restaurarcontra", data);
        return resp;
    },
    async changePassword(data) {
        var resp = await UseService.postRest("auth/restablecercontra", data);
        return resp;
    },
};
export default AuthService;