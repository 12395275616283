/* eslint-disable */
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import paises from "../../../Assets/paises.json";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AuthService from "../../../api/services/authService";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import moment from "moment/moment";
import Select from "react-select";
import "../auth.css";
import { useEffect } from "react";
import parse from "date-fns/parse";
const restaEdad = moment().subtract(18, "years").calendar();
const newFormat = restaEdad.split("/");
const fechaMayor = newFormat[2] + "/" + newFormat[1] + "/" + newFormat[0];
const schemaRegistrar = yup.object({
    nombres: yup
        .string()
        .required("Este campo es requerido")
        .matches(/^[a-zA-Z\s]*$/, "No debe de contener numeros"),
    apellidos: yup
        .string()
        .required("Este campo es requerido")
        .matches(/^[aA-zZ\s]+$/, "No debe de contener numeros"),
    email: yup.string().required("Este campo es requerido").email("Debe de ser un email"),
    documento: yup
        .number()
        .required("Este campo es requerido")
        .typeError("No es un numero")
        .test("len", "Debe tener min 8 caracteres", (val) => {
            if (val == undefined) {
                return true;
            }
            return val.toString().length >= 8;
        }),
    pais: yup
        .object()
        .shape({
            label: yup.string().required(),
            value: yup.string().required(),
        })
        .required("Seleccione un pais"),
    cod_pais: yup.string().required("Seleccione un código"),
    telefono: yup.number().required("Ingrese un número").typeError("Ingrese un número celular"),
    ciudad: yup.string().required("Ingrese una ciudad"),
    fecha_nacimiento: yup.date().typeError("No es una fecha valida").required().max(fechaMayor, "Debe ser mayor de edad"),
    password: yup.string().required("Ingrese una contraseña"),
    termconds: yup.boolean().oneOf([true], "Acepte los términos y condiciones").required("Acepte los términos y condiciones"),
});
const colourStyles = {
    control: (styles) => ({
        ...styles, backgroundColor: "#fff", borderColor: "#c3c5ca", boxShadow: "none", "&:hover": {
            borderColor: "none",
        },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected
                ? "#D0D2D7"
                : isFocused
                    ? "#E9E9E9"
                    : "#F5F5F5",
            color: isSelected
                ? "black"
                : "black",
            fontWeight: "500",
            cursor: "pointer",
        };
    },
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles) => ({
        ...styles,
        color: "#5e6278",
        fontWeight: "500",
    }),
    input: (styles) => ({
        ...styles,
        color: "black",
        fontWeight: "500",
    }),
    valueContainer: (styles) => ({ ...styles, padding: "0.1rem 0.4rem", color: "black" }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: "#f9f9f9",
        
    }),
};
const defaultStyles = {
    control: (base, state) => ({
        ...base,
    }),
    menu: (base) => ({
        ...base,
    }),
    menuList: (base) => ({
        ...base,
        minHeight: "100px", // your desired height
    }),
};
const Registrarse = () => {
    // mayorEdad()
    //hooks
    const [pais, setPais] = useState({});
    const navigate = useNavigate();
    const [viewPass, setViewPass] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mayor, setMayor] = useState();
    //USE FORM
    const {
        handleSubmit,
        register,
        setValue,
        getValues,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schemaRegistrar),
    });
    //REGEX
    const onlyNumber = (e) => {
        if (
            (e.keyCode < 48 || e.keyCode > 57) &&
            e.keyCode != 190 &&
            e.keyCode != 8 &&
            e.keyCode != 9 &&
            e.keyCode != 46 &&
            (e.keyCode < 96 || e.keyCode > 105)
        ) {
            e.preventDefault();
        }
    };
    const onlyText = (e) => {
        if ((e.keyCode < 65 || e.keyCode > 90) && e.keyCode != 32 && e.keyCode != 8 && e.keyCode != 9 && e.keyCode != 13) {
            e.preventDefault();
        }
    };
    //MOUNTED
    useEffect(() => {
        //console.log("paises", paises);
        // setValue("cod_pais", "47")
    }, []);
    //ENVIO DE DATA
    const sendDataRegister = async (data) => {
        setLoading(true);
        data.fecha_nacimiento = Intl.DateTimeFormat("sv-SE").format(data.fecha_nacimiento);
        //console.log(data);
        const body = {
            nombres: data.nombres.trim(),
            apellidos: data.apellidos.trim(),
            correo: data.email.trim(),
            password: data.password.trim(),
            documento: data.documento,
            celular: data.telefono,
            codtelefono: data.cod_pais,
            telefono: data.telefono,
            pais: data.pais.value.trim(),
            ciudad: data.ciudad.trim(),
            fecnac: data.fecha_nacimiento,
        };
        //console.log("bdy", body);
        const resp = await AuthService.register(body);
        // console.log("resp", resp);
        if (resp.status) {
            Swal.fire({
                title: "Registrado",
                icon: "success",
                text: "Su cuenta ha sido registrado con éxito.",
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                navigate("/login");
            });
        } else {
            if (resp.code == 409) {
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: resp.data.message,
                });
            } else {
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: resp.data.message + " inválido",
                });
            }
        }
        setLoading(false);
    };
    const searchCountry = (val) => {
        //console.log("val", val);
        const filter = paises.filter((e) => e.nombre == val);
        //console.log("filter", filter);
        if (filter[0]?.phone_code != "") {
            setValue("cod_pais", filter[0].phone_code);
            //console.log("cod_pais", document.getElementById("cod_pais"));
            //console.log("aer", getValues("pais"));
        }
    };
    return (
        <div className="d-flex flex-column flex-root h-100">
            <div className="row h-100 w-100 gx-0">
                {/* <div className="d-none d-xl-block col-xl-6 login_aside h-100"></div> */}
                <div className="col-12 h-100">
                    <div className="d-flex flex-center w-100 p-5 h-sm-100 content_login">
                        <div className=" w-100 w-lg-600px flex-center p-5 d-flex flex-column gap-8">

                            <form
                                className="login_form p-10 form w-100 d-flex flex-column items-content-center align-items-center gap-4 h-100"
                                noValidate="noValidate"
                                id="kt_sign_in_form"
                                onSubmit={handleSubmit(sendDataRegister)}
                            >
                                <div className="text-center d-flex gap-3 mb-2 w-100">
                                    <div className="py-2 pb-lg-5 h-100 w-100 d-flex flex-center">
                                        <img alt="Logo" className="logo_login " />
                                    </div>
                                    <div className="w-100">
                                        <span className="text-black mb-3 fs-3">Create una Cuenta</span>
                                        <div className="text-gray-400 fw-semibold fs-6">
                                            ¿Ya tienes una cuenta?
                                            <Link to="/login" className="link-primary fw-bold ms-1">
                                                Ingresa aqui
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="row fv-row fv-plugins-icon-container w-100">
                                    <div className="col-sm-6">
                                        <label className="form-label fw-bold text-black fs-6 ">Nombres</label>
                                        <input
                                            className="form-control form-control-sm bg-white border-login input_form_login"
                                            type="text"
                                            placeholder="Nombres"
                                            name="nombres"
                                            autoComplete="off"
                                            onKeyDown={(e) => onlyText(e)}
                                            {...register("nombres")}
                                        />
                                        <div className="fv-plugins-message-container invalid-feedback">
                                            {errors.nombres?.type === "required" && errors.nombres.message}
                                            {errors.nombres?.type === "matches" && errors.nombres.message}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <label className="form-label fw-bold text-black fs-6">Apellidos</label>
                                        <input
                                            className="form-control form-control-sm bg-white border-login input_form_login "
                                            type="text"
                                            placeholder="Apellidos"
                                            name="apellidos"
                                            autoComplete="off"
                                            onKeyDown={(e) => onlyText(e)}
                                            {...register("apellidos")}
                                        />
                                        <div className="fv-plugins-message-container invalid-feedback">
                                            {errors.apellidos?.type === "required" && errors.apellidos.message}
                                            {errors.apellidos?.type === "matches" && errors.apellidos.message}
                                        </div>
                                    </div>
                                </div>
                                <div className="row fv-row fv-plugins-icon-container w-100">
                                    <div className="col-sm-6 ">
                                        <label className="form-label fw-bold text-black fs-6">Correo</label>
                                        <input
                                            className="form-control form-control-sm bg-white border-login input_form_login"
                                            type="email"
                                            placeholder="Correo Electrónico"
                                            name="email"
                                            autoComplete="off"
                                            {...register("email")}
                                        />
                                        <div className="fv-plugins-message-container invalid-feedback">
                                            {errors.email?.type === "required" && errors.email.message}
                                            {errors.email?.type === "email" && errors.email.message}
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <label className="form-label fw-bold text-black fs-6">Documento</label>
                                        <input
                                            className="form-control form-control-sm bg-white border-login input_form_login"
                                            type="telf"
                                            placeholder="Documento"
                                            name="documento"
                                            autoComplete="off"
                                            onKeyDown={(e) => onlyNumber(e)}
                                            minLength={8}
                                            {...register("documento")}
                                        />
                                        <div className="fv-plugins-message-container invalid-feedback">
                                            {errors.documento?.type === "len" && errors.documento.message}
                                            {errors.documento?.type === "required" && errors.documento.message}
                                            {errors.documento?.type === "typeError" && errors.documento.message}
                                        </div>
                                    </div>
                                </div>
                                <div className="row fv-row fv-plugins-icon-container w-100">
                                    <div className="col-sm-6">
                                        <label className="form-label fw-bold text-black fs-6">Pais</label>
                                        <Controller
                                            control={control}
                                            name="pais"
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    options={paises.map((cu) => ({
                                                        label: cu.nombre,
                                                        value: cu.nombre,
                                                    }))}
                                                    styles={colourStyles}
                                                    defaultValue=""
                                                    onChange={(e) => [field.onChange(e), searchCountry(e.value)]}
                                                    // onChange={(e) => searchCountry(e.value)}
                                                    maxMenuHeight={125}
                                                    placeholder={"Seleccionar"}
                                                    noOptionsMessage={() => "Sin resultados"}
                                                />
                                            )}
                                        // defaultValue={{ label: "0", value: "0" }}
                                        />
                                        <div className="fv-plugins-message-container invalid-feedback">
                                            {errors.pais?.type === "required" && errors.pais.message}
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <label className="form-label fw-bold text-black fs-6">Telefono</label>
                                        <div className="d-flex gap-2">
                                            <div className="w-50">
                                                <select
                                                    name="cod_pais"
                                                    id="cod_pais"
                                                    className="form-select form-select-sm form_select_login"
                                                    {...register("cod_pais")}
                                                >
                                                    <option value="0" data-select2-id="select2-data-9-nmcn">
                                                        Cod
                                                    </option>
                                                    {paises.map((pais) => (
                                                        <option
                                                            key={pais.iso2}
                                                            value={pais.phone_code}
                                                            data-select2-id="select2-data-9-nmcn"
                                                        >
                                                            {`+${pais.phone_code}`}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="w-100 ">
                                                <input
                                                    className="form-control form-control-sm bg-white border-login input_form_login"
                                                    type="text"
                                                    placeholder="Teléfono"
                                                    name="telefono"
                                                    autoComplete="off"
                                                    onKeyDown={(e) => onlyNumber(e)}
                                                    {...register("telefono")}
                                                />
                                            </div>
                                        </div>
                                        <div className="fv-plugins-message-container invalid-feedback">
                                            {errors.cod_pais?.type === "required" && errors.cod_pais.message}
                                        </div>
                                        <div className="fv-plugins-message-container invalid-feedback">
                                            {errors.telefono?.type === "typeError" && errors.telefono.message}
                                            {errors.telefono?.type === "required" && errors.telefono.message}
                                        </div>
                                    </div>
                                </div>
                                <div className="row fv-row fv-plugins-icon-container w-100">
                                    <div className="col-sm-6">
                                        <label className="form-label fw-bold text-black fs-6">Ciudad</label>
                                        <input
                                            className="form-control form-control-sm bg-white border-login input_form_login"
                                            type="text"
                                            placeholder="Ciudad"
                                            name="ciudad"
                                            autoComplete="off"
                                            {...register("ciudad")}
                                        />
                                        <div className="fv-plugins-message-container invalid-feedback">
                                            {errors.ciudad?.type === "required" && errors.ciudad.message}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <label className="form-label fw-bold text-black fs-6">Fecha nacimiento</label>
                                        <input
                                            className="form-control form-control-sm bg-white border-login input_form_login "
                                            type="date"
                                            placeholder="F. de Nacimiento"
                                            name="fecha_nacimiento"
                                            autoComplete="off"
                                            {...register("fecha_nacimiento")}
                                        />
                                        <div className="fv-plugins-message-container invalid-feedback">
                                            {errors.fecha_nacimiento?.type === "max" && errors.fecha_nacimiento.message}
                                            {errors.fecha_nacimiento?.type === "typeError" && errors.fecha_nacimiento.message}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4 fv-row fv-plugins-icon-container w-100" data-kt-password-meter="true">
                                    <div className="col-sm-12">
                                        <label className="form-label fw-bold text-black fs-6">Password</label>
                                        <div className="input-group input-group-sm mb-3">
                                            <input
                                                className="form-control form-control-sm bg-white border-login input_form_login"
                                                type={viewPass ? "text" : "password"}
                                                placeholder="Contraseña"
                                                name="password"
                                                autoComplete="off"
                                                {...register("password")}
                                            />
                                            <span className="input-group-text icon_pass" id="basic-addon1">
                                                {viewPass ? (
                                                    <span onClick={() => setViewPass(false)} className="cursor-pointer">
                                                        <FaEye></FaEye>
                                                    </span>
                                                ) : (
                                                    <span onClick={() => setViewPass(true)} className="cursor-pointer">
                                                        <FaEyeSlash></FaEyeSlash>
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                        <div className="fv-plugins-message-container invalid-feedback">
                                            {errors.password?.type === "required" && errors.password.message}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2 fv-plugins-icon-container w-100">
                                    <div className="col-sm-12 ">
                                        <label className="form-check form-check-custom form-check-inline d-flex align-items-center">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="termconds"
                                                value="1"
                                                {...register("termconds")}
                                            />
                                            <span className="form-check-label fw-semibold text-gray-700 fs-6">
                                                Aceptar
                                                <a
                                                    href="https://ccacorps.com/terminos-y-condiciones"
                                                    target="_blank"
                                                    className="ms-1 link-primary"
                                                >
                                                    Términos y Condiciones
                                                </a>
                                                .
                                            </span>
                                        </label>
                                        <div className="fv-plugins-message-container invalid-feedback">
                                            {errors.termconds?.type === "oneOf" && errors.termconds.message}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center w-100 d-flex justify-content-center">
                                    <button
                                        disabled={loading}
                                        id="kt_sign_up_submit"
                                        className="btn btn-md btn-sm-lg btn-primary"
                                        type="submit"
                                    >
                                        {loading ? (
                                            <span className="indicator-label">
                                                Cargando...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        ) : (
                                            <span className="indicator-label ">Registrarse</span>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Registrarse;
