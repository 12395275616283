/* eslint-disable */

import UseService from '../UseServices'

const TransaccionesService = {
    async getTransacciones(data) {
        var resp = await UseService.post('transacciones/list', data)
        return resp
    },
    async addTransaccion(data) {
        var resp = await UseService.post('transacciones/add', data)
        return resp
    },
    async updateTransaccion(data) {
        var resp = await UseService.put('transacciones/update', data)
        return resp
    },
    async statusTransaccion(data) {
        var resp = await UseService.put('transacciones/status', data)
        return resp
    },
}
export default TransaccionesService
