/* eslint-disable */
import React, { useState, useEffect } from "react";
// import Main from "../../layout/main";
import EventosService from "../../../api/services/eventosService";
import Breadcrumbs from "../../../Components/utils/BreadCrumb";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment/moment";
import { FaRegFutbol, FaSearch, FaRegClock } from "react-icons/fa";
const today = new Date();
const Eventos = () => {
    let keysData = [];
    const [dataEventos, setDataEventos] = useState([]);
    const [eventosFilter, setEventosFilter] = useState([]);
    const [fecha, setFecha] = useState(moment(today).format("yyyy-MM-DD"));
    const [loading, setLoading] = useState(false);
    const [sport, setSport] = useState(1);
    const body = {
        fecha: fecha,
    };
    //BreadCrumb
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/eventos", name: "Eventos", active: false },
    ];
    //TRAER DATA
    const getData = async () => {
        setLoading(true);
        //console.log('Se ejecuta aqui?')
        let temp = [];
        const eventos = await EventosService.getEventos(body);
        if (eventos.status) {
            for (let index = 0; index < eventos.data.length; index++) {
                const element = eventos.data[index];
                temp.push(element);
                keysData.push("0" + index.toString());
            }
            setDataEventos(temp);
            filterData(temp, 1);
            setSport(1);
        }
        // setDataEventos(temp)
        setLoading(false);
    };
    //MOUNTED
    useEffect(() => {
        getData();
        //init tooltip
    }, []);
    useEffect(() => {
        $("#kt_datepicker_1").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            // minDate: "today",
            onChange: function (selectedDates, dateStr, instance) {
                setFecha(moment(dateStr).format("yyyy-MM-DD"));
                // searchData(moment(dateStr).format("yyyy-MM-DD"))
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
    }, []);

    //BUSCAR EVENTOS
    const searchData = async () => {
        setLoading(true);
        let temp = [];
        const body1 = {
            fecha: fecha,
        };
        const eventos = await EventosService.getEventos(body1);
        if (eventos.status) {
            for (let index = 0; index < eventos.data.length; index++) {
                const element = eventos.data[index];
                temp.push(element);
                keysData.push("0" + index.toString());
            }
            filterData(temp, sport);
        }
        setLoading(false);
    };
    //FILTRAR SEGUN DEPORTE
    const filterData = (data, order) => {
        let eventosF = [];
        keysData = [];
        if (order == 0) {
            eventosF = data;
        } else if (order == 1) {
            eventosF = data.filter((e) => e.deporte == "SOCCER");
        } else if (order == 2) {
            eventosF = data.filter((e) => e.deporte == "BASKETBALL");
        } else if (order == 3) {
            eventosF = data.filter((e) => e.deporte == "VOLLEYBALL");
        } else if (order == 4) {
            eventosF = data.filter((e) => e.deporte == "TENNIS");
        } else if (order == 5) {
            eventosF = data.filter((e) => e.deporte == "HOCKEY");
        } else if (order == 6) {
            eventosF = data.filter((e) => e.deporte == "BASEBALL");
        } else if (order == 7) {
            eventosF = data.filter((e) => e.deporte == "AMERICAN-FOOTBALL");
        } else if (order == 8) {
            eventosF = data.filter((e) => e.deporte == "RUGBY-UNION");
        } else if (order == 9) {
            eventosF = data.filter((e) => e.deporte == "RUGBY-LEAGUE");
        } else {
            eventosF = data.filter((e) => e.deporte == "ESPORTS");
        }
        for (let index = 0; index < eventosF.length; index++) {
            keysData.push(order.toString() + index.toString());
        }
        setEventosFilter(eventosF);
    };
    //SELECCIONAR DEPORTE
    const selectSport = (sportSelect) => {
        setSport(sportSelect);
        filterData(dataEventos, sportSelect);
    };

    return (
        <div className="mw-100 ">
            <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                <div className="overlay-wrapper">
                    <div className="card">
                        <div className="row mx-0 ">
                            <div className="col-12 d-flex flex-column flex-xl-row justify-content-between py-4 px-6 gap-2 gap-xl-0">
                                <div className="d-flex gap-3 flex-wrap">
                                    {/*<div className="py-4 d-flex justify-content-center align-items-center">
                                        <button
                                            className={`btn ${sport == 0 ? "btn-danger" : ""} px-6 py-1`}
                                            onClick={() => selectSport(0)}
                                        >
                                            Todos
                                        </button>
                                    </div>*/}
                                    <div className="py-2 d-flex justify-content-center align-items-center">
                                        <button
                                            className={`btn ${sport == 1 ? "btn-danger" : ""} px-2 py-1`}
                                            onClick={() => selectSport(1)}
                                        >
                                            Soccer
                                        </button>
                                    </div>
                                    <div className="py-2 d-flex justify-content-center align-items-center">
                                        <button
                                            className={`btn ${sport == 2 ? "btn-danger" : ""} px-2 py-1`}
                                            onClick={() => selectSport(2)}
                                        >
                                            Basketball
                                        </button>
                                    </div>
                                    <div className="py-2 d-flex justify-content-center align-items-center">
                                        <button
                                            className={`btn ${sport == 3 ? "btn-danger" : ""} px-2 py-1`}
                                            onClick={() => selectSport(3)}
                                        >
                                            Volleyball
                                        </button>
                                    </div>
                                    <div className="py-2 d-flex justify-content-center align-items-center">
                                        <button
                                            className={`btn ${sport == 4 ? "btn-danger" : ""} px-2 py-1`}
                                            onClick={() => selectSport(4)}
                                        >
                                            Tennis
                                        </button>
                                    </div>
                                    <div className="py-2 d-flex justify-content-center align-items-center">
                                        <button
                                            className={`btn ${sport == 5 ? "btn-danger" : ""} px-2 py-1`}
                                            onClick={() => selectSport(5)}
                                        >
                                            Hockey
                                        </button>
                                    </div>
                                    <div className="py-2 d-flex justify-content-center align-items-center">
                                        <button
                                            className={`btn ${sport == 6 ? "btn-danger" : ""} px-2 py-1`}
                                            onClick={() => selectSport(6)}
                                        >
                                            Baseball
                                        </button>
                                    </div>
                                    <div className="py-2 d-flex justify-content-center align-items-center">
                                        <button
                                            className={`btn ${sport == 7 ? "btn-danger" : ""} px-2 py-1`}
                                            onClick={() => selectSport(7)}
                                        >
                                            American Football
                                        </button>
                                    </div>
                                    <div className="py-2 d-flex justify-content-center align-items-center">
                                        <button
                                            className={`btn ${sport == 8 ? "btn-danger" : ""} px-2 py-1`}
                                            onClick={() => selectSport(8)}
                                        >
                                            Rugby Union
                                        </button>
                                    </div>
                                    <div className="py-2 d-flex justify-content-center align-items-center">
                                        <button
                                            className={`btn ${sport == 9 ? "btn-danger" : ""} px-2 py-1`}
                                            onClick={() => selectSport(9)}
                                        >
                                            Rugby League
                                        </button>
                                    </div>
                                    <div className="py-2 d-flex justify-content-center align-items-center">
                                        <button
                                            className={`btn ${sport == 10 ? "btn-danger" : ""} px-2 py-1`}
                                            onClick={() => selectSport(10)}
                                        >
                                            E-sport
                                        </button>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-xl-center align-items-center gap-2">
                                    <div className="d-flex gap-2 align-items-center">
                                        <input className="form-control form-control-solid" placeholder="Fecha" id="kt_datepicker_1" />
                                    </div>
                                    <button onClick={() => searchData()} className="btn btn-primary btn-icon">
                                        <FaSearch></FaSearch>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 px-4">
                            <div className="py-5">
                                {eventosFilter.length > 0 ? (
                                    <Accordion defaultActiveKey={keysData} alwaysOpen>
                                        {eventosFilter.map((evento, index) => (
                                            <Accordion.Item eventKey={sport.toString() + index.toString()} key={index}>
                                                <Accordion.Header>
                                                    <div className="w-100 fw-bolder">
                                                        <img src={evento.imgleague ? evento.imgleague : "https://bit.ly/45vyuAu"} alt="liga" width={18} className="me-2"/>
                                                        {evento.parcialleague}
                                                    </div>
                                                </Accordion.Header>
                                                {evento.datos.map((datos, index1) => (
                                                    <Accordion.Body key={index1}>
                                                        <div className="d-flex gap-3 flex-column flex-sm-row justify-content-between">
                                                            <div className="px-4 d-flex border-end border-2 align-items-center justify-content-center">
                                                                <FaRegClock className="me-1"></FaRegClock>
                                                                {datos.hora}
                                                            </div>
                                                            <div className="d-flex flex px-4 gap-1 w-100 align-items-center justify-content-center justify-content-sm-start">
                                                                <img src={datos.imghome ? datos.imghome : "https://bit.ly/45vyuAu"} alt="liga" width={18} className="me-2" />
                                                                <span>{datos.local}</span>
                                                                <span> - </span>
                                                                <span>{datos.visita}</span>
                                                                <img src={datos.imgaway ? datos.imgaway : "https://bit.ly/45vyuAu"} alt="liga" width={18} className="ms-2" />
                                                            </div>
                                                            <div className="d-flex flex px-4 gap-1 align-items-center justify-content-center justify-content-sm-start">
                                                                <div className="px-4 d-flex border-start border-2 align-items-center justify-content-center">
                                                                    {
                                                                        datos.real == 'SCHEDULED' ? "PROGRAMADA" :
                                                                        datos.real == 'POSTPONED' ? "POSPUESTO" :
                                                                        datos.real == 'FINISHED' ? "FINALIZADO" : "JUGANDO"
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                ))}
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                ) : (
                                    <div className="p-20 d-flex align-items-center justify-content-center flex-column gap-4">
                                        <FaRegFutbol size={40}></FaRegFutbol>
                                        <span className="fw-bolder">No se encontraron datos</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {loading ? (
                    <div className="overlay-event card-rounded bg-black bg-opacity-50">
                        <div className="d-flex flex-column align-items-center mt-10">
                            <div className="spinner-border text-white" role="status"></div>
                            <span>Buscando eventos...</span>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default Eventos;
