/* eslint-disable */

import UseService from "../UseServices";

const ConsolidadoService = {
    async getConsolidados(data) {
        var resp = await UseService.post("consolidado/list", data);
        return resp;
    },
    async addConsolidado(data) {
        var resp = await UseService.post("consolidado/add", data);
        return resp;
    },
    async addInicioConsolidado(data) {
        var resp = await UseService.post("consolidado/addinicio", data);
        return resp;
    },
    async previewConsolidado(data) {
        var resp = await UseService.post("consolidado/preview", data);
        return resp;
    },
};
export default ConsolidadoService;
