/* eslint-disable */

import UseService from "../UseServices";

const SurebetService = {
    async addBets(data) {
        var resp = await UseService.post("apuestas/registrar/", data);
        return resp;
    },
    async addSureBets(data) {
        var resp = await UseService.post("surebets/registrar/", data);
        return resp;
    },
    async getBets(data) {
        var resp = await UseService.post("apuestas/", data);
        return resp;
    },
    async getSureBets(data) {
        var resp = await UseService.post("surebets/", data);
        return resp;
    },
};

export default SurebetService;
