/* eslint-disable */
import React, { useState, useEffect, lazy } from "react";
import Main from "../../layout/main";

//OPERABLE
import { JugadasSureBets, JugadasApuestas } from "../Jugadas";
import { ReporteApuestas, ReporteSureBets } from "../Reportes";
import { Movimientos, BancaDiario } from "../Operaciones";


//NO OPERABLE

import { Bancarias, Casas } from "../Cuentas";
import Montos from "../montos";
import Transacciones from "../transacciones";
import ReporteMontos from "../montos/reporte";
import CajaCuenta from "../cajacuenta";
import Confirmados from "../confirmados";
import Consolidado from "../consolidado";
import Gastos from "../gastos"
import Utilidades from "../utilidades"

//ESTADISTICAS
import Resumen from "../resumen";

const PanelAdmin = () => {

  const [check, setCheck] = useState(3)
  const [load, setLoad] = useState(false)
  const [load2, setLoad2] = useState(false)

  const changeView1 = async (val) => {
    setCheck(val)
    setLoad(true)

    await new Promise((r) => setTimeout(r, 400));
    setLoad(false)
  }
  const changeView2 = async (val) => {
    setCheck(val)
    setLoad2(true)
    await new Promise((r) => setTimeout(r, 400));
    setLoad2(false)
  }
  return (
    <Main>
      <div className="container-xxl mw-100">
        <div className="toolbar" id="kt_toolbar">
          <div className="row mx-0 w-100">
            <div className="col-12 w-100 d-flex justify-content-center py-8">
              {/* <button disabled={load} onClick={() => changeView1(1)} type="button" className={`btn rounded-4 font-bold fs-4 ${check == 1 ? 'btn-primary' : 'btn_second'} me-3`}>
                {load ? (
                  <span className="indicator-label">
                    CAPITAL OPERABLE...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                ) : (
                  'CAPITAL OPERABLE'
                )}
                
              </button>
              <button disabled={load2} onClick={() => changeView2(2)} type="button" className={`btn rounded-4 font-bold fs-4 ${check == 2 ? 'btn-primary' : 'btn_second'} me-3`}>
              {load2 ? (
                  <span className="indicator-label">
                    CAPITAL NO OPERABLE...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                ) : (
                  'CAPITAL NO OPERABLE'
                )}
              </button> */}
              <button onClick={() => setCheck(3)} type="button" className={`btn rounded-4 font-bold fs-4 ${check == 3 ? 'btn-primary' : 'btn_second'} me-3`}>
                ESTADISTICAS
              </button>
              <button onClick={() => setCheck(1)} type="button" className={`btn rounded-4 font-bold fs-4 ${check == 1 ? 'btn-primary' : 'btn_second'} me-3`}>
                CAPITAL OPERABLE
              </button>
              <button onClick={() => setCheck(2)} type="button" className={`btn rounded-4 font-bold fs-4 ${check == 2 ? 'btn-primary' : 'btn_second'} me-3`}>
                CAPITAL NO OPERABLE
              </button>
            </div>
          </div>
          <div className="row mx-0 w-100">
            <div className="col-12 w-100">
              <div className="flex-column gap-8" style={{ display: check == 1 ? 'flex' : 'none' }}>
                <JugadasSureBets></JugadasSureBets>
                <JugadasApuestas></JugadasApuestas>
                <ReporteSureBets></ReporteSureBets>
                <ReporteApuestas></ReporteApuestas>
                <Movimientos></Movimientos>
                <BancaDiario></BancaDiario>
              </div>
              <div className=" flex-column gap-8" style={{ display: check == 2 ? 'flex' : 'none' }}>
                <Bancarias></Bancarias>
                <Casas></Casas>
                <Montos></Montos>
                <Transacciones></Transacciones>
                <ReporteMontos></ReporteMontos>
                <CajaCuenta></CajaCuenta>
                <Confirmados></Confirmados>
                <Consolidado></Consolidado>
                <Gastos></Gastos>
                <Utilidades></Utilidades>
              </div>
              <div className=" flex-column gap-8" style={{ display: check == 3 ? 'flex' : 'none' }}>
                <Resumen></Resumen>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  )
}

export default PanelAdmin