/* eslint-disable */
import React, { useState, lazy, Suspense, useEffect } from "react";
import { Calculadora, Scanner } from "../../../Components";
import Main from "../../../layout/main";
import { FaWindowRestore, FaCalculator } from "react-icons/fa";
import { useUserContext } from "../../../Providers/UserProvider";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Ventana from "../live/Ventana";
// import './live.css'
const Filtro = lazy(() => import("../../../Components/filtro/Filtro"));
const tooltipTC = (
    <Tooltip id="tooltipTC">
        <strong>Ver tipo de cambio</strong>
    </Tooltip>
);
const tooltip = (
    <Tooltip id="tooltip">
        <strong>Abrir en nueva ventana</strong>
    </Tooltip>
);
const tooltipF = (
    <Tooltip id="tooltipF">
        <strong>Solo ventanas flotantes</strong>
    </Tooltip>
);

const Prematch = () => {
    const { user, setUser } = useUserContext();
    const [liveSure, setLiveSure] = useState("");
    const [tc, setTc] = useState("normal");
    const [stake, setStake] = useState(user.stake);
    const [userTc, setUserTc] = useState(user.tipocambio);
    const onOptionChange = (e) => {
        setTc(e.target.checked);
    };
    const openWindow = () => {
        setTrigger((trigger) => trigger + 1);
        // window.open('/calculator?modal=A'+trigger+'&type=Live'+'&data='+JSON.stringify(liveSure),'_blank')
        // setTrigger((trigger) => trigger + 1);
        // const containerEl =document.createElement('div');
        // var win = window.open('', '_blank', 'width=800,height=300,left=200,top=200');
        // win.document.body.appendChild(containerEl);
        setShowWindowPortal(true);
        // console.log('elemento', elemento)
    };
    const closeWindowPortal = () => {
        setShowWindowPortal(false);
    };
    useEffect(() => {
        window.addEventListener("beforeunload", () => {
            closeWindowPortal();
        });
    }, []);
    // const canToggle = () => {
    //     console.log('liveSure', liveSure)
    //     if (liveSure.length != 0 || dataLive.length != 0) {
    //         setToggle(!toggle);
    //         // document.getElementById("calc_acordion").classList.toggle("colapse");
    //         // document.getElementById("cal_cont_acordion").classList.toggle("showa");
    //     }

    // };
    const onlyFloat = (e) => {
        var checkBox = document.getElementById("chck1");
        if (e.target.checked) {
            // document.getElementById("calc_acordion").classList.remove("colapse");
            // document.getElementById("cal_cont_acordion").classList.remove("showa");
            setToggle(false)
            setOnlyF(true)
            setDisInput(true)
            
            if (checkBox.checked == true) {
                checkBox.checked = false
                checkBox.disabled = true
            }
        } else {
            setOnlyF(false)
            setDisInput(false)
            if (checkBox.checked == false) {
                checkBox.checked = true
                checkBox.disabled = false
            }
        }
    }

    return (
        <Main userStake={(datos) => setStake(datos)} userTC={(datos) => setUserTc(datos)}>
            <Suspense fallback={<h1>cargando...</h1>}>
                <div className="row w-100 mx-auto px-5">
                    <div className="col-lg-6 col-sm-12 order-2 order-lg-1">
                        <div className="card">
                            <Filtro type="prematch" />
                            <Scanner type={1} setLiveSure={(liveSure) => setLiveSure(liveSure)} />
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-6 order-1 order-lg-2">
                        <div className="card">
                            <div className=" px-6 py-3 bg_surebet_primary   fw-bolder fs-14 d-flex justify-content-between rounded-top">
                                <div className="d-flex box_options ">
                                    <FaCalculator size={30} color="white"></FaCalculator>
                                    <div className="form-check form-switch form-check-custom form-check-success form-check-solid me-6">
                                        <OverlayTrigger placement="top" overlay={tooltipF}>
                                            <input
                                                className="form-check-input ms-1 cursor-pointer"
                                                type="checkbox"
                                                value="1"
                                                id="flexSwitchDefault"
                                                onChange={onlyFloat}
                                            />
                                        </OverlayTrigger>
                                    </div>
                                    <OverlayTrigger placement="top" overlay={tooltip}>
                                        <button className="btn btn-warning btn-sm" onClick={openWindow}>
                                            <FaWindowRestore></FaWindowRestore>
                                        </button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" overlay={tooltipTC}>
                                        <div className="px-6 d-flex gap-2 justify-content-center align-items-center form-check form-check-custom form-check-primary form-check-solid">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                name="tipo_cambio"
                                                id="option-1"
                                                value={"tc"}
                                                onChange={onOptionChange}
                                            />
                                            <label htmlFor="option-1" className="text-white">
                                                TC
                                            </label>
                                        </div>
                                    </OverlayTrigger>
                                    {/* <input type="radio" name="tipo_cambio" id="option-1" value={"normal"} onChange={onOptionChange} defaultChecked />
                                    <input type="radio" name="tipo_cambio" id="option-2" value={"tc"} onChange={onOptionChange} />
                                    <label htmlFor="option-1" className="option option-1">
                                        <div className="dot"></div>
                                        <span>Normal</span>
                                    </label>
                                    <label htmlFor="option-2" className="option option-2">
                                        <div className="dot"></div>
                                        <span>TC</span>
                                    </label> */}
                                </div>
                            </div>
                            <Calculadora actSurebet={liveSure} tc={tc} stake={stake} userTc={userTc} />
                        </div>
                    </div>
                </div>
            </Suspense>
        </Main>
    );
};

export default Prematch;
