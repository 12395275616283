/* eslint-disable */

import UseService from "../UseServices";

const GastosService = {
    async getGastos(data) {
        var resp = await UseService.post("gastos/list", data);
        return resp;
    },
    async addGastos(data) {
        var resp = await UseService.post("gastos/add", data);
        return resp;
    },
    async updateGastos(data) {
        var resp = await UseService.put("gastos/update", data);
        return resp;
    },
    async statusGastos(data) {
        var resp = await UseService.put("gastos/status", data);
        return resp;
    },
};
export default GastosService;